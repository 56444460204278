import React from 'react';
import {Config, Routes} from './Config.js';
import {withStyles} from '@material-ui/core/styles';

import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Snackbar, SvgIcon, Switch, TextField, Typography } from '@material-ui/core';
import Messages from './Messages.js';
import API from './API.js';
import { Link, Prompt, withRouter } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import { Alert } from '@material-ui/lab';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ComputerOutlinedIcon from '@material-ui/icons/ComputerOutlined';
import LocalAirportIcon from '@material-ui/icons/LocalAirport';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import MoneyIcon from '@material-ui/icons/Money';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MailIcon from '@material-ui/icons/Mail';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import {BlockTitle, BlockTitleCheckbox, BlockTitleTotal, BlockTitleAdvance, DeleteTemplateDialog} from './TemplateComponents.js';
import {CurrencyFilter, PercentsInput, TemplateListInput, TemplateListCustomerInput, TemplateCheckboxList, TemplateList, Okpd2Element, CustomerElement, TemplateBlock} from './TemplateComponents.js';
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
//import ArrowRightIcon from '@material-ui/icons/ArrowRight';
//import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
//import ClearIcon from '@material-ui/icons/Clear';
//import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import {comparatorIgnoreCase, includesIgnoreCase, isOKPD2, trimOKPD2} from './Utils.js';
import SearchPage from './SearchPage.js';
import LoadingCursor from './LoadingCursor.js';


const styles = theme => ({
    longBlock: {
        minHeight: '600px',
    },
    saveButtonsBlock: {
        marginTop: theme.spacing(2),
    },
    saveButton: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
});


class TemplatePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
        this.handleSave = this.handleSave.bind(this);
        this.handleSaveAndFind = this.handleSaveAndFind.bind(this);
        this.handleNotSaveAndFind = this.handleNotSaveAndFind.bind(this);
        this.handleAccordionOpen = this.handleAccordionOpen.bind(this);
        this.handleSwitchSetting = this.handleSwitchSetting.bind(this);
        this.handleSwitchValue = this.handleSwitchValue.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
        this.handleResultFormatChange = this.handleResultFormatChange.bind(this);
        this.handleSubmitWord = this.handleSubmitWord.bind(this);
        this.handleSubmitOkpd2 = this.handleSubmitOkpd2.bind(this);
        this.handleSubmitCustomer = this.handleSubmitCustomer.bind(this);
        this.handleDeleteListItem = this.handleDeleteListItem.bind(this);
        this.handleChangeListItem = this.handleChangeListItem.bind(this);
        this.handleChangeListCustomerItem = this.handleChangeListCustomerItem.bind(this);
        this.handleInfoMessageClose = this.handleInfoMessageClose.bind(this);
        this.handleCheckboxListChange=this.handleCheckboxListChange.bind(this);
        this.handleCheckboxListSelectAll=this.handleCheckboxListSelectAll.bind(this);
        this.addInfoMessage = this.addInfoMessage.bind(this);
        this.getInfoMessagesForNewWords = this.getInfoMessagesForNewWords.bind(this);
        this.handleDeleteTemplate = this.handleDeleteTemplate.bind(this);
        this.handleDeleteTemplateDialogSwitch = this.handleDeleteTemplateDialogSwitch.bind(this);
        this.handleSaveBeforeSearchDialogSwitch = this.handleSaveBeforeSearchDialogSwitch.bind(this);
    }

    getInitialState() {
        return {
            errors: null,
            warnings: null,
            notices: null,
            template: null, // this.templateIdParam === TemplatePage.newTemplateId ? this.getEmptyTemplate(TemplatePage.newTemplateId, this.newTemplateName) : this.getEmptyTemplate(null, ''),
            templateName: (this.props.location.state && this.props.location.state.templateName) ? this.props.location.state.templateName : '',
            isLoading: true,
            infoMessages: null,
            search: '',
            okpd2Code: '',
            customer: {inn:'', name:'', note:''},
            blackWord: '',
            blackOkpd2Code: '',
            blackCustomer: {inn:'', name:'', note:''},
            isTemplateDeleteDialogOpened: false,
            isSaveBeforeSearchDialogOpened: false,
        };
    }
/*
    getEmptyTemplate(id, name) {
        const template = {
            id: id,
            name: name,
            letterSubject: name,
            isSubscribed: 0,
            words: [],
            okpd2: [],
            customers: [],
            blackWords: [],
            blackOkpd2: [],
            blackCustomers: [],
            okpd2Parts: [],
            regions: [],
            procedureTypes: [],
            etp: [],
            total: [],
            settings: {
                [this.resultFormat]: this.resultFormatValueRtf,
                [this.isSendNotFound]: 1,
            },
            incOkpd2ByBL: [],
            incOkpd2ByPart: [],
        };
        return this.prepareLoadedTemplate(template);
    }
*/
    componentDidUpdate(prevProps) {
        if ((this.templateIdParam !== this.getTemplateIdParam(prevProps.location.search)))
            this.updatePage();
    }
    
    componentDidMount() {
        this.props.appProps.setHead('Настройка поиска', '');
        this.loadTemplate();
    }

    updatePage() {
        this.setState(this.getInitialState());
        this.props.appProps.setHead('Настройка поиска', '');
        this.loadTemplate();
    }

    prepareLoadedTemplate(template) {
        if (template) {
            if (Array.isArray(template.words) && template.words.length)
                template.words.sort(comparatorIgnoreCase.bind(this, null));
            if (Array.isArray(template.blackWords) && template.blackWords.length)
                template.blackWords.sort(comparatorIgnoreCase.bind(this, null));
            Object.assign(template, {changed: []});
            Object.assign(template, {open: []});
            this.uiPropsArray.map(name => (
                Object.assign(template.open, {
                    [name]: (name === this.isWordsOpen || ((name === this.isSubscriptionOpen) && ('' + template.id === TemplatePage.newTemplateId))) ? 1 : 0,
                })
            ));
        }
        return template;
    }

    loadTemplate() {
        const templateId = this.templateIdParam;
        if (templateId === null)
            return this.setState({template: null, errors: ['Не указан шаблон'], warnings: null, notices: null, isLoading: false});

        return API.fetch(this.props.appProps.setAuth,
            Config.templateApiUrl,
            '1.0',
            'getTemplate',
            {
                'id': templateId,
            }
        )
        .then(responseData => {
            //const {auth} = this.props;
            const {errors, warnings, notices, content, auth} = responseData;
            const template = (content && (content.id || ('' + content.id === TemplatePage.newTemplateId))) ? content : null;
            //if (template && (!errors || !errors.length)) {
            if (template && (template.id || ('' + template.id === TemplatePage.newTemplateId))) {
                this.props.appProps.setHead(('' + template.id === TemplatePage.newTemplateId) ? this.newTemplateName : 'Настройка поиска ' + template.name, '');
                this.prepareLoadedTemplate(template);
            }

            let errors_new = errors;
            const notActiveMessage = 'Рассылка новых тендеров отключена в связи с окончанием оплаченного периода. Для возобновления рассылки необходимо сформировать новый счет и произвести оплату.';
            if (!auth || !auth.isActive)
                errors_new = errors ? [notActiveMessage, ...errors] : [notActiveMessage];

            const newState = {errors: errors_new, warnings, notices, template, isLoading: false};
            if (template && template.name)
                newState.templateName = template.name;
            this.setState(newState);
        })
        .catch(() => {
            this.setState({errors: [Config.commonErrorText], isLoading: false})
            window.scrollTo(0, 0);
        });
    }

    deleteTemplate() {
        const {template} = this.state;
        if (!template || !template.id || ('' + template.id === TemplatePage.newTemplateId))
            return this.setState({errors: ['Не указан шаблон'], warnings: null, notices: null});

        this.setState({isLoading: true});
        return API.fetch(this.props.appProps.setAuth,
            Config.templateApiUrl,
            '1.0',
            'deleteTemplate',
            {
                'id': template.id,
            }
        )
        .then(responseData => {
            const {errors, warnings, notices} = responseData;
            if (!errors || !errors.length) {
                //this.setState({template: null, errors, warnings, notices, isTemplateDeleteDialogOpened: false, isLoading: false});
                this.props.history.push(Routes.lk);
                this.props.appProps.getMenu(false);
            } else
                this.setState({errors, warnings, notices, isTemplateDeleteDialogOpened: false, isLoading: false});
            window.scrollTo(0, 0);
        })
        .catch(() => {
            this.setState({errors: [Config.commonErrorText], isTemplateDeleteDialogOpened: false, isLoading: false});
            window.scrollTo(0, 0);
        });
    }

    saveTemplate(isDoSearch = false) {
        this.setState({isLoading: true});
        let {template} = this.state;

        // Добавить в слова для поиска фразу из поля ввода
        // this.handleSubmitWord.bind('words', 'search', this.getInfoMessagesForNewWords, null);
        // Не вызываем эту функцию, т.к. state обновляется асинхронно и изменений в нем мы сейчас не увидим
        let value = this.state['search'] ? this.state['search'].trim() : '';
        let newWords1 = value.split(/[,;&|]+/).map(word => ((word && word.trim()) ? word.trim() : null)).filter(word => (word && template && !includesIgnoreCase(template['words'], word)));
        let newWords = [];
        newWords1.map(word => (includesIgnoreCase(newWords, word) ? null : newWords.push(word)));
        const isSubmitEnabled = template && newWords && newWords.length;
        if (isSubmitEnabled) {
            template = Object.assign({}, template, {
                changed: Object.assign({}, template.changed, {words: 1}),
                words: template['words'].concat(newWords).sort(comparatorIgnoreCase.bind(this, null)),
            });
        }
        
        return API.fetch(this.props.appProps.setAuth,
            Config.templateApiUrl,
            '1.0',
            'saveTemplate',
            {
                'template': template,
            }
        )
        .then(responseData => {
            const {errors, warnings, notices, content} = responseData;
            const templateId = content;
            if (templateId && (!errors || !errors.length)) {
                if (isDoSearch) {
                    //this.props.history.push(Routes.addParamToUrl(Routes.search, SearchPage.templateIdParamName, templateId), {templateName: template.name});
                    this.doSearch(templateId, template.name);
                } else {
                    if (templateId === this.state.template.id)
                        this.updatePage();
                    else
                        this.props.history.push(Routes.addParamToUrl(Routes.template, TemplatePage.templateIdParamName, templateId));
                }
                this.props.appProps.getMenu(false);
            } else
                this.setState({errors, warnings, notices, isLoading: false});
            window.scrollTo(0, 0);
        })
        .catch(() => {
            this.setState({errors: [Config.commonErrorText], isLoading: false});
            window.scrollTo(0, 0);
        });
    }

    doSearch(templateId, templateName) {
        this.props.history.push(Routes.addParamToUrl(Routes.search, SearchPage.templateIdParamName, templateId), templateName ? {templateName} : null);
    }

    loadOkpd2Names(listName, newCodes) {
        return API.fetch(this.props.appProps.setAuth,
            Config.okpd2ApiUrl,
            '1.0',
            'getOkpd2Names',
            {
                'codes': newCodes.map(item => (item.code)),
            }
        )
        .then(responseData => {
            const {content} = responseData;
            if (Array.isArray(content))
                this.setState(prevState => {
                    const newList = [];
                    for (const item of prevState.template[listName])
                        if (!item.name && newCodes.includes(item)) {
                            let newItem = null;
                            for (let itemWithName of content)
                                if (itemWithName.code === item.code) {
                                    newItem = itemWithName;
                                    break;
                                }
                            newList.push(newItem ? newItem : item);
                        } else
                            newList.push(item);
                    return {template: Object.assign({}, prevState.template, {
                        [listName]: newList,
                    })};
                });
        })
        .catch();
    }

    handleSave() {
        this.saveTemplate(false);
    }

    handleSaveAndFind() {
        this.saveTemplate(true);
    }

    handleNotSaveAndFind() {
        this.doSearch(this.state.template.id, this.state.template.name);
    }

    handleAccordionOpen(name) {
        this.setState(prevState => ({template: Object.assign({}, prevState.template, {
            open: Object.assign({}, prevState.template.open, {[name]: prevState.template.open[name] ? 0 : 1})
        })}));
    }

    handleSwitchSetting(name) {
        this.setState(prevState => ({template: Object.assign({}, prevState.template, {
            changed: Object.assign({}, prevState.template.changed, {'settings': 1}),
            settings: Object.assign({}, prevState.template.settings, {[name]: prevState.template.settings[name] ? 0 : 1}),
        })}));
    }

    handleSwitchValue(name) {
        this.setState(prevState => ({template: Object.assign({}, prevState.template, {
            changed: Object.assign({}, prevState.template.changed, {'main': 1}),
            [name]: prevState.template[name] ? 0 : 1},
        )}));
    }

    handleValueChange(clearPattern, name, event) {
        let value = event.target.value;
        while (value && clearPattern.test(value))
            value = value.replace(clearPattern, '');
        this.setState(prevState => ({template: Object.assign({}, prevState.template, {
            changed: Object.assign({}, prevState.template.changed, {'main': 1}),
            [name]: value
        })}));
    }

    handleNameChange(event) {
        const value = event.target.value;
        this.setState(prevState => ({template: Object.assign({}, prevState.template, {
            changed: Object.assign({}, prevState.template.changed, {'main': 1}),
            [this.name]: value,
            [this.letterSubject]: value
        })}));
    }

    handleCurrencyChange(currencyCode, isMinValue, event) {
        let value = event.target.value;
        const clearPattern = /[^\d]+/;
        while (value && clearPattern.test(value))
            value = value.replace(clearPattern, '');
        if (value === '')
            value = null;
        this.setState(prevState => {
            const prevTotal = prevState.template.total;
            const prevCur = includesIgnoreCase(prevTotal, currencyCode, 'currencyCode');
            const prevMin = prevCur ? prevCur.minValue : null;
            const prevMax = prevCur ? prevCur.maxValue : null;
            if (isMinValue && (value === prevMin))
                return {};
            if (!isMinValue && (value === prevMax))
                return {};
            const newCur = {currencyCode: currencyCode, minValue: isMinValue ? value : prevMin, maxValue: isMinValue ? prevMax : value};
            let newTotal = [];
            if (prevTotal)
                for (let cur of prevTotal)
                    if (cur.currencyCode !== currencyCode)
                        newTotal.push(cur);
            if ((newCur.minValue !== null) || (newCur.maxValue !== null))
                newTotal.push(newCur);
            return {template: Object.assign({}, prevState.template, {
                changed: Object.assign({}, prevState.template.changed, {'total': 1}),
                'total': newTotal
            })};
        });
    }

    handleResultFormatChange(event) {
        const value = event.target.value;
        const isExcel = (value === this.resultFormatValueRtfExcel) ? 1 : 0;
        this.setState(prevState => ({template: Object.assign({}, prevState.template, {
            changed: Object.assign({}, prevState.template.changed, {'settings': 1}),
            settings: Object.assign({}, prevState.template.settings, {
                [this.resultFormat]: value,
                [this.isResultFormatRtf]: 1,
                [this.isResultFormatExcel]: isExcel,
            })
        })}));
    }

    getInfoMessagesForNewWords(newWords) {
        let newInfoMessages = [];
        const isMultipleWordsRe = /((^|[^a-zA-Zа-яёА-ЯЁ0-9.]+)[a-zA-Zа-яёА-ЯЁ0-9.]+(?=$|[^a-zA-Zа-яёА-ЯЁ0-9.]+)){3,}/;
        newWords.map(word => {
            if (word.toLowerCase().search(/поставк|закупк|покупк|заказ|приобретение/i) !== -1)
                this.addInfoMessage(newInfoMessages, 'Поисковый запрос содержит слова "поставка", "закупка" или др. Рекомендуем исключать подобные слова из запросов, так как в описании тендеров они могут отсутствовать, и такие тендеры не будут найдены. Указывайте только сами предметы поставки: товары, услуги.');
            if (isMultipleWordsRe.test(word))
                this.addInfoMessage(newInfoMessages, 'Поисковый запрос содержит несколько слов. Будут найдены только тендеры, которые содержат одновременно все слова из данного запроса. Если хотя бы одно слово будет отсутствовать в описании тендера, такой тендер не будет найден по данному запросу.');
            if ((word.indexOf(" и ") >= 0) || (word.indexOf(" И ") >= 0))
                this.addInfoMessage(newInfoMessages, 'Поисковый запрос содержит союз "и". Если запрос аналогичен запросу "доставка грузов и корреспонденции", то его следует разбить на два отдельных: "доставка грузов" и "доставка корреспонденции". Иначе будут найдены только тендеры на доставку одновременно и грузов и корреспонденции, но не будут найдены тендеры только на грузы и только на корреспонденцию.');
            if (word.indexOf("*") >= 0)
                this.addInfoMessage(newInfoMessages, 'Поисковый запрос содержит символ *. Поиск по части слова не производится. Все слова должны быть указаны полностью, в соответствии с правилами русского языка, в этом случае мы гарантируем, что будут найдены все формы написания данных слов.');
            return null;
        });
        return newInfoMessages;
    }

    handleSubmitWord(listName, valueName, getInfoMessagesForNewItems, event) {
        const {template} = this.state;
        let value = this.state[valueName] ? this.state[valueName].trim() : '';
        let newWords1 = value.split(/[,;&|]+/).map(word => ((word && word.trim()) ? word.trim() : null)).filter(word => (word && template && !includesIgnoreCase(template[listName], word)));
        let newWords = [];
        newWords1.map(word => (includesIgnoreCase(newWords, word) ? null : newWords.push(word)));

        const isSubmitEnabled = template && newWords && newWords.length;
        if (isSubmitEnabled) {
            const newInfoMessages = getInfoMessagesForNewItems ? getInfoMessagesForNewItems(newWords) : [];
            this.setState(prevState => ({
                [valueName]: '',
                infoMessages: prevState.infoMessages ? prevState.infoMessages.concat(newInfoMessages) : newInfoMessages,
                template: Object.assign({}, prevState.template, {
                    changed: Object.assign({}, prevState.template.changed, {[listName]: 1}),
                    [listName]: prevState.template[listName].concat(newWords).sort(comparatorIgnoreCase.bind(this, null)),
                }),
            }));
        }
        event.preventDefault();
    }

    handleSubmitOkpd2(listName, valueName, minLength, getInfoMessagesForNewItems, event) {
        const {template} = this.state;
        let value = this.state[valueName] ? this.state[valueName].trim() : '';
        let wrongCodes = [];
        let newWords1 = value.split(/[,\s]+/).map(trimOKPD2).filter(word => {
            if (word && !isOKPD2(word, minLength))
                return wrongCodes.push(word) && false;
            return word/* && !isOKPD2(word, minLength)*/ && template && !includesIgnoreCase(template[listName], word, 'code');
        });
        let newWords = [];
        newWords1.map(word => (includesIgnoreCase(newWords, trimOKPD2(word), 'code') ? null : newWords.push({code: trimOKPD2(word)})));

        const isSubmitEnabled = template && newWords && newWords.length;
        if (isSubmitEnabled) {
            const newInfoMessages = getInfoMessagesForNewItems ? getInfoMessagesForNewItems(newWords) : [];
            if (wrongCodes.length)
                newInfoMessages.push(wrongCodes.join(', ') + ((wrongCodes.length === 1) ? ' не является корректным кодом ОКПД2' : ' не являются корректными кодами ОКПД2'));
            this.setState(prevState => ({
                [valueName]: '',
                infoMessages: prevState.infoMessages ? prevState.infoMessages.concat(newInfoMessages) : newInfoMessages,
                template: Object.assign({}, prevState.template, {
                    changed: Object.assign({}, prevState.template.changed, {[listName]: 1}),
                    [listName]: prevState.template[listName].concat(newWords).sort(comparatorIgnoreCase.bind(this, 'code')),
                }),
            }));
            this.loadOkpd2Names(listName, newWords);
        }
        event.preventDefault();
    }

    handleSubmitCustomer(listName, valueName, getInfoMessagesForNewItems, event) {
        const {template} = this.state;
        const customer = this.state[valueName];
        const isSubmitEnabled = template && customer && customer.inn && !includesIgnoreCase(template[listName], customer.inn, 'inn');
        if (isSubmitEnabled) {
            const newInfoMessages = getInfoMessagesForNewItems ? getInfoMessagesForNewItems(customer) : [];
            this.setState(prevState => ({
                [valueName]: {inn:'', name:'', note:''},
                infoMessages: prevState.infoMessages ? prevState.infoMessages.concat(newInfoMessages) : newInfoMessages,
                template: Object.assign({}, prevState.template, {
                    changed: Object.assign({}, prevState.template.changed, {[listName]: 1}),
                    [listName]: prevState.template[listName].concat(customer).sort(comparatorIgnoreCase.bind(this, 'inn')),
                }),
            }));
        }
        event.preventDefault();
    }

    handleDeleteListItem(listName, item) {
        this.setState(prevState => {
            let index = prevState.template[listName].indexOf(item);
            if (index === -1)
                return {};
            let newList = prevState.template[listName].slice();
            newList.splice(index, 1);
            return {
                template: Object.assign({}, prevState.template, {
                    changed: Object.assign({}, prevState.template.changed, {[listName]: 1}),
                    [listName]: newList,
                }),
            };
        });
    }

    handleChangeListItem(clearPattern, event) {
        const valueName = event.target.name;
        let value = event.target.type === 'checkbox' ? (event.target.checked ? 1 : 0) : event.target.value;
        while (clearPattern && value && clearPattern.test(value))
            value = value.replace(clearPattern, '');
        this.setState({[valueName]: value});
    }

    handleChangeListCustomerItem(name, event) {
        const valueName = event.target.name;
        let value = event.target.type === 'checkbox' ? (event.target.checked ? 1 : 0) : event.target.value;
        const clearPattern = /[^\d]+/;
        while ((valueName === 'inn') && value && clearPattern.test(value))
            value = value.replace(clearPattern, '');
        this.setState(prevState => ({[name]: Object.assign({}, prevState[name], {[valueName]: value})}));
    }

    handleCheckboxListChange(listName, fieldId, fieldIsChecked, event) {
        //const listName = event.target.name;
        const valueName = event.target.id;
        let value = event.target.type === 'checkbox' ? (event.target.checked ? 1 : 0) : event.target.value;
        this.setState(prevState => {
            const newList = [];
            for (const item of prevState.template[listName])
                newList.push(((item[fieldId] !== valueName) || (item[fieldIsChecked] === value)) ? item :
                    Object.assign({}, item, {[fieldIsChecked]: value}));
            return {template: Object.assign({}, prevState.template, {
                changed: Object.assign({}, prevState.template.changed, {[listName]: 1}),
                [listName]: newList,
            })};
        });
    }

    handleCheckboxListSelectAll(listName, value, fieldIsChecked) {
        this.setState(prevState => {
            const newList = [];
            for (const item of prevState.template[listName])
                newList.push((item[fieldIsChecked] === value) ? item :
                    Object.assign({}, item, {[fieldIsChecked]: value}));
            return {template: Object.assign({}, prevState.template, {
                changed: Object.assign({}, prevState.template.changed, {[listName]: 1}),
                [listName]: newList,
            })};
        });
    }

    handleInfoMessageClose(event, reason) {
        if (reason === 'clickaway')
            return;
        if (this.state.infoMessages && this.state.infoMessages.length) {
            this.setState(prevState => {
                let infoMessages = prevState.infoMessages;
                if (infoMessages) {
                    infoMessages = infoMessages.slice();
                    infoMessages.shift();
                    return {infoMessages};
                }
                return {};
            });
        }
    }

    addInfoMessage(newInfoMessages, message) {
        const {infoMessages} = this.state;
        if ((!infoMessages || !infoMessages.includes(message)) && !newInfoMessages.includes(message))
            newInfoMessages.push(message);
    }

    handleDeleteTemplate() {
        this.deleteTemplate();
    }

    handleDeleteTemplateDialogSwitch() {
        this.setState(prevState => ({isTemplateDeleteDialogOpened: !prevState.isTemplateDeleteDialogOpened}));
    }

    handleSaveBeforeSearchDialogSwitch() {
        this.setState(prevState => ({isSaveBeforeSearchDialogOpened: !prevState.isSaveBeforeSearchDialogOpened}));
    }
/*
    makeTitle(title, list) {
        //return title + (list.length ? (' (' + list.length + ')') : '');
        return <React.Fragment>{title}{!list.length ? null : <Typography variant='body2' component='span' display='inline' style={{marginLeft: '8px'}}>{' (' + list.length + ')'}</Typography>}</React.Fragment>;
    }

    makeTitleCheckbox(title, list) {
        let checkedCount = 0;
        list.map(item => (checkedCount += item.isChecked ? 1 : 0));
        return <React.Fragment>{title}{(checkedCount === list.length) ? null : <Typography variant='body2' component='span' display='inline' style={{marginLeft: '8px'}}>{' (' + checkedCount + '/' + list.length + ')'}</Typography>}</React.Fragment>;
    }

    makeTitleTotal(title, list) {
        let count = 0;
        list.map(item => (count += ((item.minValue > 0) || (item.maxValue !== null)) ? 1 : 0));
        return <React.Fragment>{title}{!count ? null : <Typography variant='body2' display='inline' component='span' style={{marginLeft: '8px'}}>{' (' + count + ')'}</Typography>}</React.Fragment>;
    }
*/
    // Template blocks isOpen
    get isWordsOpen()			{return 'ui.isWordsOpen'}
    get isOkpd2Open()			{return 'ui.isOkpd2Open'}
    get isRegionsOpen()			{return 'ui.isRegionsOpen'}
    get isCustomersOpen()		{return 'ui.isCustomersOpen'}
    get isProcTypesOpen()		{return 'ui.isProcTypesOpen'}
    get isBlackWordsOpen()		{return 'ui.isBlackWordsOpen'}
    get isBlackOkpd2Open()		{return 'ui.isBlackOkpd2Open'}
    get isBlackCustomersOpen()	{return 'ui.isBlackCustomersOpen'}
    get isOkpd2PartOpen()		{return 'ui.isOkpd2PartOpen'}
    get isEtpOpen()				{return 'ui.isEtpOpen'}
    get isTotalOpen()			{return 'ui.isTotalOpen'}
    get isAdvanceOpen()			{return 'ui.isAdvanceOpen'}
    get isSubscriptionOpen()	{return 'ui.isSubscriptionOpen'}
    get uiPropsArray()			{return [this.isWordsOpen, this.isOkpd2Open, this.isRegionsOpen, this.isCustomersOpen, this.isProcTypesOpen, this.isBlackWordsOpen, this.isBlackOkpd2Open, this.isBlackCustomersOpen, this.isOkpd2PartOpen, this.isEtpOpen, this.isSubscriptionOpen]}

    // Template settings
    get resultFormatValueRtf()		{return 'RTF'}
    get resultFormatValueRtfExcel()	{return 'RTF_EXCEL'}
    get isSendNotFound()		{return 'mail.send_not_found'}
    get resultFormat()			{return 'result.format.name'}
    get isResultFormatRtf()		{return 'result.format.rtf'}
    get isResultFormatExcel()	{return 'result.format.excel'}
    get isShowRegion()			{return 'result.show_region'}
    get isShowOkpd2()			{return 'result.show_okpd2'}
    get isShowEtp()				{return 'result.show_etp'}

    // Template main info
    get isSubscribed()			{return 'isSubscribed'}
    get minAdvance()			{return 'minAdvance'}
    get name()					{return 'name'}
    get letterSubject()			{return 'letterSubject'}

    // New template name
    get newTemplateName()		{return 'Новый поиск'}

    // Template id from url
    static get newTemplateId()	{return 'e29345ca418c1fe8cf5b1aa8bf84d9dc'} // '0'
    static get templateIdParamName()	{return 'id'}
    get templateIdParam() {
        return this.getTemplateIdParam(this.props.location.search);
    }
    getTemplateIdParam(search) {
        const id = new URLSearchParams(search).get(TemplatePage.templateIdParamName);
        return id; // return /^[\d]+$/.test(id) ? parseInt(id, 10) : null;
    }

    render() {
        const classes = this.props.classes;
        const {template, isLoading, infoMessages, isTemplateDeleteDialogOpened} = this.state;
        const {errors, warnings, notices} = this.state;
        const {search, okpd2Code, customer, blackWord, blackOkpd2Code, blackCustomer, templateName} = this.state;
        const totalRub = !template ? null : includesIgnoreCase(template.total, 'RUB', 'currencyCode');
        const totalUsd = !template ? null : includesIgnoreCase(template.total, 'USD', 'currencyCode');
        const totalEur = !template ? null : includesIgnoreCase(template.total, 'EUR', 'currencyCode');
        let isChanged = false;
        if (template && template.changed && !isLoading)
            Object.keys(template.changed).map(key => {return isChanged = isChanged || template.changed[key]});
        const pageData = !template ? null : (
            <React.Fragment>
                <TemplateBlock title={<BlockTitle title='Поиск' list={template.words} />} icon={<SearchIcon className={classes.accordionTitleIcon} />} isExpanded={template.open[this.isWordsOpen]} handleOpen={this.handleAccordionOpen.bind(this, this.isWordsOpen)}>
                    <Typography variant='body2'>Составьте список поисковых запросов. Слова указывайте полностью, не удаляя окончания. Поиск производится по всем падежам, единственному и множественному числу. <Link to={Routes.help} target='_blank'>Подробная инструкция.</Link></Typography>
                    <TemplateListInput autoFocus={true} isLoading={isLoading}
                        list={template.words}
                        valueName="search"
                        value={search}
                        fieldName={null}
                        label="Введите поисковый запрос"
                        autoComplete="search"
                        clearPattern={null}
                        onSubmit={this.handleSubmitWord.bind(this, 'words', 'search', this.getInfoMessagesForNewWords)}
                        onChange={this.handleChangeListItem}
                    />
                    <TemplateList isLoading={isLoading}
                        list={template.words}
                        fieldName={null}
                        onDelete={this.handleDeleteListItem.bind(this, 'words')}
                        itemElement={null}
                    />
                </TemplateBlock>
                <TemplateBlock title={<BlockTitle title='Коды ОКПД2' list={template.okpd2} />} icon={<SearchIcon className={classes.accordionTitleIcon} />} isExpanded={template.open[this.isOkpd2Open]} handleOpen={this.handleAccordionOpen.bind(this, this.isOkpd2Open)}>
                    <Typography variant='body2'>Будут найдены тендеры, содержащие товары указанных кодов <Link to={Routes.okpd2} target='_blank'>ОКПД2</Link>. Коды добавляйте в цифровом виде без описания, минимум 3 цифры. Примечание: тендеры по 615 ПП (Капитальный ремонт) не содержат кодов ОКПД2 и могут быть найдены только по текстовым запросам. <Link to={{pathname: Routes.help, hash: 'okpd2'}} target='_blank'>Подробная инструкция.</Link></Typography>
                    <TemplateListInput autoFocus={false} isLoading={isLoading}
                        list={template.okpd2}
                        valueName="okpd2Code"
                        value={okpd2Code}
                        fieldName={'code'}
                        label="Код ОКПД2 в цифровом виде"
                        autoComplete="off"
                        clearPattern={/[^\d,.\s]+/}
                        onSubmit={this.handleSubmitOkpd2.bind(this, 'okpd2', 'okpd2Code', 4, null)}
                        onChange={this.handleChangeListItem}
                    />
                    <TemplateList isLoading={isLoading}
                        list={template.okpd2}
                        fieldName={'code'}
                        onDelete={this.handleDeleteListItem.bind(this, 'okpd2')}
                        itemElement={Okpd2Element}
                    />
                </TemplateBlock>
                <TemplateBlock title={<BlockTitle title='Заказчики' list={template.customers} />} icon={<SearchIcon className={classes.accordionTitleIcon} />} isExpanded={template.open[this.isCustomersOpen]} handleOpen={this.handleAccordionOpen.bind(this, this.isCustomersOpen)}>
                    <Typography variant='body2'>В дополнение к поиску по словам и кодам ОКПД2 будут найдены тендеры указанных заказчиков. Заказчики идентифицируются по ИНН. <Link to={{pathname: Routes.help, hash: 'customers'}} target='_blank'>Подробная инструкция.</Link></Typography>
                    <TemplateListCustomerInput autoFocus={false} isLoading={isLoading}
                        list={template.customers}
                        value={customer}
                        onSubmit={this.handleSubmitCustomer.bind(this, 'customers', 'customer', null)}
                        onChange={this.handleChangeListCustomerItem.bind(this, 'customer')}
                    />
                    <TemplateList isLoading={isLoading}
                        list={template.customers}
                        fieldName={'inn'}
                        onDelete={this.handleDeleteListItem.bind(this, 'customers')}
                        itemElement={CustomerElement}
                    />
                </TemplateBlock>
                <TemplateBlock title={<BlockTitleCheckbox title='Регионы' list={template.regions} />} icon={<LocalAirportIcon className={classes.accordionTitleIcon} />} isExpanded={template.open[this.isRegionsOpen]} handleOpen={this.handleAccordionOpen.bind(this, this.isRegionsOpen)}>
                    <Typography variant='body2'>Будут найдены только тендеры, заказчики которых расположены в отмеченных регионах. <Link to={{pathname: Routes.help, hash: 'region'}} target='_blank'>Подробная инструкция.</Link></Typography>
                    <TemplateCheckboxList isLoading={isLoading}
                        list={template.regions}
                        name='regions'
                        fieldId='id' fieldName='name' fieldChapter='districtName' fieldIsChecked='isChecked'
                        onChange={this.handleCheckboxListChange.bind(this, 'regions', 'id', 'isChecked')}
                        onSelectAll={this.handleCheckboxListSelectAll.bind(this, 'regions', 1, 'isChecked')}
                        onSelectNone={this.handleCheckboxListSelectAll.bind(this, 'regions', 0, 'isChecked')}
                    />
                </TemplateBlock>
                <TemplateBlock title={<BlockTitleCheckbox title='Типы процедур' list={template.procedureTypes} />} icon={<FormatListBulletedIcon className={classes.accordionTitleIcon} />} isExpanded={template.open[this.isProcTypesOpen]} handleOpen={this.handleAccordionOpen.bind(this, this.isProcTypesOpen)}>
                    <Typography variant='body2'>Будут найдены только тендеры, имеющие отмеченные типы процедур. <Link to={{pathname: Routes.help, hash: 'procedure'}} target='_blank'>Подробная инструкция.</Link></Typography>
                    <TemplateCheckboxList isLoading={isLoading}
                        list={template.procedureTypes}
                        name='procedureTypes'
                        fieldId='id' fieldName='name' fieldChapter='lawName' fieldIsChecked='isChecked'
                        onChange={this.handleCheckboxListChange.bind(this, 'procedureTypes', 'id', 'isChecked')}
                        onSelectAll={this.handleCheckboxListSelectAll.bind(this, 'procedureTypes', 1, 'isChecked')}
                        onSelectNone={this.handleCheckboxListSelectAll.bind(this, 'procedureTypes', 0, 'isChecked')}
                    />
                </TemplateBlock>
                <TemplateBlock title={<BlockTitle title='Исключить слова и фразы' list={template.blackWords} />} icon={<HighlightOffIcon className={classes.accordionTitleIcon} />} isExpanded={template.open[this.isBlackWordsOpen]} handleOpen={this.handleAccordionOpen.bind(this, this.isBlackWordsOpen)}>
                    <Typography variant='body2'>Из результатов поиска будут исключены тендеры, содержащие слова и фразы из списка. <Link to={{pathname: Routes.help, hash: 'black_words'}} target='_blank'>Подробная инструкция.</Link></Typography>
                    <TemplateListInput autoFocus={false} isLoading={isLoading}
                        list={template.blackWords}
                        valueName="blackWord"
                        value={blackWord}
                        fieldName={null}
                        label="Слово или фраза для исключения"
                        autoComplete="off"
                        clearPattern={null}
                        onSubmit={this.handleSubmitWord.bind(this, 'blackWords', 'blackWord', null)}
                        onChange={this.handleChangeListItem}
                    />
                    <TemplateList isLoading={isLoading}
                        list={template.blackWords}
                        fieldName={null}
                        onDelete={this.handleDeleteListItem.bind(this, 'blackWords')}
                        itemElement={null}
                    />
                </TemplateBlock>
                <TemplateBlock title={<BlockTitle title='Исключить коды ОКПД2' list={template.blackOkpd2} />} icon={<HighlightOffIcon className={classes.accordionTitleIcon} />} isExpanded={template.open[this.isBlackOkpd2Open]} handleOpen={this.handleAccordionOpen.bind(this, this.isBlackOkpd2Open)}>
                    <Typography variant='body2'>Из результатов поиска будут исключены тендеры, содержащие товары указанных кодов ОКПД2 и их подкодов. <Link to={{pathname: Routes.help, hash: 'black_okpd2'}} target='_blank'>Подробная инструкция.</Link></Typography>
                    <TemplateListInput autoFocus={false} isLoading={isLoading}
                        list={template.blackOkpd2}
                        valueName="blackOkpd2Code"
                        value={blackOkpd2Code}
                        fieldName={'code'}
                        label="Код ОКПД2 для исключения"
                        autoComplete="off"
                        clearPattern={/[^\d,.\s]+/}
                        onSubmit={this.handleSubmitOkpd2.bind(this, 'blackOkpd2', 'blackOkpd2Code', 2, null)}
                        onChange={this.handleChangeListItem}
                    />
                    <TemplateList isLoading={isLoading}
                        list={template.blackOkpd2}
                        fieldName={'code'}
                        onDelete={this.handleDeleteListItem.bind(this, 'blackOkpd2')}
                        itemElement={Okpd2Element}
                    />
                </TemplateBlock>
                <TemplateBlock title={<BlockTitle title='Исключить заказчиков' list={template.blackCustomers} />} icon={<HighlightOffIcon className={classes.accordionTitleIcon} />} isExpanded={template.open[this.isBlackCustomersOpen]} handleOpen={this.handleAccordionOpen.bind(this, this.isBlackCustomersOpen)}>
                    <Typography variant='body2'>Из результатов поиска будут исключены тендеры указанных заказчиков. Заказчики идентифицируются по ИНН. <Link to={{pathname: Routes.help, hash: 'black_customers'}} target='_blank'>Подробная инструкция.</Link></Typography>
                    <TemplateListCustomerInput autoFocus={false} isLoading={isLoading}
                        list={template.blackCustomers}
                        value={blackCustomer}
                        onSubmit={this.handleSubmitCustomer.bind(this, 'blackCustomers', 'blackCustomer', null)}
                        onChange={this.handleChangeListCustomerItem.bind(this, 'blackCustomer')}
                    />
                    <TemplateList isLoading={isLoading}
                        list={template.blackCustomers}
                        fieldName={'inn'}
                        onDelete={this.handleDeleteListItem.bind(this, 'blackCustomers')}
                        itemElement={CustomerElement}
                    />
                </TemplateBlock>
                <TemplateBlock title={<BlockTitleCheckbox title='Разделы справочника ОКПД2' list={template.okpd2Parts} />} icon={<FormatListBulletedIcon className={classes.accordionTitleIcon} />} isExpanded={template.open[this.isOkpd2PartOpen]} handleOpen={this.handleAccordionOpen.bind(this, this.isOkpd2PartOpen)}>
                    <Typography variant='body2'>Отключение раздела аналогично добавлению всех кодов <Link to={Routes.okpd2} target='_blank'>ОКПД2</Link> данного раздела в чёрный список. Используйте данный фильтр только когда в нем есть необходимость (находится много неподходящих тендеров), и только после выполнения тестового поиска с отключенным фильтром и по его результатам определив разделы подходящих и не подходящих тендеров. <Link to={{pathname: Routes.help, hash: 'okpd2_part'}} target='_blank'>Подробная инструкция.</Link></Typography>
                    <TemplateCheckboxList isLoading={isLoading}
                        list={template.okpd2Parts}
                        name='okpd2Parts'
                        fieldId='code' fieldName='nameWithCode' fieldChapter={null} fieldIsChecked='isChecked'
                        onChange={this.handleCheckboxListChange.bind(this, 'okpd2Parts', 'code', 'isChecked')}
                        onSelectAll={this.handleCheckboxListSelectAll.bind(this, 'okpd2Parts', 1, 'isChecked')}
                        onSelectNone={this.handleCheckboxListSelectAll.bind(this, 'okpd2Parts', 0, 'isChecked')}
                    />
                </TemplateBlock>
                <TemplateBlock title={<BlockTitleCheckbox title='Электронные торговые площадки' list={template.etp} />} icon={<ComputerOutlinedIcon className={classes.accordionTitleIcon} />} isExpanded={template.open[this.isEtpOpen]} handleOpen={this.handleAccordionOpen.bind(this, this.isEtpOpen)}>
                    <Typography variant='body2'>{'Будут найдены только тендеры, проводимые на отмеченных электронных торговых площадках. Рекомендуем оставлять отмеченной запись "Не указана", так как у значительного числа тендеров торговая площадка не указана.'}</Typography>
                    <TemplateCheckboxList isLoading={isLoading}
                        list={template.etp}
                        name='etp'
                        fieldId='id' fieldName='nameWithHost' fieldChapter={null} fieldIsChecked='isChecked'
                        onChange={this.handleCheckboxListChange.bind(this, 'etp', 'id', 'isChecked')}
                        onSelectAll={this.handleCheckboxListSelectAll.bind(this, 'etp', 1, 'isChecked')}
                        onSelectNone={this.handleCheckboxListSelectAll.bind(this, 'etp', 0, 'isChecked')}
                    />
                </TemplateBlock>
                <TemplateBlock title={<BlockTitleTotal title='Ограничения по сумме' list={template.total} />} icon={<MoneyIcon className={classes.accordionTitleIcon} />} isExpanded={template.open[this.isTotalOpen]} handleOpen={this.handleAccordionOpen.bind(this, this.isTotalOpen)}>
                    <Typography variant='body2'>Ограничения, указанные для конкретной валюты, применяются только к тендерам, суммы которых указаны в данной валюте. <Link to={{pathname: Routes.help, hash: 'total'}} target='_blank'>Подробная инструкция.</Link></Typography>
                    <div style={{marginTop: '8px'}} />
                    <CurrencyFilter currencyCode='RUB' tooltip='Российский рубль' minValue={totalRub ? totalRub.minValue : ''} maxValue={totalRub ? totalRub.maxValue : ''} isLoading={isLoading}
                        onMinChange={this.handleCurrencyChange.bind(this, 'RUB', true)}
                        onMaxChange={this.handleCurrencyChange.bind(this, 'RUB', false)}
                        endAdornment={
                            <SvgIcon fontSize="small" viewBox='0 0 680 680'>
                                <path d="M34.786,428.963h81.158v69.572c0,3.385,1.083,6.156,3.262,8.322c2.173,2.18,4.951,3.27,8.335,3.27h60.502
                                    c3.14,0,5.857-1.09,8.152-3.27c2.295-2.166,3.439-4.938,3.439-8.322v-69.572h182.964c3.377,0,6.156-1.076,8.334-3.256
                                    c2.18-2.178,3.262-4.951,3.262-8.336v-46.377c0-3.365-1.082-6.156-3.262-8.322c-2.172-2.18-4.957-3.27-8.334-3.27H199.628v-42.754
                                    h123.184c48.305,0,87.73-14.719,118.293-44.199c30.551-29.449,45.834-67.49,45.834-114.125c0-46.604-15.283-84.646-45.834-114.125
                                    C410.548,14.749,371.116,0,322.812,0H127.535c-3.385,0-6.157,1.089-8.335,3.256c-2.173,2.179-3.262,4.969-3.262,8.335v227.896
                                    H34.786c-3.384,0-6.157,1.145-8.335,3.439c-2.172,2.295-3.262,5.012-3.262,8.151v53.978c0,3.385,1.083,6.158,3.262,8.336
                                    c2.179,2.18,4.945,3.256,8.335,3.256h81.158v42.754H34.786c-3.384,0-6.157,1.09-8.335,3.27c-2.172,2.166-3.262,4.951-3.262,8.322
                                    v46.377c0,3.385,1.083,6.158,3.262,8.336C28.629,427.887,31.401,428.963,34.786,428.963z M199.628,77.179h115.938
                                    c25.6,0,46.248,7.485,61.953,22.46c15.697,14.976,23.549,34.547,23.549,58.691c0,24.156-7.852,43.733-23.549,58.691
                                    c-15.705,14.988-36.354,22.473-61.953,22.473H199.628V77.179z"
                                />
                            </SvgIcon>
                        }
                    />
                    <CurrencyFilter currencyCode='USD' tooltip='Доллар США' minValue={totalUsd ? totalUsd.minValue : ''} maxValue={totalUsd ? totalUsd.maxValue : ''} isLoading={isLoading}
                        onMinChange={this.handleCurrencyChange.bind(this, 'USD', true)}
                        onMaxChange={this.handleCurrencyChange.bind(this, 'USD', false)}
                        endAdornment={<AttachMoneyIcon fontSize="small" />}
                    />
                    <CurrencyFilter currencyCode='EUR' tooltip='Евро' minValue={totalEur ? totalEur.minValue : ''} maxValue={totalEur ? totalEur.maxValue : ''} isLoading={isLoading}
                        onMinChange={this.handleCurrencyChange.bind(this, 'EUR', true)}
                        onMaxChange={this.handleCurrencyChange.bind(this, 'EUR', false)}
                        endAdornment={<EuroSymbolIcon fontSize="small" />}
                    />
                </TemplateBlock>
                <TemplateBlock title={<BlockTitleAdvance title='Минимальный аванс' minAdvance={template.minAdvance} />} icon={<MoneyIcon className={classes.accordionTitleIcon} />} isExpanded={template.open[this.isAdvanceOpen]} handleOpen={this.handleAccordionOpen.bind(this, this.isAdvanceOpen)}>
                    <Typography variant='body2'>Будут найдены только те тендеры, указанный размер аванса которых больше или равен заданному значению. Внимание: по статистике аванс указан только у 1% всех тендеров. Отсутствие указанного аванса не означает, что у тендера нет аванса. Он может быть прописан в тендерной документации, но не указан заказчиком явно в параметрах тендера.</Typography>
                    <div style={{marginTop: '8px'}} />
                    <PercentsInput name='minAdvance' label='Аванс не менее ...' value={template.minAdvance} tooltip='Процентов' onChange={this.handleValueChange.bind(this, /[^\d.]+/, this.minAdvance)} isLoading={isLoading}
                        endAdornment={
                            <SvgIcon fontSize="small" viewBox='0 0 24 24'>
                                <path d="M7.5 11C9.43 11 11 9.43 11 7.5S9.43 4 7.5 4 4 5.57 4 7.5 5.57 11 7.5 11m0-5C8.33 6 9 6.67 9 7.5S8.33 9 7.5 9 6 8.33 6 7.5 6.67 6 7.5 6M4.0025 18.5832 18.59 3.9955l1.4142 1.4143L5.4167 19.9974zM16.5 13c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5m0 5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5"/>
                            </SvgIcon>
                        }
                    />
                </TemplateBlock>
                <TemplateBlock title='Настройка рассылки' icon={template.isSubscribed ? <MailIcon className={classes.accordionTitleIcon} /> : <MailOutlineIcon className={classes.accordionTitleIcon} />} isExpanded={template.open[this.isSubscriptionOpen]} handleOpen={this.handleAccordionOpen.bind(this, this.isSubscriptionOpen)}>
                    <FormControlLabel label='Включить рассылку (присылать новые тендеры на email)' control={
                        <Switch name="isSubscribed" checked={Boolean(template.isSubscribed)} disabled={isLoading} onChange={this.handleSwitchValue.bind(this, this.isSubscribed)} color="secondary" />
                    }/>
                    <TextField name="name" label="Название шаблона, тема писем с новыми тендерами" type="text" autoComplete="off" value={template[this.name]} disabled={isLoading} variant="outlined" margin="normal" fullWidth onChange={this.handleNameChange} InputProps={{inputProps: {maxLength: 250}}} />
                    <FormControl component="fieldset" margin='normal'>
                        <FormLabel component="legend">Формат файлов с новыми тендерами:</FormLabel>
                        <RadioGroup name="sendFormat" value={template.settings[this.resultFormat]} onChange={this.handleResultFormatChange} row>
                            <FormControlLabel value={this.resultFormatValueRtf} control={<Radio color='primary' />} label="Word" />
                            <FormControlLabel value={this.resultFormatValueRtfExcel} control={<Radio color='primary' />} label="Word + Excel" />
                        </RadioGroup>
                    </FormControl>{/*
                    <FormControl component="fieldset" margin='dense'>
                        <FormLabel component="legend" style={{marginBottom: '6px'}}>Дополнительные данные в описании тендеров (в файле Word):</FormLabel>
                        <FormControlLabel label="Регионы заказчиков" className={classes.paddedControl} control={
                            <Checkbox name="isShowRegion" checked={Boolean(template.settings[this.isShowRegion])} disabled={isLoading} onChange={this.handleSwitchSetting.bind(this, this.isShowRegion)} color="primary" className={classes.denseCheckbox} />
                        }/>
                        <FormControlLabel label="Адреса электронных торговых площадок" className={classes.paddedControl} control={
                            <Checkbox name="isShowEtp" checked={Boolean(template.settings[this.isShowEtp])} disabled={isLoading} onChange={this.handleSwitchSetting.bind(this, this.isShowEtp)} color="primary" className={classes.denseCheckbox} />
                        }/>
                        <FormControlLabel label="Коды ОКПД2 товаров" className={classes.paddedControl} control={
                            <Checkbox name="isShowOkpd2" checked={Boolean(template.settings[this.isShowOkpd2])} disabled={isLoading} onChange={this.handleSwitchSetting.bind(this, this.isShowOkpd2)} color="primary" className={classes.denseCheckbox} />
                        }/>
                    </FormControl>*/}
                    <FormControlLabel label="Отправлять письма в дни, когда тендеры не найдены" control={
                        <Checkbox name="isSendNotFound" checked={Boolean(template.settings[this.isSendNotFound])} disabled={isLoading} onChange={this.handleSwitchSetting.bind(this, this.isSendNotFound)} color="primary" />
                    }/>
                </TemplateBlock>
                <div className={template.settings[this.isSubscriptionOpen] ? null : classes.saveButtonsBlock}>
                    <Button onClick={this.handleSave} startIcon={<SaveIcon />} variant='contained' color='primary' className={classes.saveButton}>Сохранить</Button>
                    <Button onClick={this.handleSaveAndFind} startIcon={<SearchIcon />} variant='contained' color='primary' className={classes.saveButton}>Найти тендеры</Button>
                    {('' + template.id === TemplatePage.newTemplateId) ? null : (
                        <Button onClick={this.handleDeleteTemplateDialogSwitch} startIcon={<DeleteForeverIcon />} variant='contained' color='primary' className={classes.saveButton}>Удалить</Button>
                    )}
                </div>
                <DeleteTemplateDialog open={isTemplateDeleteDialogOpened} templateName={template.name} handleClose={this.handleDeleteTemplateDialogSwitch} handleDelete={this.handleDeleteTemplate} />
                {/*<SaveBeforeSearchDialog open={isSaveBeforeSearchDialogOpened} handleClose={this.handleSaveBeforeSearchDialogSwitch} handleSave={this.handleSaveAndFind} handleNotSave={this.handleNotSaveAndFind} />*/}
                <Prompt when={Boolean(isChanged)} message='Вы уходите со страницы? Все внесенные изменения будут потеряны.' />
            </React.Fragment>
        );
        const infoMessage = (!infoMessages || !infoMessages.length) ? null : (
            <Snackbar key={infoMessages[0]} open={true} autoHideDuration={20000} onClose={this.handleInfoMessageClose}>
                <Alert onClose={this.handleInfoMessageClose} severity="info">{infoMessages[0]}</Alert>
            </Snackbar>
        );
        return (
            <div className={classes.longBlock}>
                <LoadingCursor isLoading={isLoading} />
                {/*<Typography align='center' variant='h5' gutterBottom >{(template && template.name) ? (('' + template.id === TemplatePage.newTemplateId) ? this.newTemplateName : template.name) : ''}</Typography>*/}
                <Typography align='center' variant='h5' gutterBottom >{templateName ? ((template && ('' + template.id === TemplatePage.newTemplateId)) ? this.newTemplateName : templateName) : ''}</Typography>
                {!isLoading ? null : <div align='center'><CircularProgress color="primary" /></div>}
                <Messages errors={errors} warnings={warnings} notices={notices} />
                {pageData}
                {infoMessage}
            </div>
        );
    }
}

export default withRouter(withStyles(styles, {withTheme: true})(TemplatePage));
