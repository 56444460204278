import { Config } from "./Config";


class API {
    static fetch(setAuth, apiUrl, version, f, params) {
        let request = {version, f, params};
        return fetch(apiUrl, {
            method: 'POST',
            headers: {'Content-Type': 'application/json;charset=utf-8'},
            credentials: 'same-origin',
            body: JSON.stringify(request)
        })
        .then(response => {
            if (response.ok) return response.json();
            else throw new Error(Config.commonErrorText);
        })
        .then(responseData => {
            if (setAuth)
                setAuth(responseData.auth);
            return responseData;
        });
    }
}

export default API;
