import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { Box, Grow } from '@material-ui/core';


const styles = theme => ({
    message: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
});

class Messages extends React.PureComponent {

    renderMessages(messages, severity, className) {
        if (this.emptyArray(messages))
            return null;
        return (
            <React.Fragment>
                {messages.map(message => (
                    <Alert severity={severity} key={message} className={className}>{message}</Alert>
                ))}
            </React.Fragment>
        );
    }

    emptyArray(a) {
        return !Array.isArray(a) || (a.length === 0);
    }

    join(a) {
        return this.emptyArray(a) ? '' : a.join('|');
    }

    render() {
        const classes = this.props.classes;
        const {errors, warnings, notices} = this.props;
        if (this.emptyArray(errors) && this.emptyArray(warnings) && this.emptyArray(notices))
            return null;
        const key = this.join(errors) + '|' + this.join(warnings) + '|' + this.join(notices);
        const errorsEl =	this.renderMessages(errors, 'error', classes.message);
        const warningsEl =	this.renderMessages(warnings, 'warning', classes.message);
        const noticesEl =	this.renderMessages(notices, 'info', classes.message);
        return (
            <Grow key={key} in={true}>
                <Box display='block' width='100%'>
                    {errorsEl}
                    {warningsEl}
                    {noticesEl}
                </Box>
            </Grow>
        );
    }
}


export default withStyles(styles, {withTheme: true})(Messages);

