import React from 'react';
import {Config} from './Config.js';
import {withStyles} from '@material-ui/core/styles';

import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Messages from './Messages.js';
import API from './API.js';
import AddOrgForm from './AddOrgForm.js';
import OrgDetails from './OrgDetails.js';
import LoadingCursor from './LoadingCursor.js';

const styles = theme => ({
    icon: {
        marginRight: theme.spacing(1),
    },
    longBlock: {
        minHeight: '600px',
    },
    notice: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        fontStyle: 'italic',
    },
});

  
class OrganizationsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            warnings: [],
            notices: [],
            organizations: [],
            isLoading: true,
            isNewOrgExpanded: false,
        };
        this.newOrgRef = React.createRef();
        this.addOrganization = this.addOrganization.bind(this);
        this.handleExpandNewOrg = this.handleExpandNewOrg.bind(this);
        this.focusOnNewOrg = this.focusOnNewOrg.bind(this);
    }

    handleExpandNewOrg() {
        this.setState(prevState => ({isNewOrgExpanded: !prevState.isNewOrgExpanded}));
    }

    focusOnNewOrg() {
        this.newOrgRef.current.focus();
    }

    sortOrganizations(organizations) {
        return organizations.sort((o1, o2) => (parseInt(o1.id, 10) - parseInt(o2.id, 10)));
    }
    
    componentDidMount() {
        this.props.appProps.setHead('Мои организации', '');
        this.loadOrganizations();
    }

    loadOrganizations() {
        return API.fetch(this.props.appProps.setAuth,
            Config.billingApiUrl,
            '1.0',
            'getOrganizations',
            {}
        )
        .then(responseData => {
            let {errors, warnings, notices, content} = responseData;
            content = Array.isArray(content) ? content : [];
            content = this.sortOrganizations(content);
            this.setState({errors, warnings, notices, organizations: content, isLoading: false});
        })
        .catch(() => this.setState({isLoading: false, errors: [Config.commonErrorText]}));
    }

    addOrganization(organization) {
        this.setState(prevState => ({organizations: this.sortOrganizations(prevState.organizations.concat(organization)), isNewOrgExpanded: false}));
    }

    render() {
        const classes = this.props.classes;
        const {appProps} = this.props;
        const {isLoading, isNewOrgExpanded, organizations} = this.state;
        const {errors, warnings, notices} = this.state;
        const pageData = isLoading ? (
            <CircularProgress color="primary" />
        ) : (
            organizations.map(organization => (
                <Accordion key={organization.id}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        <AccountBalanceIcon color='primary' fontSize='large' className={classes.icon} />
                        <Typography variant='h6'>{organization.shortName}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <OrgDetails organization={organization} addNewInvoiceLink={true} />
                    </AccordionDetails>
                </Accordion>
            ))
        );
        return (
            <div className={classes.longBlock}>
                <LoadingCursor isLoading={isLoading} />
                <Typography variant='h4' gutterBottom >Мои организации</Typography>
                <Messages errors={errors} warnings={warnings} notices={notices} />
                {pageData}
                <Accordion expanded={isNewOrgExpanded} onChange={this.handleExpandNewOrg} TransitionProps={{onEntered: this.focusOnNewOrg}} >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        <AddCircleOutlineIcon color='secondary' fontSize='large' className={classes.icon} />
                        <Typography variant='h6'>Добавить организацию</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AddOrgForm appProps={appProps} onAddOrg={this.addOrganization} firstInputRef={this.newOrgRef} submitText='Сохранить' backText={null} onBack={null} />
                    </AccordionDetails>
                </Accordion>
                <Typography className={classes.notice}>* Для изменения реквизитов существующей организации пишите на {Config.supportLink}</Typography>
            </div>
        );
    }
}

export default withStyles(styles, {withTheme: true})(OrganizationsPage);
