import React from 'react';
import {Link, Route, Switch} from 'react-router-dom';
import {Routes} from './Config.js';
import {withStyles} from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ComputerIcon from '@material-ui/icons/Computer';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import { Card, CardContent, Divider, Tooltip } from '@material-ui/core';


const styles = theme => ({
    accountIconLarge: {
        marginBottom: theme.spacing(2),
        fontSize: 86,
    },
    centerBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    accountCard: {
        padding: theme.spacing(1),
        width: '280px',
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
});


class AccountCard extends React.Component {

    render() {
        const classes = this.props.classes;
        const {auth, onClose} = this.props;

        const paidToTitle = auth.isPaid ? 'Оплачено до:' : 'Пробный период до:'
        const notConfirmed = auth.isConfirmed ? null : (
            <Tooltip
                placement="bottom-end"
                title={
                    <Typography variant='body2' align='left'>
                        {'При регистрации вам было отправлено письмо подтверждения. Перейтите по ссылке из письма. Если письмо не пришло, обратитесь в техническую поддержку.'}
                    </Typography>
                }
            >
                <div className={classes.centerBox} style={{cursor: 'pointer'}}><Typography color="error" variant='body2' paragraph align='center'>(Учётная запись не подтверждена)</Typography></div>
            </Tooltip>
        );
        return (
            <Card className={classes.accountCard}>
                <CardContent>
                    <div className={classes.centerBox}><AccountCircle color='secondary' className={classes.accountIconLarge} /></div>
                    <div className={classes.centerBox}><Typography variant='h6' align='center'>{auth.name}</Typography></div>
                    <div className={classes.centerBox}><Typography variant='subtitle1' align='center'>{auth.login}</Typography></div>
                    {notConfirmed}
                    <Switch>
                        <Route path={Routes.lk} />
                        <Route path='/'>
                            <div className={classes.centerBox}><Button component={Link} to={Routes.lk} onClick={onClose} startIcon={<ComputerIcon />} color="primary">Личный кабинет</Button></div>
                        </Route>
                    </Switch>
                    <Typography paragraph />
                    <Typography variant='subtitle1' align='left'>{paidToTitle}</Typography>
                    <Typography variant='body2' align='left' color={auth.isActive ? 'initial' : 'error'}><b>{auth.paidTo}{auth.isActive ? null : ' (истёк)'}</b></Typography>
                    <Button component={Link} to={Routes.newInvoice} onClick={onClose} startIcon={<AddIcon fontSize='small' />} size='medium' color='primary' style={{textTransform: 'none', paddingLeft: 0}}>Выставить новый счёт</Button>
                    <Typography paragraph />
                </CardContent>
                <Divider className={classes.divider} />
                <div className={classes.centerBox}><Button component={Link} to={Routes.logout} onClick={onClose} startIcon={<ExitToAppIcon />} color="primary">Выход</Button></div>
            </Card>
        );
    }
}


export default withStyles(styles, {withTheme: true})(AccountCard);

