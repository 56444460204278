import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, TextField, Tooltip, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import {includesIgnoreCase} from './Utils.js';


const styles = theme => ({
    accordion: {
        '&$accordionExpanded': {
        },
    },
    accordionExpanded: {
    },
    accordionSummaryRoot: {
        background: theme.palette.background.default,
    },
    accordionSummaryExpanded: {
        background: theme.palette.grey[100],
    },
    accordionDetailsRoot: {
        flexDirection: 'column',
    },
    paddedControl: {
        marginLeft: theme.spacing(2),
    },
    denseCheckbox: {
        padding: '2px',
    },
    lightTooltip: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.primary,
        boxShadow: theme.shadows[1],
    },
    listItemIcon: {
        minWidth: '32px',
    },
    accordionTitleIcon: {
        minWidth: '48px',
    },
    secondaryActionHidden: {
        //'&:hover': {
        //    display: 'block!important',
        //},
    },
    listItem: {
        paddingTop: 0,
        paddingBottom: 0,
        "& ~ $secondaryActionHidden": {
            display: 'none',
        },
        '&:hover': {
            backgroundColor: theme.palette.background.default,
            "& ~ $secondaryActionHidden": {
                display: 'block',
            },
        },
        "& ~ $secondaryActionHidden:hover": {
            display: 'block',
        },
    },
    currencyInput: {
        marginRight: theme.spacing(1),
    },
    percentsInput: {
        marginRight: theme.spacing(1),
    },
    checkboxListItem: {
        paddingTop: '4px',
        paddingBottom: 0,
    },
    checkboxListChapter: {
        margin: theme.spacing(2, 0, 1, 3),
        fontWeight: 'bold',
        color: theme.palette.text.primary,
    },
    checkboxListContainer: {
        height: '400px',
        overflow: 'auto',
        marginTop: theme.spacing(2),
        marginRight: -theme.spacing(2),
        marginBottom: -theme.spacing(2),
    }
});


class PercentsInput_ extends React.Component {
    shouldComponentUpdate(nextProps) {
        const {name, value, isLoading} = this.props;
        return (name !== nextProps.name) || (value !== nextProps.value) || (isLoading !== nextProps.isLoading);
    }

            /*
            <Grid container>
                <Grid item xs={12} sm={6} md={4} lg={3} >
                </Grid>
            </Grid>
            */
    render() {
        const {name, label, value, tooltip, endAdornment, onChange, isLoading, classes} = this.props;
        return (
            <div display='block'>
                <TextField name={name} label={label} value={value} onChange={onChange} disabled={isLoading} variant="outlined" margin="dense" type="text" autoComplete='off' className={classes.percentsInput}
                    InputProps={{
                        inputProps: {maxLength: 5, inputMode: 'numeric'},
                        endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip classes={{tooltip: classes.lightTooltip}} title={
                                    <Typography variant='body2' align='left'>{tooltip}</Typography>}
                                >
                                    {endAdornment}
                                </Tooltip>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        )
    }
}
const PercentsInput = withStyles(styles, {withTheme: true})(PercentsInput_);


class CurrencyInput_ extends React.Component {
    shouldComponentUpdate(nextProps) {
        const {name, value, isLoading} = this.props;
        return (name !== nextProps.name) || (value !== nextProps.value) || (isLoading !== nextProps.isLoading);
    }

    render() {
        const {name, label, value, tooltip, endAdornment, onChange, isLoading, classes} = this.props;
        return (
            <TextField name={name} label={label} value={value ? value : ''} onChange={onChange} disabled={isLoading} variant="outlined" margin="dense" type="text" autoComplete='off' className={classes.currencyInput}
                InputProps={{
                    inputProps: {inputMode: 'numeric'},
                    endAdornment: (
                        <InputAdornment position="end">
                            <Tooltip classes={{tooltip: classes.lightTooltip}} title={
                                <Typography variant='body2' align='left'>{tooltip}</Typography>}
                            >
                                {endAdornment}
                            </Tooltip>
                        </InputAdornment>
                    ),
                }}
            />
        )
    }
}
const CurrencyInput = withStyles(styles, {withTheme: true})(CurrencyInput_);

class CurrencyFilter extends React.Component {
    shouldComponentUpdate(nextProps) {
        const {currencyCode, minValue, maxValue, isLoading} = this.props;
        return (currencyCode !== nextProps.currencyCode) || (minValue !== nextProps.minValue) || (maxValue !== nextProps.maxValue) || (isLoading !== nextProps.isLoading);
    }

    render() {
        const {currencyCode, minValue, maxValue, onMinChange, onMaxChange, tooltip, endAdornment, isLoading} = this.props;
        return (
            <div display='block'>
                <CurrencyInput name={currencyCode + 'minValue'} label='От' value={minValue} tooltip={tooltip} endAdornment={endAdornment} onChange={onMinChange} isLoading={isLoading} />
                <CurrencyInput name={currencyCode + 'maxValue'} label='До' value={maxValue} tooltip={tooltip} endAdornment={endAdornment} onChange={onMaxChange} isLoading={isLoading} />
            </div>
        )
    }
}

class TemplateListInput_ extends React.Component {
    shouldComponentUpdate(nextProps) {
        const {list, valueName, value, isLoading} = this.props;
        return (list !== nextProps.list) || (valueName !== nextProps.valueName) || (value !== nextProps.value) || (isLoading !== nextProps.isLoading);
    }

    render() {
        const {list, valueName, value, fieldName, label, autoComplete, clearPattern, autoFocus, onSubmit, onChange, isLoading, classes} = this.props;
        return (
            <Grid container>
                <Grid item xs={12} sm={12} md={9} lg={6} >
                    <form className={classes.form} onSubmit={onSubmit} method='POST'>
                        <TextField disabled={isLoading} variant="outlined" margin="normal" fullWidth name={valueName} type="text" autoComplete={autoComplete} autoFocus={autoFocus}
                            label={label}
                            value={value}
                            onChange={event => onChange(clearPattern, event)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip classes={{tooltip: classes.lightTooltip}} title={
                                            <Typography variant='body2' align='left'>{(value && includesIgnoreCase(list, value, fieldName)) ? 'Значение уже есть в списке' : 'Добавить в список'}</Typography>}
                                        ><span>
                                            <IconButton type="submit" disabled={isLoading || !value || Boolean(includesIgnoreCase(list, value, fieldName))} color="secondary">
                                                <AddBoxOutlinedIcon />
                                            </IconButton>                
                                        </span></Tooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </form>
                </Grid>
            </Grid>
        )
    }
}
const TemplateListInput = withStyles(styles, {withTheme: true})(TemplateListInput_);

class TemplateListCustomerInput_ extends React.Component {
    shouldComponentUpdate(nextProps) {
        const {list, value, isLoading} = this.props;
        return (list !== nextProps.list) || (value !== nextProps.value) || (isLoading !== nextProps.isLoading);
    }

    render() {
        const {list, value, autoFocus, onSubmit, onChange, isLoading, classes} = this.props;
        return (
            <form className={classes.form} onSubmit={onSubmit} method='POST'>
                <Grid container spacing={1} style={{marginTop: '16px'}}>
                    <Grid item xs={12} sm={12} md={4} lg={3} >
                        <TextField disabled={isLoading} variant="outlined" margin="none" fullWidth name='inn' type="text" required autoFocus={autoFocus} autoComplete='off'
                            label='ИНН заказчика'
                            value={value.inn}
                            onChange={event => onChange(event)}
                            InputProps={{inputProps: {maxLength: 12, minLength: 10, inputMode: 'numeric', pattern: '\\d{10}|\\d{12}'}}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={9} >
                        <Collapse in={Boolean(value.inn)}>
                        <TextField disabled={isLoading} variant="outlined" margin="none" fullWidth name='name' type="text" autoComplete='off'
                            label='Наименование'
                            value={value.name}
                            onChange={event => onChange(event)}
                            InputProps={{inputProps: {maxLength: 250}}}
                        />
                        </Collapse>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                        <Collapse in={Boolean(value.inn)}>
                        <TextField disabled={isLoading} variant="outlined" margin="none" fullWidth name='note' type="text" autoComplete='off'
                            label='Комментарий'
                            value={value.note}
                            onChange={event => onChange(event)}
                            InputProps={{inputProps: {maxLength: 500}}}
                        />
                        </Collapse>
                    </Grid>
                    <Grid item xs={12}>
                        <Collapse in={Boolean(value.inn)}>
                        <Button type="submit" disabled={isLoading || Boolean(includesIgnoreCase(list, value.inn, 'inn'))} variant="contained" color="primary" >Добавить</Button>
                        </Collapse>
                    </Grid>
                </Grid>
            </form>
        )
    }
}
const TemplateListCustomerInput = withStyles(styles, {withTheme: true})(TemplateListCustomerInput_);

class TemplateCheckboxForList_ extends React.Component {
    shouldComponentUpdate(nextProps) {
        const {item, isLoading} = this.props;
        return (item !== nextProps.item) || (isLoading !== nextProps.isLoading);
    }

    render() {
        const {item, name, fieldId, fieldName, fieldIsChecked, onChange, isLoading, classes} = this.props;
        return (
            <FormControlLabel label={item[fieldName]} key={item[fieldId]}
                control={<Checkbox id={item[fieldId]} name={name + '[]'} checked={Boolean(item[fieldIsChecked])} onChange={onChange} color='primary' size='small' disabled={isLoading} classes={{root: classes.checkboxListItem}} />}
            />
        );
    }
}
const TemplateCheckboxForList = withStyles(styles, {withTheme: true})(TemplateCheckboxForList_);

class TemplateCheckboxList_ extends React.Component {
    shouldComponentUpdate(nextProps) {
        const {list, isLoading} = this.props;
        return (list !== nextProps.list) || (isLoading !== nextProps.isLoading);
    }

    render() {
        const {list, name, fieldId, fieldName, fieldChapter, fieldIsChecked, onChange, onSelectAll, onSelectNone, isLoading, classes} = this.props;
        let prevChapter = null;
        let listItems = [];
        for (let item of list) {
            if (fieldChapter) {
                if (item[fieldChapter] !== prevChapter)
                    listItems.push(<FormLabel key={item[fieldChapter]} component="legend" className={classes.checkboxListChapter}>{item[fieldChapter]}</FormLabel>);
                prevChapter = item[fieldChapter];
            }
            listItems.push(
                //<FormControlLabel label={item[fieldName]} key={item[fieldId]}
                //    control={<Checkbox id={item[fieldId]} name={name + '[]'} checked={Boolean(item[fieldIsChecked])} onChange={onChange} color='primary' size='small' disabled={isLoading} classes={{root: classes.checkboxListItem}} />}
                ///>
                <TemplateCheckboxForList key={item[fieldId]} item={item} name={name} fieldId={fieldId} fieldName={fieldName} fieldIsChecked={fieldIsChecked} onChange={onChange} isLoading={isLoading}/>
            );
        }
        return (
            <React.Fragment>
                <div display='block' style={{marginTop: '16px'}}>
                    {!onSelectAll ? null : <Button onClick={onSelectAll} variant="contained" color='primary' size='small' style={{marginRight: '8px'}}>Отметить все</Button>}
                    {!onSelectNone ? null : <Button onClick={onSelectNone} variant="contained" color='primary' size='small' style={{marginRight: '8px'}}>Снять все</Button>}
                </div>
                <div className={classes.checkboxListContainer}>
                    <FormGroup>
                        {listItems}
                    </FormGroup>
                </div>
            </React.Fragment>
        )
    }
}
const TemplateCheckboxList = withStyles(styles, {withTheme: true})(TemplateCheckboxList_);

class TemplateList_ extends React.Component {
    shouldComponentUpdate(nextProps) {
        const {list, isLoading} = this.props;
        return (list !== nextProps.list) || (isLoading !== nextProps.isLoading);
    }

    render() {
        const {list, fieldName, onDelete, isLoading, itemElement, classes} = this.props;
        //console.log('Render list ' + this.props.listName);
        return (
            <List>
                {list.map(item => (
                    <ListItem key={fieldName ? item[fieldName] : item} className={classes.listItem} classes={{container: classes.listItem}}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <ArrowRightIcon fontSize='small' />
                        </ListItemIcon>
                        <ListItemText primary={itemElement ? React.createElement(itemElement, {item: item}) : item} />
                        <ListItemSecondaryAction className={classes.secondaryActionHidden}>
                            <IconButton disabled={isLoading} edge='end' size='small' onClick={() => onDelete(item)}>
                                <ClearIcon fontSize='small' color='error' />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        )
    }
}
const TemplateList = withStyles(styles, {withTheme: true})(TemplateList_);

class Okpd2Element extends React.Component {
    shouldComponentUpdate(nextProps) {
        const {item} = this.props;
        const {nextItem} = nextProps;
        return !item || !nextItem || (item.code !== nextItem.code) || (item.name !== nextItem.name);
    }

    render() {
        const {item} = this.props;
        return !item ? null : (
            <React.Fragment>
                <b>{item.code}</b>
                {!item.name ? null : (
                    <React.Fragment>
                        {' - '}
                        <span>{item.name}</span>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

class CustomerElement extends React.Component {
    shouldComponentUpdate(nextProps) {
        const {item} = this.props;
        const {nextItem} = nextProps;
        return !item || !nextItem || (item.inn !== nextItem.inn) || (item.name !== nextItem.name) || (item.note !== nextItem.note);
    }

    render() {
        const {item} = this.props;
        const title = item.name ? (item.name + ' (ИНН: ' + item.inn + ')') : item.inn;
        const note = !item.note ? null : <Typography variant='body2' style={{fontStyle: 'italic'}}>{item.note}</Typography>;
        return !item ? null : (
            <React.Fragment>
                {title}
                {note}
            </React.Fragment>
        );
    }
}

class TemplateBlock_ extends React.PureComponent {
    render() {
        const children = this.props.children;
        const {icon, title, isExpanded, handleOpen, classes} = this.props;
        return (
            <Accordion expanded={Boolean(isExpanded)} TransitionProps={{unmountOnExit: true}} onChange={handleOpen} classes={{root: classes.accordion, expanded: classes.accordionExpanded}}>
                <AccordionSummary classes={{root: classes.accordionSummaryRoot, expanded: classes.accordionSummaryExpanded}} expandIcon={<ExpandMoreIcon />} >
                    {icon}
                    <Typography>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails classes={{root: classes.accordionDetailsRoot}}>
                    {children}
                </AccordionDetails>
            </Accordion>
        )
    }
}
const TemplateBlock = withStyles(styles, {withTheme: true})(TemplateBlock_);

class DeleteTemplateDialog extends React.PureComponent {
    render() {
        const {open, templateName, handleClose, handleDelete} = this.props;
        return (
            <Dialog open={open} onClose={handleClose} >
                <DialogTitle>Удалить шаблон</DialogTitle>
                <DialogContent>
                    <DialogContentText>{'Вы действительно хотите удалить шаблон поиска "' + templateName + '"?'}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>Отмена</Button>
                    <Button onClick={handleDelete} color="primary">Удалить</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

class SaveBeforeSearchDialog extends React.PureComponent {
    render() {
        const {open, handleClose, handleSave, handleNotSave} = this.props;
        return (
            <Dialog open={open} onClose={handleClose} >
                <DialogTitle>Поиск тендеров</DialogTitle>
                <DialogContent>
                    <DialogContentText>{'Сохранить внесенные в настройки поиска изменения?'}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleNotSave} color="primary">Не сохранять</Button>
                    <Button onClick={handleSave} color="primary" autoFocus>Сохранить</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

class GoAwayConfirmDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
        }
        this.handleNo = this.handleNo.bind(this);
        this.handleYes = this.handleYes.bind(this);
    }

    handleYes() {
        this.props.callback(true);
        this.setState({open: false});
    }

    handleNo() {
        this.props.callback(false);
        this.setState({open: false});
    }

//    UNSAFE_componentWillReceiveProps() {
//        this.setState({open: true});
//    }

    componentDidUpdate(prevProps) {
        if (this.props.callback !== prevProps.callback)
            this.setState({open: true});
    }

    render() {
        const {message} = this.props;
        const {open} = this.state;
        return (
            <Dialog open={open} onClose={this.handleNo} >
                <DialogContent>
                    <DialogContentText>{message}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleNo} color="primary" autoFocus>Отмена</Button>
                    <Button onClick={this.handleYes} color="primary">Да</Button>
                </DialogActions>
            </Dialog>
        )
    }
}

class BlockTitle extends React.PureComponent {
    render() {
        const {title, list} = this.props;
        //return title + (list.length ? (' (' + list.length + ')') : '');
        return <React.Fragment>{title}{!list.length ? null : <Typography variant='body2' component='span' display='inline' style={{marginLeft: '8px'}}>{' (' + list.length + ')'}</Typography>}</React.Fragment>;
    }
}

class BlockTitleCheckbox extends React.PureComponent {
    render() {
        const {title, list} = this.props;
        let checkedCount = 0;
        list.map(item => (checkedCount += item.isChecked ? 1 : 0));
        return <React.Fragment>{title}{(checkedCount === list.length) ? null : <Typography variant='body2' component='span' display='inline' style={{marginLeft: '8px'}}>{' (' + checkedCount + '/' + list.length + ')'}</Typography>}</React.Fragment>;
    }
}

class BlockTitleTotal extends React.PureComponent {
    render() {
        const {title, list} = this.props;
        let count = 0;
        list.map(item => (count += ((item.minValue > 0) || (item.maxValue !== null)) ? 1 : 0));
        return <React.Fragment>{title}{!count ? null : <Typography variant='body2' display='inline' component='span' style={{marginLeft: '8px'}}>{' (' + count + ')'}</Typography>}</React.Fragment>;
    }
}

class BlockTitleAdvance extends React.PureComponent {
    render() {
        const {title, minAdvance} = this.props;
        let count = (minAdvance > 0) ? 1 : 0;
        return <React.Fragment>{title}{!count ? null : <Typography variant='body2' display='inline' component='span' style={{marginLeft: '8px'}}>{' (' + count + ')'}</Typography>}</React.Fragment>;
    }
}


export {BlockTitle, BlockTitleCheckbox, BlockTitleTotal, BlockTitleAdvance, GoAwayConfirmDialog, DeleteTemplateDialog, SaveBeforeSearchDialog, PercentsInput, CurrencyInput, CurrencyFilter, TemplateListInput, TemplateListCustomerInput, TemplateCheckboxList, TemplateList, Okpd2Element, CustomerElement, TemplateBlock};
