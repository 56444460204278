import React from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import {Config, Routes} from './Config.js';

import LoginPage from './LoginPage.js';
import AppLK from './AppLK.js';

//import NotFoundPage from './NotFoundPage.js';
import RegisterPage from './RegisterPage.js';
import LogoutPage from './LogoutPage.js';
import HomePage from './HomePage.js';
import ConfidentialPage from './ConfidentialPage.js';
import RestorePasswordPage from './RestorePasswordPage.js';
import ConfirmEmailPage from './ConfirmEmailPage.js';
import NewPasswordPage from './NewPasswordPage.js';
import UnsubscribePage from './UnsubscribePage.js';
import AppNavigationOld from './AppNavigationOld.js';


class AppNavigation extends React.Component {
    componentDidMount() {
        if (window.ym)
            window.ym(Config.yaMetricId,'hit',window.location.href);
    }

    componentDidUpdate(prevProps) {
        if ((this.props.location.pathname !== prevProps.location.pathname) || (this.props.location.search !== prevProps.location.search))
            if (window.ym)
                window.ym(Config.yaMetricId, 'hit', window.location.href);
    }

    render() {
        const {appProps} = this.props;
        return (
            <Switch>
                <Route path={Routes.lk}>				<AppLK appProps={appProps} />		</Route>

                <Route path={Routes.confidential}>		<ConfidentialPage appProps={appProps} />	</Route>
                <Route path={Routes.login}>				<LoginPage appProps={appProps} />			</Route>
                <Route path={Routes.logout}>			<LogoutPage appProps={appProps} />			</Route>
                <Route path={Routes.register}>			<RegisterPage appProps={appProps} />		</Route>
                <Route path={Routes.confirm}>		    <ConfirmEmailPage appProps={appProps} />	</Route>
                <Route path={Routes.restore}>			<RestorePasswordPage appProps={appProps} />	</Route>
                <Route path={Routes.newPassword}>		<NewPasswordPage appProps={appProps} />		</Route>
                <Route path={Routes.unsubscribe}>		<UnsubscribePage appProps={appProps} />		</Route>

                <Route path={Routes.home} exact>		<HomePage appProps={appProps} />			</Route>
                {/*<Route path='/'>						<NotFoundPage appProps={appProps} />		</Route>*/}
                <Route path='/'>						<AppNavigationOld appProps={appProps} />		</Route>
            </Switch>
        );
    }
}


export default withRouter(AppNavigation);
