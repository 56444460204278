import React from 'react';
import {withRouter} from 'react-router';
import {Config} from './Config.js';
import {withStyles} from '@material-ui/core/styles';

import { Button, TextField, Typography } from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import TemplateFormPage from './TemplateFormPage.js';
import API from './API.js';
import LoadingCursor from './LoadingCursor.js';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});


class RestorePasswordPage extends React.Component {
    constructor(props) {
        super(props);
        const {appProps} = this.props;
        const {auth} = appProps;
        this.state = {
            errors: [],
            warnings: [],
            notices: [],
            email: (auth && auth.login) ? auth.login : '',
            isLoading: false,
            isSent: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    componentDidMount() {
        this.props.appProps.setHead('Восстановление пароля', '');
    }

    handleChange(event) {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({[name]: value});
    }

    handleSubmit(event) {
        const {isLoading, isSent, email} = this.state;
        const isSubmitEnabled = !isLoading && !isSent && Boolean(email.trim());
        if (isSubmitEnabled) {
            this.setState({isLoading: true});
            this.sendRestorePasswordCode(email);
        }
        event.preventDefault();
    }

    sendRestorePasswordCode(email) {
        return API.fetch(this.props.appProps.setAuth,
            Config.authApiUrl,
            '1.0',
            'sendRestorePasswordCode',
            {
                login: email,
            }
        )
        .then(responseData => {
            const {errors, warnings, notices} = responseData;
            const isSent = !errors || !errors.length;
            this.setState({errors, warnings, notices, isLoading: false, isSent: isSent});
        })
        .catch(() => this.setState({isLoading: false, errors: [Config.commonErrorText]}));
    }

    render() {
        const classes = this.props.classes;
        const {appProps} = this.props;
        const {auth} = appProps;
        const {isLoading, isSent, email, errors, warnings, notices} = this.state;
        let content = null;
        if (isSent)
            content = <Typography align='justify'>Для ввода нового пароля перейдите по ссылке, отправленной вам на email. Ссылка действительна в течение 3 часов.</Typography>;
        else
            content = (
                <form className={classes.form} onSubmit={this.handleSubmit} method='POST'>
                    <TextField disabled={(auth && auth.login) || isLoading} value={email} variant="outlined" margin="normal" required fullWidth name="email" label="Укажите ваш email" type="email" id="email" autoComplete="email" autoFocus onChange={this.handleChange} InputProps={{inputProps: {maxLength: 320}}} />
                    <Button type="submit" disabled={isLoading} fullWidth variant="contained" color="primary" className={classes.submit} >
                        Восстановить пароль
                    </Button>
                </form>
            );
        return (
            <React.Fragment>
                <LoadingCursor isLoading={isLoading} />
                <TemplateFormPage
                    title='Восстановить пароль'
                    icon={<VpnKeyIcon />}
                    isDivider={isSent}
                    content={content}
                    errors={errors} warnings={warnings} notices={notices}
                />
            </React.Fragment>
        );
    }
}

export default withRouter(withStyles(styles, {withTheme: true})(RestorePasswordPage));
