import React from 'react';
import {withRouter} from 'react-router';
import {withStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import {Config, Routes} from '../Config.js';
import Okpd2SearchBar from './Okpd2SearchBar.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import Messages from '../Messages.js';
import API from '../API.js';
import { Button } from '@material-ui/core';
//import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LoadingCursor from '../LoadingCursor.js';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';


const styles = theme => ({
    okpd2TreeCode: {
        fontFamily: '"Times New Roman", Times, serif',
        fontSize: '16px',
        fontWeight: 'bold',
    },    
    okpd2TreeName: {
        fontFamily: '"Times New Roman", Times, serif',
        fontSize: '16px',
    },
    okpd2Chapter: {
        fontFamily: '"Times New Roman", Times, serif',
        fontSize: '16px',
        fontWeight: 'bold',
        marginLeft: '30px',
    },
    okpd2FoundBlock: {
    },
    okpd2Found: {
    },
    allOkpd2Button: {
        //margin: theme.spacing(2, 0),
    }
});

  
class Okpd2SearchPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            results: null,
            errors: null,
            warnings: null,
            notices: null,
        };
    }

    searchOkpd2() {
        const search =	this.search;
        if (!search)
            return;
        return API.fetch(this.props.appProps.setAuth,
            Config.okpd2ApiUrl,
            '1.0',
            'searchOkpd2',
            {
                search: search,
            }
        )
        .then(responseData => {
            const {errors, warnings, notices, content} = responseData;
            this.setState({
                loaded: true,
                errors: errors,
                warnings: warnings,
                notices: notices,
                results: content,
            });
        })
        .catch(() => this.setState({errors: [Config.commonErrorText]}));
    }
    
    componentDidMount() {
        this.props.appProps.setHead('Справочник ОКПД2', 'Справочник ОКПД2. Общероссийский классификатор продукции по видам экономической деятельности');
        this.searchOkpd2();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search)
        {
            this.setState({
                loaded: false,
                errors: null,
                warnings: null,
                notices: null,
                results: null,
            })
            this.searchOkpd2();
        }
    }

    renderResults(results) {
        const classes = this.props.classes;
        if (results === null)
            return null;
        if (!Array.isArray(results) || (results.length === 0))
            return <h3>По вашему запросу коды не найдены</h3>;
        let prevChapter = null;
        return (
            <div className={classes.okpd2FoundBlock}>
                {results.map((result) => {
                    const chapter = (result.chapter_code === prevChapter) ? null :
                        <p className={classes.okpd2Chapter} key={result.chapter_code}>{result.chapter_name}</p>;
                    prevChapter = result.chapter_code;
                    return [chapter, 
                        <p className={classes.okpd2Found} key={result.code}>
                            <span className={classes.okpd2TreeCode}>{result.code}</span>
                            <span className={classes.okpd2TreeName} dangerouslySetInnerHTML={{__html: ' - ' + result.name}} />
                        </p>
                    ];
                })}
            </div>
        );
    }
    
    get search() {
        return new URLSearchParams(this.props.location.search).get(Okpd2SearchBar.searchParamName);
    }

    render() {
        const classes = this.props.classes;
        const {loaded} = this.state;
        const {errors, warnings, notices} = this.state;
        const search =	this.search;
        const results =	this.renderResults(this.state.results);
        const progress = loaded ? null : <CircularProgress color="primary" />;
        return (
            <React.Fragment>
                <LoadingCursor isLoading={!loaded} />
                <h1>Справочник ОКПД2 (общероссийский классификатор продукции по видам экономической деятельности)</h1>
                {/*<Button component={Link} to={Routes.okpd2} size='large' color='primary' startIcon={<ArrowBackIcon/>} style={{textTransform: 'none', fontSize: '20px', marginBottom: '16px'}}>Перейти к полному справочнику</Button>*/}
                <Button variant='contained' color='primary' component={Link} to={Routes.okpd2} startIcon={<FormatListBulletedIcon />} className={classes.allOkpd2Button} >
                    Полный справочник
                </Button>
                <br />
                <br />
                <Messages errors={errors} warnings={warnings} notices={notices} />
                <Okpd2SearchBar search={search} />
                <br />
                <h3 style={{marginBottom: '24px'}}>{'Результаты поиска по запросу "' + search + '":'}</h3>
                {progress}
                {results}
            </React.Fragment>
        );
    }
}

export default withRouter(withStyles(styles, {withTheme: true})(Okpd2SearchPage));
