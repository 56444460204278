import React from 'react';
import ReactDOM from 'react-dom';
import {Helmet} from "react-helmet";
import {BrowserRouter as Router} from 'react-router-dom';
import {createMuiTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import {Config} from './Config.js';

import AppHeader from './AppHeader.js';
import AppNavigation from './AppNavigation.js';

import { ruRU } from '@material-ui/core/locale';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Backdrop, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import ScrollToTop from './ScrollToTop.js';
import API from './API.js';
import { GoAwayConfirmDialog } from './TemplateComponents.js';


const pageWidth = false; // 'xl';
const pageWidthPx = '1450px';

const myTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#1976d2', // Blue 700
            light: '#63a4ff', // '#63a4ff',
            dark: '#004ba0', // '#004ba0',
        },
        secondary: {
            main: '#90cc00', // '#c6ff00', // Lime A400
            light: '#c5ff4d', // '#fdff58',
            dark: '#5c9b00', // '#90cc00',
        },
        background: {
            default: '#edeef0',
        }
    },
}, ruRU);

const styles = (theme) => ({
    root: {
        display: 'flex',
    },
    appHeader: {
        height: AppHeader.height,
        minHeight: AppHeader.height,
    },
    box: {
        position: 'relative',
        top: AppHeader.height,
        display: 'flex',
        maxWidth: pageWidthPx + '!important',
    },
    progress: {
        color: theme.palette.secondary.main,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 2,
    },
});


class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMenuLoading: false,
            isInitError: false,
            head: {
                title: 'Сервис поиска и аналитики тендеров',
                description: 'Сервис поиска и аналитики тендеров',
            },
            menu: null,
            isMobileMenuOpen: false,
            auth: null,
        };
        this.setHead = this.setHead.bind(this);
        this.setAuth = this.setAuth.bind(this);
        this.getMenu = this.getMenu.bind(this);
        this.handleMobileMenuToggle = this.handleMobileMenuToggle.bind(this);
        this.handleRepeatInit = this.handleRepeatInit.bind(this);
        this.isMenuLoading = false;
    }

    handleMobileMenuToggle() {
        this.setState(prevState => ({isMobileMenuOpen: !prevState.isMobileMenuOpen}));
    }

    setHead(title, description) {
        const prevHead = this.state.head;
        if (!prevHead || title !== prevHead.title || description !== prevHead.description)
            this.setState(() => ({head: {title: title, description: description, setHead: this.setHead}}));

        // Close mobile menu on head change
        if (this.state.isMobileMenuOpen)
            this.handleMobileMenuToggle();
    }

    setAuth(auth) {
        if (!auth) // Set only empty auth object or full auth. Don't set null auth
            return;
        const prevAuth = this.state.auth;
        if (!prevAuth ||
            prevAuth.login !== auth.login ||
            prevAuth.isConfirmed !== auth.isConfirmed ||
            prevAuth.isBan !== auth.isBan ||
            prevAuth.isActive !== auth.isActive ||
            prevAuth.isPaid !== auth.isPaid ||
            prevAuth.paidTo !== auth.paidTo
        ) {
            let prevState_ = null;
            this.setState((prevState) => {
                prevState_ = prevState;
                if (!auth.login) // No auth, reset menu
                    return {auth: auth, menu: null};
                else
                    return {auth: auth};
            });
            const {menu, isMenuLoading} = prevState_;
            if (!menu && auth.login && !this.isMenuLoading && !isMenuLoading) // Do only for authorized user with no menu
                this.getMenu(false);
        }
    }

    componentDidMount() {
        // Check auth and init menu
        this.getMenu(true);
    }

    handleRepeatInit() {
        this.setState({isInitError: false})
        this.getMenu(true);
    }

    getMenu(isSetAuth) {
        this.isMenuLoading = true;
        this.setState({isMenuLoading: true});
        const setAuth = isSetAuth ? this.setAuth : null;
        return API.fetch(setAuth,
            Config.dataApiUrl,
            '1.0',
            'getMenu',
            {}
        )
        .then(responseData => {
            const {errors, content, auth} = responseData;
            if ((!errors || !errors.length) && content) { // No errors and have content
                this.setState({ // At first set menu
                    isMenuLoading: false,
                    menu: {
                        templates: content.templates,
                        favourites: content.favourites,
                        canAddNewSearch: content.canAddNewSearch,
                        canAddNewFavourite: content.canAddNewFavourite,
                    },
                })
                this.isMenuLoading = false;
            }
            else if (!auth) { // Errors in getMenu and no auth
                this.setState({isMenuLoading: false, isInitError: true});
                this.isMenuLoading = false;
            }
            else { // In any case isMenuLoading = false
                this.setState({isMenuLoading: false});
                this.isMenuLoading = false;
            }
        })
        .catch(() => { // Error getting response from API
            this.setState({isMenuLoading: false, isInitError: true});
            this.isMenuLoading = false;
        });
    }

    render() {
        const classes = this.props.classes; // useStyles();
        const {isInitError, head, menu, auth, isMobileMenuOpen} = this.state;
        const {setAuth, setHead, getMenu, handleMobileMenuToggle} = this;
        const appProps = {auth, menu, setAuth, setHead, getMenu, isMobileMenuOpen, handleMobileMenuToggle};
        const isInitialized = Boolean(auth); // auth !== null
        const initError = !isInitError ? null : (
            <Dialog open={true} >
                <DialogTitle id="loading-error-dialog-title">Ошибка загрузки</DialogTitle>
                <DialogContent>
                    <DialogContentText id="loading-error-dialog-description">{'При загрузке страницы произошла ошибка. Попробуйте обновить страницу. Если ошибка сохранится, обратитесь в техническую поддержку '}{Config.supportLink}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleRepeatInit} color="primary">Обновить страницу</Button>
                </DialogActions>
            </Dialog>
        );
        const content = isInitialized ? (
            <Container className={classes.box} maxWidth={pageWidth}>
                <AppNavigation appProps={appProps} />
            </Container>
        ) : (
            <Backdrop className={classes.backdrop} open={true}>
                <CircularProgress className={classes.progress} classes={{circle: classes.progress}} />
                {initError}
            </Backdrop>
        );
        return (
            <ThemeProvider theme={myTheme}>
                <Helmet>
                    <title>{Config.titlePrefix + (head.title ? ' - ' + head.title : '')}</title>
                    <meta name='description' content={head.description ? head.description : ''} />
                </Helmet>
                <div id='goAwayConfirmContainer' />
                <Router
                    getUserConfirmation={(message, callback) => {
                        ReactDOM.render((
                            <GoAwayConfirmDialog message={message} callback={callback} />
                        ), document.getElementById('goAwayConfirmContainer'));
                    }}
                >
                    <div className={classes.root}>
                        <CssBaseline />
                        <AppHeader auth={auth} handleMobileMenuToggle={handleMobileMenuToggle} />
                        <ScrollToTop />
                        {content}
                    </div>
                </Router>
            </ThemeProvider>
        );
    }
}

export default withStyles(styles, {withTheme: true, defaultTheme: myTheme})(App);
