import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

import { Button, Card, CardActions, CardContent, Collapse, Grid, IconButton, Link, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import SearchIcon from '@material-ui/icons/Search';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SearchPage from './SearchPage.js';
import { formatMarkedText } from './Utils.js';
import { Routes } from './Config.js';
import AppHeader from './AppHeader.js';
import { Pagination, PaginationItem } from '@material-ui/lab';
import StarIcon from '@material-ui/icons/Star';
//import StarBorderIcon from '@material-ui/icons/StarBorder';

const rowsXs = [12, 12, 12];
const rowsSm = [3, 6, 3];
const rowsMd = [3, 6, 3];
const rowsLg = [2, 8, 2];

const styles = theme => ({
    tender: {
        margin: theme.spacing(0, 1, 6, 1),
    },
    actions: {
        background: theme.palette.background.default, // '#EFF0F1',
    },
    etpLink: {
        textTransform: 'none',
    },
    lightTooltip: {
        backgroundColor: theme.palette.common.white, // theme.palette.grey[100]
        color: theme.palette.text.primary,
        boxShadow: theme.shadows[1],
        maxWidth: '600px',
    },
    tooltipFont: {
        fontFamily: '"Times New Roman", Times, serif, Georgia', lineHeight: 'normal',
        fontSize: '12pt',
    },

    law: {
        fontFamily: '"Times New Roman", Times, serif, Georgia', lineHeight: 'normal',
        fontSize: '16pt',
        fontWeight: 'bold',
        color: 'gray',
    },
    procType: {
        fontFamily: '"Times New Roman", Times, serif, Georgia', lineHeight: 'normal',
        fontSize: '12pt',
        fontWeight: 'bold',
    },
    customer: {
        fontFamily: '"Times New Roman", Times, serif, Georgia', lineHeight: 'normal',
        fontSize: '12pt',
        color: 'gray',
    },
    description: {
        fontFamily: '"Times New Roman", Times, serif, Georgia', lineHeight: 'normal',
        fontSize: '12pt',
        marginTop: '6pt',
    },
    submissionCloseTitle: {
        fontFamily: '"Times New Roman", Times, serif, Georgia', lineHeight: 'normal',
        fontSize: '12pt',
        fontWeight: 'bold',
        color: 'gray',
    },
    submissionClose: {
        fontFamily: '"Times New Roman", Times, serif, Georgia', lineHeight: 'normal',
        fontSize: '12pt',
    },
    totalSumInt: {
        fontFamily: '"Times New Roman", Times, serif, Georgia', lineHeight: 'normal',
        fontSize: '16pt',
        fontWeight: 'bold',
    },
    totalSumPart: {
        fontFamily: '"Times New Roman", Times, serif, Georgia', lineHeight: 'normal',
        fontSize: '12pt',
        fontWeight: 'bold',
        marginLeft: '3pt',
    },
    currencyName: {
        fontFamily: '"Times New Roman", Times, serif, Georgia', lineHeight: 'normal',
        fontSize: '10pt',
        color: 'gray',
    },
    beforeAdvance: {
        marginTop: '12pt',
    },
    advance: {
        fontFamily: '"Times New Roman", Times, serif, Georgia', lineHeight: 'normal',
        fontSize: '11pt',
        color: 'gray',
    },
    lotDescription: {
        fontFamily: '"Times New Roman", Times, serif, Georgia', lineHeight: 'normal',
        fontSize: '12pt',
        marginBottom: '12pt',
    },
    itemsTitle: {
        fontFamily: '"Times New Roman", Times, serif, Georgia', lineHeight: 'normal',
        fontSize: '10pt',
        fontWeight: 'bold',
        color: 'gray',
    },
    itemDescription: {
        fontFamily: '"Times New Roman", Times, serif, Georgia', lineHeight: 'normal',
        fontSize: '10pt',
    },
    contractExpirationTitle: {
        fontFamily: '"Times New Roman", Times, serif, Georgia', lineHeight: 'normal',
        fontSize: '10pt',
        fontWeight: 'bold',
        color: 'gray',
        marginTop: '12pt',
    },
    okpd2ChaptersTitle: {
        fontFamily: '"Times New Roman", Times, serif, Georgia', lineHeight: 'normal',
        fontSize: '10pt',
        fontWeight: 'bold',
        color: 'gray',
        marginTop: '12pt',
    },
    okpd2Chapter: {
        fontFamily: '"Times New Roman", Times, serif, Georgia', lineHeight: 'normal',
        fontSize: '10pt',
    },
    okpd2Code: {
        textDecoration: 'underline',
    },
    okpd2Name: {
    },
    moreItemsButton: {
        fontFamily: '"Times New Roman", Times, serif, Georgia', lineHeight: 'normal',
        fontSize: '10pt',
        textTransform: 'none',
        fontWeight: 'bold',
        paddingLeft: 0,
    },
    cgTextLink: {
        textDecoration: 'underline',
    },

    nestedRequestItem: {
        paddingLeft: theme.spacing(4),
    },
    listItemIcon: {
        minWidth: '32px',
    },
    disabledListItem: {
        //opacity: '1!important',
    },
    selectedListItem: {
        opacity: '1!important',
    },
    detailsLink: {
        textDecoration: 'underline',
    },
    detailsNoLink: {
    },

    lotsNumbersBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        margin: theme.spacing(2, 1, 1, 2),
    },
    lotsNumbers: {
        //margin: theme.spacing(4, 1, 1, 2),
        marginRight: theme.spacing(1),
        fontFamily: '"Times New Roman", Times, serif, Georgia', lineHeight: 'normal',
        //fontSize: '12pt',
        fontSize: '14pt',
        fontWeight: 'bold',
    },
    favouritesIcon: {
    },
    favouritesIconButton: {
        padding: 0,
        marginLeft: theme.spacing(1),
    },

    anchor: {
        paddingTop: AppHeader.height + 'px',
        marginTop: '-' + AppHeader.height + 'px',
    },

});


class Tender_ extends React.PureComponent {
    render() {
        const classes = this.props.classes;
        const {tender, isNeedPayment} = this.props;
        return (
            <Card className={classes.tender} raised>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={rowsXs[0]} sm={rowsSm[0]} md={rowsMd[0]} lg={rowsLg[0]}>
                            <Typography className={classes.law}>{tender.law}</Typography>
                            <Typography className={classes.procType}>{tender.procedureTypeDetailed}</Typography>
                        </Grid>
                        <Grid item xs={rowsXs[1]} sm={rowsSm[1]} md={rowsMd[1]} lg={rowsLg[1]}>
                            <Typography className={classes.customer}>{tender.customerString}</Typography>
                            <Typography className={classes.description}>{formatMarkedText(tender.description)}</Typography>
                        </Grid>
                        <Grid item xs={rowsXs[2]} sm={rowsSm[2]} md={rowsMd[2]} lg={rowsLg[2]}>
                            <Typography className={classes.submissionCloseTitle}>Окончание подачи заявок</Typography>
                            <Typography className={classes.submissionClose}>{tender.submissionCloseDateString}</Typography>
                            {!tender.submissionCloseTimeString ? null : <Typography className={classes.submissionClose}>{tender.submissionCloseTimeString}</Typography>}
                            {!tender.timeStringToSubmissionClose ? null : <Typography className={classes.submissionClose}>({tender.timeStringToSubmissionClose})</Typography>}
                        </Grid>
                        {!(tender.lots && tender.lots.length) ? null : tender.lots.map(lot => (
                            <Lot key={lot.lotNo} lot={lot} />
                        ))}
                    </Grid>
                </CardContent>
                <CardActions className={classes.actions}>
                    {isNeedPayment ? (
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Button size="small" color="primary" disabled>Ссылки на документацию будут доступны после оплаты</Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={1}>
                            <Grid item xs>
                                <Button size="small" color="primary" component={Link} href={tender.url} target='_blank' disabled={!tender.url}>Документация</Button>
                            </Grid>
                            <Grid item xs='auto'> {/* <Grid item xs style={{textAlign: 'right'}}> */}
                                {!tender.etpLabel ? null : (
                                    tender.etpHref ? (
                                        <Button size="small" color="primary" component={Link} href={tender.etpHref} target='_blank' className={classes.etpLink}>{tender.etpLabel}</Button>
                                    ) : (
                                        <Button size="small" color="primary" component={Link} disabled className={classes.etpLink}>{tender.etpLabel}</Button>
                                    )
                                )}
                            </Grid>
                        </Grid>
                    )}
                </CardActions>
            </Card>
        );
    }
}
const Tender = withStyles(styles, {withTheme: true})(Tender_);


class Lot_ extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isShowAllItems: false,
        };
        this.handleShowAllItems = this.handleShowAllItems.bind(this);
    }

    handleShowAllItems() {
        this.setState({isShowAllItems: true});
    }

    render() {
        const classes = this.props.classes;
        const {lot} = this.props;
        const {isShowAllItems} = this.state;
        const okpd2Chapters = lot.okpd2Chapters ? Object.entries(lot.okpd2Chapters).sort((a, b) => b[0].localeCompare(a[0])) : null;
        return (
            <React.Fragment>
                <Grid item xs={rowsXs[0]} sm={rowsSm[0]} md={rowsMd[0]} lg={rowsLg[0]}>
                    <Typography className={classes.totalSumInt}>
                        {lot.totalSumInt}
                        <span className={classes.totalSumPart}>{lot.totalSumPart}</span>
                    </Typography>
                    <Typography className={classes.currencyName}>{lot.currencyName}</Typography>
                    {(!lot.agPart && !lot.cgPart && !lot.cgText && !lot.advancePercents) ? null : <Typography className={classes.beforeAdvance}></Typography>}
                    {!lot.advancePercents ? null : <Typography className={classes.advance}>Аванс: {lot.advancePercents}%</Typography>}
                    {(!lot.agPart && !lot.cgPart && !lot.cgText) ? null : <Typography className={classes.advance}>Обеспечение</Typography>}
                    {!lot.agPart ? null : <Typography className={classes.advance}> - заявки: {lot.agPart}%</Typography>}
                    {!lot.cgPart ? null : <Typography className={classes.advance}> - контракта: {lot.cgPart}%</Typography>}
                    {!(lot.cgText && !lot.cgPart) ? null :
                        <Typography className={classes.advance}>{' - контракта: '}
                            <Tooltip classes={{tooltip: classes.lightTooltip}} placement='bottom-start' title={
                                <Typography className={classes.tooltipFont} align='left'>{lot.cgText}</Typography>}
                            >
                                <span className={classes.cgTextLink}>есть</span>
                            </Tooltip>
                        </Typography>
                    }
                </Grid>
                <Grid item xs={rowsXs[1]} sm={rowsSm[1]} md={rowsMd[1]} lg={rowsLg[1]}>
                    {!lot.description ? null : <Typography className={classes.lotDescription}>{formatMarkedText(lot.description)}</Typography>}
                    {!(lot.itemsForView && lot.itemsForView.length) ? null : <Typography className={classes.itemsTitle}>Состав лота:</Typography>}
                    {!(lot.itemsForView && lot.itemsForView.length) ? null : lot.itemsForView.map(item => (
                        <LotItem key={item.itemNo} item={item} />
                    ))}
                    {!(lot.itemsMoreString && !isShowAllItems) ? null : <Button color='primary' className={classes.moreItemsButton} onClick={this.handleShowAllItems}>- {lot.itemsMoreString}</Button>}
                    {!(lot.itemsTruncated && lot.itemsTruncated.length) ? null : (
                        <Collapse in={Boolean(isShowAllItems)} unmountOnExit>
                            {lot.itemsTruncated.map(item => (
                                <LotItem key={item.itemNo} item={item} />
                            ))}
                        </Collapse>
                    )}
                    {!(okpd2Chapters && okpd2Chapters.length) ? null : <Typography className={classes.okpd2ChaptersTitle}>Лот относится к разделам справочника ОКПД2:</Typography>}
                    {!(okpd2Chapters && okpd2Chapters.length) ? null : okpd2Chapters.map(okpd2 => (
                        <Typography key={okpd2[0]} className={classes.okpd2Chapter}>- {okpd2[1]}</Typography>
                    ))}
                    {!lot.contractExpiration ? null :
                        <Typography className={classes.contractExpirationTitle}>Срок исполнения контракта: {lot.contractExpiration}</Typography>
                    }
                </Grid>
                <Grid item xs={rowsXs[2]} sm={rowsSm[2]} md={rowsMd[2]} lg={rowsLg[2]}>
                </Grid>
            </React.Fragment>
        );
    }
}
const Lot = withStyles(styles, {withTheme: true})(Lot_);


class LotItem_ extends React.PureComponent {
    render() {
        const classes = this.props.classes;
        const {item} = this.props;

        return (!item.description && !item.okpd2Code) ? null : (
            <React.Fragment>
                <Typography className={classes.itemDescription}>
                    {'- '}{formatMarkedText(item.description)}
                    {!(item.description && item.okpd2Code) ? null : ' '}
                    {!item.okpd2Code ? null : <React.Fragment>{'('}<Okpd2 code={item.okpd2Code} name={item.okpd2Name} isShowName={!item.description} />{')'}</React.Fragment>}
                </Typography>
            </React.Fragment>
        );
    }
}
const LotItem = withStyles(styles, {withTheme: true})(LotItem_);


class Okpd2_ extends React.PureComponent {
    render() {
        const classes = this.props.classes;
        const {code, name, isShowName} = this.props;

        if (!code)
            return null;
        return (
            <React.Fragment>
                <Tooltip classes={{tooltip: classes.lightTooltip}} placement='bottom-start' title={
                    <Typography className={classes.tooltipFont} align='left'>{code}{name ? (' - ' + name) : null}</Typography>}
                >
                    <span className={classes.okpd2Code}>ОКПД2: {code}</span>
                </Tooltip>
                {!(name && isShowName) ? null : <span className={classes.okpd2Name}> - {name}</span>}
            </React.Fragment>
        );
    }
}
const Okpd2 = withStyles(styles, {withTheme: true})(Okpd2_);


class SearchResultsMenu_ extends React.PureComponent {
    makeTitle(title, count) {
        //return <span>{title}{count ? (<b>{' (' + count + ')'}</b>) : null}</span>;
        return title + (count ? (' (' + count + ')') : '');
    }

    render() {
        const classes = this.props.classes;
        const {disabled, templateId, date, total, words, okpd2, customers, selectedWordId, selectedOkpd2Code, selectedCustomerInn, isRequestsOpen, onRequestsOpenSwitch} = this.props;
        const isAllSelected = !selectedWordId && !selectedOkpd2Code && !selectedCustomerInn;
        const baseUrlParams = date ? {[SearchPage.templateIdParamName]: templateId, [SearchPage.dateParamName]: date} : {[SearchPage.templateIdParamName]: templateId};
        return (!(total && total.lotCount && words && okpd2 && customers)) ? null : (
            <List dense component="div">
                <ListItem button disabled={disabled || isAllSelected} selected={isAllSelected} component={RouterLink} to={Routes.addParamsToUrl(Routes.search, baseUrlParams)} classes={{disabled: classes.disabledListItem, selected: classes.selectedListItem}} >
                    <ListItemIcon><SearchIcon /></ListItemIcon>
                    <ListItemText primary={this.makeTitle('Все тендеры', total.lotCount)} />
                </ListItem>
                <ListItem button onClick={onRequestsOpenSwitch} disabled={disabled}>
                    <ListItemIcon>{isRequestsOpen ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
                    <ListItemText primary='Детализация по запросам' />
                </ListItem>
                <Collapse in={Boolean(isRequestsOpen)} unmountOnExit>
                    <List dense component="div">
                        {!words ? null : words.map(word => {
                            const isSelected = (word.id === selectedWordId);
                            const isLink = !isSelected && word.lotCount;
                            return (
                                <ListItem key={'w' + word.id} button disabled={disabled || !isLink} selected={isSelected} className={classes.nestedRequestItem} classes={{disabled: classes.disabledListItem, selected: classes.selectedListItem}} component={RouterLink} to={Routes.addParamsToUrl(Routes.search, Object.assign({}, baseUrlParams, {[SearchPage.selectedWordIdParamName]: word.id}))} >
                                    <ListItemIcon className={classes.listItemIcon}>{word.lotCount ? <SearchIcon fontSize='small' /> : <ArrowRightIcon />}</ListItemIcon>
                                    <ListItemText className={clsx({[classes.detailsLink]: word.lotCount, [classes.detailsNoLink]: !word.lotCount})} primary={this.makeTitle(word.word, word.lotCount)} />
                                </ListItem>
                            );
                        })}
                        {!okpd2 ? null : okpd2.map(o => {
                            const isSelected = (o.code === selectedOkpd2Code);
                            const isLink = !isSelected && o.lotCount;
                            return (
                                <ListItem key={'o' + o.code} button disabled={disabled || !isLink} selected={isSelected} className={classes.nestedRequestItem} classes={{disabled: classes.disabledListItem, selected: classes.selectedListItem}} component={RouterLink} to={Routes.addParamsToUrl(Routes.search, Object.assign({}, baseUrlParams, {[SearchPage.selectedOkpd2CodeParamName]: o.code}))} >
                                    <ListItemIcon className={classes.listItemIcon}>{o.lotCount ? <SearchIcon fontSize='small' /> : <ArrowRightIcon />}</ListItemIcon>
                                    <ListItemText className={clsx({[classes.detailsLink]: o.lotCount, [classes.detailsNoLink]: !o.lotCount})} primary={<span><b>{o.code}</b> - {this.makeTitle(o.name, o.lotCount)}</span>} />
                                </ListItem>
                            );
                        })}
                        {!customers ? null : customers.map(c => {
                            const isSelected = (c.inn === selectedCustomerInn);
                            const isLink = !isSelected && c.lotCount;
                            return (
                                <ListItem key={'c' + c.inn} button disabled={disabled || !isLink} selected={isSelected} className={classes.nestedRequestItem} classes={{disabled: classes.disabledListItem, selected: classes.selectedListItem}} component={RouterLink} to={Routes.addParamsToUrl(Routes.search, Object.assign({}, baseUrlParams, {[SearchPage.selectedCustomerInnParamName]: c.inn}))} >
                                    <ListItemIcon className={classes.listItemIcon}>{c.lotCount ? <SearchIcon fontSize='small' /> : <ArrowRightIcon />}</ListItemIcon>
                                    <ListItemText className={clsx({[classes.detailsLink]: c.lotCount, [classes.detailsNoLink]: !c.lotCount})} primary={this.makeTitle(c.shortName + ' (ИНН ' + c.inn + ')', c.lotCount)} />
                                </ListItem>
                            );
                        })}
                    </List>
                </Collapse>
            </List>
        );
    }
}
const SearchResultsMenu = withStyles(styles, {withTheme: true})(SearchResultsMenu_);


class TendersList_ extends React.PureComponent {
    render() {
        const classes = this.props.classes;
        const {isNumerateTenders, tenders, favourites, favouritesMenu, firstLotIndex, isNeedPayment, ankhorRef, onAddFavourite, onRemoveFavourite} = this.props;
        if (!(tenders && tenders.length))
            return null;
        let lotIndex = firstLotIndex;
        const tendersList = tenders.map(tender => {
            let indexes = [];
            if (isNumerateTenders)
                indexes[0] = lotIndex++;
            else
                for (let i = 0; tender.lots && (i < tender.lots.length); i++)    
                    indexes[i] = lotIndex++;
            const curFavourites = favourites[tender.registrationNumber];
            return (
                <React.Fragment key={tender.id}>
                    <div className={classes.lotsNumbersBlock}>
                        <Typography display="inline" className={classes.lotsNumbers}>{indexes.join(', ')}.</Typography>
                        <FavouriteIcons favourites={curFavourites} favouritesMenu={favouritesMenu} registrationNumber={tender.registrationNumber} onAdd={onAddFavourite} onRemove={onRemoveFavourite} />                    
                    </div>
                    <Tender tender={tender} isNeedPayment={isNeedPayment} />
                </React.Fragment>
            );
        });
        return (
            <React.Fragment>
                <div ref={ankhorRef} className={classes.anchor} />
                {tendersList}
            </React.Fragment>
        );
    }
}
const TendersList = withStyles(styles, {withTheme: true})(TendersList_);


class FavouriteIcons extends React.PureComponent {
    render() {
        const {favourites, favouritesMenu, registrationNumber, onAdd, onRemove} = this.props;
        if (!favouritesMenu)
            return null;
        return !favouritesMenu ? null : 
            favouritesMenu.map(favouritesMenuItem => <FavouriteIcon key={registrationNumber + '_' + favouritesMenuItem.id} favouritesMenuItem={favouritesMenuItem} isChecked={favourites && favourites.includes(favouritesMenuItem.id)} onAdd={() => onAdd(favouritesMenuItem.id, registrationNumber)} onRemove={() => onRemove(favouritesMenuItem.id, registrationNumber)} />);
    }
}


class FavouriteIcon_ extends React.PureComponent {
    render() {
        const classes = this.props.classes;
        const {favouritesMenuItem, isChecked, onAdd, onRemove} = this.props;
        return !favouritesMenuItem ? null :
            <Tooltip placement='bottom-start' title={
                <Typography className={classes.tooltipFont} align='left'>{favouritesMenuItem.name}</Typography>}
            >
                <IconButton className={classes.favouritesIconButton} onClick={isChecked ? onRemove : onAdd}>
                    {isChecked ?
                        <StarIcon fontSize="default" className={classes.favouritesIcon} style={{color: favouritesMenuItem.color}} /> :
                        <StarIcon fontSize="default" className={classes.favouritesIcon} style={{color: '#bababa'}} />
                        /*<StarBorderIcon fontSize="default" className={classes.favouritesIcon} />*/
                    }
                </IconButton>
            </Tooltip>

        ;
    }
}
const FavouriteIcon = withStyles(styles, {withTheme: true})(FavouriteIcon_);


class TendersPagination extends React.PureComponent {
    render() {
        const {isLoading, baseUrl, tendersCount, pageSize, pageParam, pageParamName} = this.props;
        const pagesCount = Math.floor((tendersCount + pageSize - 1) / pageSize);
        return (!(tendersCount && (tendersCount > pageSize))) ? null : (
            <Pagination disabled={isLoading} variant="outlined" shape="rounded" page={pageParam ? pageParam : 1} count={pagesCount} style={{float: 'right'}}
                renderItem={(item) => (
                    <PaginationItem component={RouterLink}
                        to={(item.page === 1) ? baseUrl : Routes.addParamToUrl(baseUrl, pageParamName, item.page)}
                        {...item}
                        disabled={item.disabled || item.selected}
                    />
                )}
            />
        );
    }
}


export {TendersList, SearchResultsMenu, TendersPagination};
