import React from 'react';
import { Routes } from './Config';
  
class HomePage extends React.Component {

    componentDidMount() {
        this.props.appProps.setHead('', '');
        Routes.externalRedirect(Routes.homeTest);
    }

    render() {
        return null;
    }
}

export default HomePage;
