import React from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import {Config, Routes} from './Config.js';
import {withStyles} from '@material-ui/core/styles';

import { Button, CircularProgress, Typography } from '@material-ui/core';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import EmailIcon from '@material-ui/icons/Email';
import TemplateFormPage from './TemplateFormPage.js';
import API from './API.js';
import LoadingCursor from './LoadingCursor.js';

const styles = theme => ({
});

  
class ConfirmEmailPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            warnings: [],
            notices: [],
            isLoading: true,
        };
    }

    componentDidMount() {
        this.props.appProps.setHead('Подтверждение регистрации', '');
        this.confirmEmail();
    }
    
    confirmEmail() {
        const login = this.email;
        const code = this.code;
        if (!login || !code)
            return this.setState({isLoading: false, errors: ['Не указаны параметры подтверждения регистрации']});
        return API.fetch(this.props.appProps.setAuth,
            Config.authApiUrl,
            '1.0',
            'confirmEmail',
            {
                login: login,
                code: code,
            }
        )
        .then(responseData => {
            const {errors, warnings, notices} = responseData;
            this.setState({errors, warnings, notices, isLoading: false});
        })
        .catch(() => this.setState({isLoading: false, errors: [Config.commonErrorText]}));
    }

    get email() {
        return new URLSearchParams(this.props.location.search).get(Config.emailParamName);
    }

    get code() {
        return new URLSearchParams(this.props.location.search).get(Config.codeParamName);
    }

    render() {
        //const classes = this.props.classes;
        //const {appProps} = this.props;
        //const {auth} = appProps;
        const {isLoading} = this.state;
        const {errors, warnings, notices} = this.state;
        const isSuccess = !isLoading && (!errors || !errors.length);
        let content = null;
        if (isSuccess)
            content = (
                <React.Fragment>
                    <Typography>Ваша регистрация успешно подтверждена!</Typography>
                    <Typography paragraph />
                    <Button component={Link} to={Routes.lk} color="primary">
                        Личный кабинет
                    </Button>
                </React.Fragment>
            );
        else
            content = !isLoading ? null : <CircularProgress color="primary" />;
        return (
            <React.Fragment>
                <LoadingCursor isLoading={isLoading} />
                <TemplateFormPage
                    title='Подтверждение'
                    icon={isSuccess ? <ThumbUpAltIcon /> : <EmailIcon />}
                    isDivider={true}
                    content={content}
                    errors={errors} warnings={warnings} notices={notices}
                />
            </React.Fragment>
        );
    }
}

export default withRouter(withStyles(styles, {withTheme: true})(ConfirmEmailPage));
