import React from 'react';
import './css/tree.css';


class TreeNode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: null,
            isClosed: true,
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        if (this.props.item.isLeaf)
            return;
        if (this.state.isClosed && (this.state.items === null) && this.props.loadItems) {
            this.props.loadItems(this.props.item.id)
                .then(items => (items !== null) && this.setState({items: items, isClosed: false}))
                .catch();
        } else if (this.state.items !== null)
            this.setState((prevState) => ({isClosed: !prevState.isClosed}));
    }
    
    render() {
        const {item, itemElement, loadItems, isLast} = this.props;
        const {items, isClosed} = this.state;
        if (!item || !itemElement)
            return null;
        let subTree = items ?
            <Tree items={items} itemElement={itemElement} loadItems={loadItems} /> :
            null;
        let expandClass = item.isLeaf ?
            'ExpandLeaf' :
            (isClosed ? ' ExpandClosed' : ' ExpandOpen');
        return (
            <li className={'Node ' + expandClass + (isLast ? ' IsLast' : '')}>
                <div className="Expand" onClick={this.handleClick} />
                <div className="Content">
                    {React.createElement(itemElement, {item: item})}
                </div>
                {subTree}
            </li>
        );
    }
}

class Tree extends React.Component {
    render() {
        const {items, itemElement, loadItems, id} = this.props;
        if (!items || !itemElement)
            return null;
        return (
            <ul className="Tree" id={id || null}>
                {items.map((item, index) =>
                    <TreeNode item={item} itemElement={itemElement} loadItems={loadItems} isLast={index === items.length-1} key={item.id} />
                )}
            </ul>
        );
    }
}

/*
item = {
    id: 1, // used as key for elements, used to load items for sub-tree
    isLeaf: true, // must be Boolean (not String "true"/"false")
    // any other content used in itemElement
}

loadItems(id) { // load sub-items for item with specified id, returns Promise
    return new Promise((resolve, reject) => {
            resolve(items);
        });
}
*/

export default Tree;
