import React from 'react';
import {Config, Routes} from './Config.js';
import {withStyles} from '@material-ui/core/styles';

import { Button, CircularProgress, FormControl, NativeSelect, Typography } from '@material-ui/core';
import Messages from './Messages.js';
import API from './API.js';
import { Link, withRouter } from 'react-router-dom';
import { comparatorIgnoreCase } from './Utils.js';
import { TendersList, SearchResultsMenu, TendersPagination } from './TenderComponents.js';
import LoadingCursor from './LoadingCursor.js';
import TemplatePage from './TemplatePage.js';
import SettingsIcon from '@material-ui/icons/Settings';

const styles = theme => ({
    longBlock: {
        minHeight: '600px',
    },
    settingsButton: {
        margin: theme.spacing(2, 0),
    },
    sortBySelect: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
});


class SearchPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
        this.tendersRef = React.createRef();
        this.handleRequestsOpenSwitch = this.handleRequestsOpenSwitch.bind(this);
        this.handleAddFavourite = this.handleAddFavourite.bind(this);
        this.handleRemoveFavourite = this.handleRemoveFavourite.bind(this);
        this.handleSortByChange = this.handleSortByChange.bind(this);
    }

    getInitialState() {
        return {
            errors: null,
            warnings: null,
            notices: null,
            isLoading: true,
            isRequestsOpen: this.selectedWordIdParam || this.selectedOkpd2CodeParam || this.selectedCustomerInnParam,
            templateName: (this.props.location.state && this.props.location.state.templateName) ? this.props.location.state.templateName : '',
            runId: null,
            date: null,
            dateToShow: null,
            page: 1, // For correct lots numbers only
            pageSize: 1,
            sortBy: 'RANK_DESC',
            total: {},
            words: [],
            okpd2: [],
            customers: [],
            tenders: [],
            favourites: [],
        };
    }

    componentDidUpdate(prevProps) {
        if (this.templateIdParam !== this.getParam(prevProps.location.search, SearchPage.templateIdParamName))
            this.updatePage(true);
        else if (
            (this.selectedWordIdParam !== this.getParam(prevProps.location.search, SearchPage.selectedWordIdParamName)) ||
            (this.selectedOkpd2CodeParam !== this.getOkpd2CodeParam(prevProps.location.search, SearchPage.selectedOkpd2CodeParamName)) ||
            (this.selectedCustomerInnParam !== this.getInnParam(prevProps.location.search, SearchPage.selectedCustomerInnParamName)) ||
            (this.pageParam !== this.getIntParam(prevProps.location.search, SearchPage.pageParamName)) ||
            (this.dateParam !== this.getParam(prevProps.location.search, SearchPage.dateParamName))
        )
            this.updatePage(false);
    }
    
    componentDidMount() {
        this.props.appProps.setHead(this.state.templateName, '');
        this.updatePage(true);
    }

    updatePage(isResetRunId, sortBy) {
        const runId = isResetRunId ? null : this.state.runId;
        sortBy = sortBy ? sortBy : this.state.sortBy;
        this.setState({isLoading: true, runId});
        this.loadTenders(this.templateIdParam, runId, this.dateParam, this.selectedWordIdParam, this.selectedOkpd2CodeParam, this.selectedCustomerInnParam, this.pageParam, sortBy);
    }

    prepareLoadedContent(content) {
        if (content) {
            if (Array.isArray(content.words) && content.words.length)
                content.words.sort(comparatorIgnoreCase.bind(this, 'word'));
            if (!content.templateName)
                content.templateName = '';
        }
        return content;
    }

    loadTenders(templateId, runId, date, selectedWordId, selectedOkpd2Code, selectedCustomerInn, page, sortBy) {
        if (!templateId)
            return this.setState({errors: ['Не указаны параметры поиска'], warnings: null, notices: null, isLoading: false});

        const isSameRun = runId || (this.state.date && (this.state.date === date));
        return API.fetch(this.props.appProps.setAuth,
            Config.searchApiUrl,
            '1.1',
            'searchRun',
            {
                'templateId': templateId,
                'runId': runId,
                'date': date,
                'wordId': selectedWordId,
                'okpd2Code': selectedOkpd2Code,
                'customerInn': selectedCustomerInn,
                'page': page,
                'sortBy': sortBy,
                'isLoadDetails': isSameRun ? 0 : 1,
            }
        )
        .then(responseData => {
            //const {auth} = this.props;
            const {errors, warnings, notices, content, auth} = responseData;
            this.prepareLoadedContent(content);
            if (isSameRun) // isLoadDetails = false
                Object.assign(content, {isRequestsOpen: this.state.isRequestsOpen, templateName: this.state.templateName, total: this.state.total, words: this.state.words, okpd2: this.state.okpd2, customers: this.state.customers});
            this.props.appProps.setHead(content.templateName + (content.dateToShow ? (' за ' + content.dateToShow) : ''), '');

            let errors_new = errors;
            const notActiveMessage = 'Рассылка новых тендеров отключена в связи с окончанием оплаченного периода. Для возобновления рассылки необходимо сформировать новый счет и произвести оплату.';
            if (!auth || !auth.isActive)
                errors_new = errors ? [notActiveMessage, ...errors] : [notActiveMessage];

            this.setState(Object.assign(this.getInitialState(), content, {errors: errors_new, warnings, notices, isLoading: false}, {sortBy: this.state.sortBy}));
            if (isSameRun && this.tendersRef && this.tendersRef.current && this.state.isRequestsOpen)
                this.tendersRef.current.scrollIntoView();
            else
                window.scrollTo(0, 0);
        })
        .catch(() => {
            this.setState(Object.assign(this.getInitialState(), {errors: [Config.commonErrorText], isLoading: false}, {sortBy: this.state.sortBy}))
            window.scrollTo(0, 0);
        });
    }

    handleAddFavourite(favouriteId, registrationNumber) {
        //alert('Add ' + favouriteId + '; ' + registrationNumber);
        return API.fetch(this.props.appProps.setAuth,
            Config.favouriteApiUrl,
            '1.0',
            'addToFavourite',
            {
                'id': favouriteId,
                'registrationNumber': registrationNumber,
            }
        )
        .then(responseData => {
            const {errors/*, warnings, notices, content*/} = responseData;
            if (!errors || !errors.length)
                this.setState(prevState => ({
                    favourites: Object.assign({}, prevState.favourites,
                        {[registrationNumber]: Array.isArray(prevState.favourites[registrationNumber]) ? Array.from(new Set(prevState.favourites[registrationNumber].concat(favouriteId))) : [favouriteId]}
                    )
                }));
        })
        .catch(() => {});
    }

    handleRemoveFavourite(favouriteId, registrationNumber) {
        //alert('Remove ' + favouriteId + '; ' + registrationNumber);
        return API.fetch(this.props.appProps.setAuth,
            Config.favouriteApiUrl,
            '1.0',
            'removeFromFavourite',
            {
                'id': favouriteId,
                'registrationNumber': registrationNumber,
            }
        )
        .then(responseData => {
            const {errors/*, warnings, notices, content*/} = responseData;
            if (!errors || !errors.length)
                this.setState(prevState => ({
                    favourites: Object.assign({}, prevState.favourites,
                        Array.isArray(prevState.favourites[registrationNumber]) ? {[registrationNumber]: prevState.favourites[registrationNumber].filter(id => id !== favouriteId)} : null
                    )
                }));
        })
        .catch(() => {});
    }

    handleRequestsOpenSwitch() {
        this.setState(prevState => ({isRequestsOpen: !prevState.isRequestsOpen}));
    }

    handleSortByChange(event) {
        const sortBy = event.target.value;
        this.setState({sortBy});
        if (this.state.page > 1) {
            const {selectedWordIdParam, selectedOkpd2CodeParam, selectedCustomerInnParam, templateIdParam, dateParam} = this;
            const baseUrl = this.getBaseUrlNoPage(templateIdParam, selectedWordIdParam, selectedOkpd2CodeParam, selectedCustomerInnParam, dateParam);
            this.props.history.push(baseUrl);
        } else
            this.updatePage(false, sortBy);
    }

    getTendersCount(total, words, okpd2, customers, selectedWordIdParam, selectedOkpd2CodeParam, selectedCustomerInnParam) {
        //const {total, words, okpd2, customers} = this.state;
        //const {selectedWordIdParam, selectedOkpd2CodeParam, selectedCustomerInnParam} = this;
        if (selectedWordIdParam && words)
            for (let word of words)
                if (word.id === selectedWordIdParam)
                    return word.lotCount;
        if (selectedOkpd2CodeParam && okpd2)
            for (let o of okpd2)
                if (o.code === selectedOkpd2CodeParam)
                    return o.lotCount;
        if (selectedCustomerInnParam && customers)
            for (let c of customers)
                if (c.inn === selectedCustomerInnParam)
                    return c.lotCount;
        return total ? total.lotCount : 0;
    }

    getBaseUrlNoPage(templateIdParam, selectedWordIdParam, selectedOkpd2CodeParam, selectedCustomerInnParam, dateParam) {
        let params = {[SearchPage.templateIdParamName]: templateIdParam};
        if (selectedWordIdParam) Object.assign(params, {[SearchPage.selectedWordIdParamName]: selectedWordIdParam});
        if (selectedOkpd2CodeParam) Object.assign(params, {[SearchPage.selectedOkpd2CodeParamName]: selectedOkpd2CodeParam});
        if (selectedCustomerInnParam) Object.assign(params, {[SearchPage.selectedCustomerInnParamName]: selectedCustomerInnParam});
        if (dateParam) Object.assign(params, {[SearchPage.dateParamName]: dateParam});
        //if (pageParam) Object.assign(params, {[SearchPage.pageParamName]: pageParam});
        return Routes.addParamsToUrl(Routes.search, params);
    }

    // Params from url
    static get templateIdParamName()			{return 't'}
    static get selectedWordIdParamName()		{return 'w'}
    static get selectedOkpd2CodeParamName()		{return 'o'}
    static get selectedCustomerInnParamName()	{return 'c'}
    static get pageParamName()					{return 'p'}
    static get dateParamName()					{return 'd'}
    get templateIdParam() {
        return this.getParam(this.props.location.search, SearchPage.templateIdParamName);
    }
    get selectedWordIdParam() {
        return this.getParam(this.props.location.search, SearchPage.selectedWordIdParamName);
    }
    get selectedOkpd2CodeParam() {
        return this.getOkpd2CodeParam(this.props.location.search, SearchPage.selectedOkpd2CodeParamName);
    }
    get selectedCustomerInnParam() {
        return this.getInnParam(this.props.location.search, SearchPage.selectedCustomerInnParamName);
    }
    get pageParam() {
        return this.getIntParam(this.props.location.search, SearchPage.pageParamName);
    }
    get dateParam() {
        return this.getParam(this.props.location.search, SearchPage.dateParamName);
    }
    getIntParam(search, paramName) {
        const id = new URLSearchParams(search).get(paramName);
        return /^[\d]+$/.test(id) ? parseInt(id, 10) : null;
    }
    getOkpd2CodeParam(search, paramName) {
        return this.getParam(search, paramName, /^[\d]{2}[\d.]*$/);
    }
    getInnParam(search, paramName) {
        return this.getParam(search, paramName, /^([\d]{10}|[\d]{12})$/);
    }
    getParam(search, paramName, regExp=null) {
        const value = new URLSearchParams(search).get(paramName);
        return regExp ? (regExp.test(value) ? value : null) : value;
    }

    render() {
        const classes = this.props.classes;
        const {auth} = this.props.appProps;
        const favouritesMenu = !this.props.appProps.menu ? null : this.props.appProps.menu.favourites;
        const {errors, warnings, notices} = this.state;
        const {templateName, isLoading, isRequestsOpen, runId, pageSize, total, words, okpd2, customers, tenders, favourites, sortBy, date, dateToShow} = this.state;
        const {selectedWordIdParam, selectedOkpd2CodeParam, selectedCustomerInnParam, pageParam, templateIdParam, dateParam} = this;
        const tendersCount = this.getTendersCount(total, words, okpd2, customers, selectedWordIdParam, selectedOkpd2CodeParam, selectedCustomerInnParam);
        const isSameRun = runId || (date && (date === dateParam));
        //const pageTitle = dateToShow ? (templateName + ' за ' + dateToShow) : (
        //    (isLoading && dateParam) ? 'Результаты поиска' : 'Актуальные тендеры по шаблону ' + templateName
        //);
        const pageTitle =
            (dateParam && dateToShow) ? (templateName + ' за ' + dateToShow) : (
            (isLoading && dateParam) ? 'Результаты поиска' : (
            (isLoading && !isSameRun) ? 'Поиск тендеров...' :
            'Актуальные тендеры по шаблону ' + templateName
        ));
        const baseUrl = this.getBaseUrlNoPage(templateIdParam, selectedWordIdParam, selectedOkpd2CodeParam, selectedCustomerInnParam, dateParam);
        const firstLotIndex = (this.state.page - 1) * pageSize + 1;
//console.log('isSameRun: ' + isSameRun + '; dateToShow: ' + dateToShow + '; isLoading: ' + isLoading + '; dateParam: ' + dateParam);
        const pageData = (isLoading && !isSameRun) ? (
            <div align='center'><CircularProgress color="primary" /></div>
        ) : (
            <React.Fragment>
                <Messages errors={errors} warnings={warnings} notices={notices} />
                <Button variant='contained' color='primary' component={Link} to={{pathname: Routes.template, search: Routes.getUrlParamString(TemplatePage.templateIdParamName, templateIdParam), state: {templateName: templateName}}} startIcon={<SettingsIcon />} className={classes.settingsButton}>
                    Настройки поиска
                </Button>
                <SearchResultsMenu disabled={isLoading} templateId={this.templateIdParam} date={date} total={total} words={words} okpd2={okpd2} customers={customers} selectedWordId={selectedWordIdParam} selectedOkpd2Code={selectedOkpd2CodeParam} selectedCustomerInn={selectedCustomerInnParam} isRequestsOpen={isRequestsOpen} onRequestsOpenSwitch={this.handleRequestsOpenSwitch} />
                {!((!errors || !errors.length) && (!tenders || !tenders.length)) ? null : (
                    <Messages key='NotFoundMessage' errors={null} warnings={null} notices={['Тендеры не найдены']} />
                )}
                {!(tenders && tenders.length) ? null : (
                    <FormControl className={classes.sortBySelect}>
                        {/*<InputLabel htmlFor="sort_by_select">Сортировать по</InputLabel>*/}
                        <NativeSelect
                            disabled={isLoading}
                            value={sortBy}
                            onChange={this.handleSortByChange}
                            inputProps={{
                                name: 'sort_by',
                                id: 'sort_by_select',
                            }}
                        >
                            <option value='RANK_DESC'>Соответствие запросу ↓</option>
                            <option value='SUBMISSION_DATE_DESC'>Окончание подачи заявок ↓</option>
                            <option value='SUBMISSION_DATE_ASC'>Окончание подачи заявок ↑</option>
                        </NativeSelect>
                    </FormControl>
                )}
                <TendersList tenders={tenders} favourites={favourites} favouritesMenu={favouritesMenu} firstLotIndex={firstLotIndex} isNeedPayment={!(auth.isActive || date)} ankhorRef={this.tendersRef} onAddFavourite={this.handleAddFavourite} onRemoveFavourite={this.handleRemoveFavourite} />
                <TendersPagination isLoading={isLoading} baseUrl={baseUrl} tendersCount={tendersCount} pageSize={pageSize} pageParam={pageParam} pageParamName={SearchPage.pageParamName} />
            </React.Fragment>
        );
        return (
            <div className={classes.longBlock}>
                <LoadingCursor isLoading={isLoading} />
                <Typography align='center' variant='h5' gutterBottom >{pageTitle}</Typography>
                {pageData}
            </div>
        );
    }
}

export default withRouter(withStyles(styles, {withTheme: true})(SearchPage));
