import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import {Container} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
    },
});

  
class NotFoundPage extends React.Component {
    componentDidMount() {
        this.props.appProps.setHead('Страница не найдена', '');
    }

    render() {
        const classes = this.props.classes;
        return (
            <Container className={classes.paper} maxWidth='sm'>
                <Alert severity="error">
                    <AlertTitle>Ошибка 404</AlertTitle>
                    Страница не найдена!
                </Alert>
            </Container>
        );
    }
}

export default withStyles(styles, {withTheme: true})(NotFoundPage);
