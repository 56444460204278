import React from 'react';

class LoadingCursor extends React.PureComponent {
    componentDidMount() {
        const {isLoading} = this.props;
        if (isLoading)
            document.body.style.cursor = 'wait';
    }

    componentDidUpdate(prevProps) {
        const {isLoading} = this.props;
        if (isLoading !== prevProps.isLoading)
            document.body.style.cursor = isLoading ? 'wait' : 'auto';
    }

    componentWillUnmount() {
        const {isLoading} = this.props;
        if (isLoading)
            document.body.style.cursor = 'auto';
    }

    render() {
        return null;
    }
}

export default LoadingCursor;
