import React from 'react';
import { Config } from '../Config.js';
import Tree from './Tree.js';
import './css/treeokpd2.css';
import API from '../API.js';


/*
class Okpd2Element extends React.Component {
    render() {
        const item = this.props.item;
        if (!item)
            return null;
        return (
            <div>
                <span className="okpd2-tree-code">{item.code}</span>
                <span className="okpd2-tree-name"> - {item.name}</span>
            </div>
        );
    }
}

class Okpd2RootElement extends React.Component {
    render() {
        const item = this.props.item;
        if (!item)
            return null;
        return (
            <div className="Chapter">
                <span className="okpd2-tree-code">{item.code}</span>
                <span className="okpd2-tree-name"> - {item.name}</span>
            </div>
        );
    }
}
*/
function Okpd2Element(props) {
    const item = props.item;
    return !item ? null : (
        <React.Fragment>
            <span className="okpd2-tree-code">{item.code}</span>
            <span className="okpd2-tree-name"> - {item.name}</span>
        </React.Fragment>
    );
}

function Okpd2RootElement(props) {
    const item = props.item;
    return !item ? null : (
        <div className="Chapter">
            <span className="okpd2-tree-code">{item.code}</span>
            <span className="okpd2-tree-name"> - {item.name}</span>
        </div>
    );
}

class TreeOkpd2 extends React.Component {
    constructor(props) {
        super(props);
        this.loadItems = this.loadItems.bind(this);
    }

    loadItems(id) {
        return API.fetch(null,
            Config.okpd2ApiUrl,
            '1.0',
            'getOkpd2SubItems',
            {
                code: id,
            }
		)
		.then(responseData => {
			const {errors, content} = responseData;
			if ((errors && errors.length) || !content)
				throw new Error(errors.join('. '));
			else
				return content.map(item => Object.assign(item, {id: item.code}));
		}); // Catch is in the tree
    }
/*
    loadItems(id) {
        return Promise.resolve(okpd2Items);
    }
*/
    render() {
        const id = this.props.id;
        //return <Tree items={okpd2Items} id={id} itemElement={Okpd2Element} loadItems={this.loadItems} />;
        return (
            <ul className="Tree" id={id || null}>
                {okpd2RootTree.map(rootItem =>
                    <li key={rootItem.id}>
                        <Okpd2RootElement item={rootItem} />
                        <Tree items={rootItem.items} itemElement={Okpd2Element} loadItems={this.loadItems} />
                    </li>
                )}
            </ul>
        );
    }
}


export default TreeOkpd2;

/*-------------------------------------------------------------------*/

const okpd2RootTree = [
{id: "A", code: "A", name: "ПРОДУКЦИЯ СЕЛЬСКОГО, ЛЕСНОГО И РЫБНОГО ХОЗЯЙСТВА", items: [
	{id: "01", code: "01", name: "Продукция и услуги сельского хозяйства и охоты", isLeaf: false},
	{id: "02", code: "02", name: "Продукция лесоводства, лесозаготовок и связанные с этим услуги", isLeaf: false},
	{id: "03", code: "03", name: "Рыба и прочая продукция рыболовства и рыбоводства; услуги, связанные с рыболовством и рыбоводством", isLeaf: false},
]},
{id: "B", code: "B", name: "ПРОДУКЦИЯ ГОРНОДОБЫВАЮЩИХ ПРОИЗВОДСТВ", items: [
	{id: "05", code: "05", name: "Уголь", isLeaf: false},
	{id: "06", code: "06", name: "Нефть сырая и газ природный", isLeaf: false},
	{id: "07", code: "07", name: "Руды металлические", isLeaf: false},
	{id: "08", code: "08", name: "Продукция горнодобывающих производств прочая", isLeaf: false},
	{id: "09", code: "09", name: "Услуги в области добычи полезных ископаемых", isLeaf: false},
]},
{id: "C", code: "C", name: "ПРОДУКЦИЯ ОБРАБАТЫВАЮЩИХ ПРОИЗВОДСТВ", items: [
	{id: "10", code: "10", name: "Продукты пищевые", isLeaf: false},
	{id: "11", code: "11", name: "Напитки", isLeaf: false},
	{id: "12", code: "12", name: "Изделия табачные", isLeaf: false},
	{id: "13", code: "13", name: "Текстиль и изделия текстильные", isLeaf: false},
	{id: "14", code: "14", name: "Одежда", isLeaf: false},
	{id: "15", code: "15", name: "Кожа и изделия из кожи", isLeaf: false},
	{id: "16", code: "16", name: "Древесина и изделия из дерева и пробки, кроме мебели; изделия из соломки и материалов для плетения", isLeaf: false},
	{id: "17", code: "17", name: "Бумага и изделия из бумаги", isLeaf: false},
	{id: "18", code: "18", name: "Услуги печатные и услуги по копированию звуко- и видеозаписей, а также программных средств", isLeaf: false},
	{id: "19", code: "19", name: "Кокс и нефтепродукты", isLeaf: false},
	{id: "20", code: "20", name: "Вещества химические и продукты химические", isLeaf: false},
	{id: "21", code: "21", name: "Средства лекарственные и материалы, применяемые в медицинских целях", isLeaf: false},
	{id: "22", code: "22", name: "Изделия резиновые и пластмассовые", isLeaf: false},
	{id: "23", code: "23", name: "Продукты минеральные неметаллические прочие", isLeaf: false},
	{id: "24", code: "24", name: "Металлы основные", isLeaf: false},
	{id: "25", code: "25", name: "Изделия металлические готовые, кроме машин и оборудования", isLeaf: false},
	{id: "26", code: "26", name: "Оборудование компьютерное, электронное и оптическое", isLeaf: false},
	{id: "27", code: "27", name: "Оборудование электрическое", isLeaf: false},
	{id: "28", code: "28", name: "Машины и оборудование, не включенные в другие группировки", isLeaf: false},
	{id: "29", code: "29", name: "Средства автотранспортные, прицепы и полуприцепы", isLeaf: false},
	{id: "30", code: "30", name: "Средства транспортные и оборудование, прочие", isLeaf: false},
	{id: "31", code: "31", name: "Мебель", isLeaf: false},
	{id: "32", code: "32", name: "Изделия готовые прочие", isLeaf: false},
	{id: "33", code: "33", name: "Услуги по ремонту и монтажу машин и оборудования", isLeaf: false},
]},
{id: "D", code: "D", name: "ЭЛЕКТРОЭНЕРГИЯ, ГАЗ, ПАР И КОНДИЦИОНИРОВАНИЕ ВОЗДУХА", items: [
	{id: "35", code: "35", name: "Электроэнергия, газ, пар и кондиционирование воздуха", isLeaf: false},
]},
{id: "E", code: "E", name: "ВОДОСНАБЖЕНИЕ; ВОДООТВЕДЕНИЕ, УСЛУГИ ПО УДАЛЕНИЮ И РЕКУЛЬТИВАЦИИ ОТХОДОВ", items: [
	{id: "36", code: "36", name: "Вода природная; услуги по очистке воды и водоснабжению", isLeaf: false},
	{id: "37", code: "37", name: "Услуги по водоотведению; шлам сточных вод", isLeaf: false},
	{id: "38", code: "38", name: "Услуги по сбору, обработке и удалению отходов; услуги по утилизации отходов", isLeaf: false},
	{id: "39", code: "39", name: "Услуги по рекультивации и прочие услуги по утилизации отходов", isLeaf: false},
]},
{id: "F", code: "F", name: "СООРУЖЕНИЯ И СТРОИТЕЛЬНЫЕ РАБОТЫ", items: [
	{id: "41", code: "41", name: "Здания и работы по возведению зданий", isLeaf: false},
	{id: "42", code: "42", name: "Сооружения и строительные работы в области гражданского строительства", isLeaf: false},
	{id: "43", code: "43", name: "Работы строительные специализированные", isLeaf: false},
]},
{id: "G", code: "G", name: "УСЛУГИ ПО ОПТОВОЙ И РОЗНИЧНОЙ ТОРГОВЛЕ; УСЛУГИ ПО РЕМОНТУ АВТОТРАНСПОРТНЫХ СРЕДСТВ И МОТОЦИКЛОВ", items: [
	{id: "45", code: "45", name: "Услуги по оптовой и розничной торговле и услуги по ремонту автотранспортных средств и мотоциклов", isLeaf: false},
	{id: "46", code: "46", name: "Услуги по оптовой торговле, кроме оптовой торговли автотранспортными средствами и мотоциклами", isLeaf: false},
	{id: "47", code: "47", name: "Услуги по розничной торговле, кроме розничной торговли автотранспортными средствами и мотоциклами", isLeaf: false},
]},
{id: "H", code: "H", name: "УСЛУГИ ТРАНСПОРТА И СКЛАДСКОГО ХОЗЯЙСТВА", items: [
	{id: "49", code: "49", name: "Услуги сухопутного и трубопроводного транспорта", isLeaf: false},
	{id: "50", code: "50", name: "Услуги водного транспорта", isLeaf: false},
	{id: "51", code: "51", name: "Услуги воздушного и космического транспорта", isLeaf: false},
	{id: "52", code: "52", name: "Услуги по складированию и вспомогательные транспортные услуги", isLeaf: false},
	{id: "53", code: "53", name: "Услуги почтовой связи и услуги курьерские", isLeaf: false},
]},
{id: "I", code: "I", name: "УСЛУГИ ГОСТИНИЧНОГО ХОЗЯЙСТВА И ОБЩЕСТВЕННОГО ПИТАНИЯ", items: [
	{id: "55", code: "55", name: "Услуги по предоставлению мест для временного проживания", isLeaf: false},
	{id: "56", code: "56", name: "Услуги общественного питания", isLeaf: false},
]},
{id: "J", code: "J", name: "УСЛУГИ В ОБЛАСТИ ИНФОРМАЦИИ И СВЯЗИ", items: [
	{id: "58", code: "58", name: "Услуги издательские", isLeaf: false},
	{id: "59", code: "59", name: "Услуги по производству кинофильмов, видеофильмов и телевизионных программ, звукозаписей и изданию музыкальных записей", isLeaf: false},
	{id: "60", code: "60", name: "Услуги в области телеи радиовещания", isLeaf: false},
	{id: "61", code: "61", name: "Услуги телекоммуникационные", isLeaf: false},
	{id: "62", code: "62", name: "Продукты программные и услуги по разработке программного обеспечения; консультационные и аналогичные услуги в области информационных технологий", isLeaf: false},
	{id: "63", code: "63", name: "Услуги в области информационных технологий", isLeaf: false},
]},
{id: "K", code: "K", name: "УСЛУГИ ФИНАНСОВЫЕ И СТРАХОВЫЕ", items: [
	{id: "64", code: "64", name: "Услуги финансовые, кроме услуг по страхованию и пенсионному обеспечению", isLeaf: false},
	{id: "65", code: "65", name: "Услуги по страхованию, перестрахованию и негосударственному пенсионному обеспечению, кроме обязательного социального обеспечения", isLeaf: false},
	{id: "66", code: "66", name: "Услуги вспомогательные, связанные с услугами финансового посредничества и страхования", isLeaf: false},
]},
{id: "L", code: "L", name: "УСЛУГИ, СВЯЗАННЫЕ С НЕДВИЖИМЫМ ИМУЩЕСТВОМ", items: [
	{id: "68", code: "68", name: "Услуги по операциям с недвижимым имуществом", isLeaf: false},
]},
{id: "M", code: "M", name: "УСЛУГИ, СВЯЗАННЫЕ С НАУЧНОЙ, ИНЖЕНЕРНО-ТЕХНИЧЕСКОЙ И ПРОФЕССИОНАЛЬНОЙ ДЕЯТЕЛЬНОСТЬЮ", items: [
	{id: "69", code: "69", name: "Услуги юридические и бухгалтерские", isLeaf: false},
	{id: "70", code: "70", name: "Услуги головных офисов; услуги консультативные в области управления предприятием", isLeaf: false},
	{id: "71", code: "71", name: "Услуги в области архитектуры и инженерно-технического проектирования, технических испытаний, исследований и анализа", isLeaf: false},
	{id: "72", code: "72", name: "Услуги и работы, связанные с научными исследованиями и экспериментальными разработками", isLeaf: false},
	{id: "73", code: "73", name: "Услуги рекламные и услуги по исследованию конъюнктуры рынка", isLeaf: false},
	{id: "74", code: "74", name: "Услуги профессиональные, научные и технические, прочие", isLeaf: false},
	{id: "75", code: "75", name: "Услуги ветеринарные", isLeaf: false},
]},
{id: "N", code: "N", name: "УСЛУГИ АДМИНИСТРАТИВНЫЕ И ВСПОМОГАТЕЛЬНЫЕ", items: [
	{id: "77", code: "77", name: "Услуги по аренде и лизингу", isLeaf: false},
	{id: "78", code: "78", name: "Услуги по трудоустройству и подбору персонала", isLeaf: false},
	{id: "79", code: "79", name: "Услуги туристических агентств, туроператоров и прочие услуги по бронированию и сопутствующие им услуги", isLeaf: false},
	{id: "80", code: "80", name: "Услуги по обеспечению безопасности и проведению расследований", isLeaf: false},
	{id: "81", code: "81", name: "Услуги по обслуживанию зданий и территорий", isLeaf: false},
	{id: "82", code: "82", name: "Услуги в области административного, хозяйственного и прочего вспомогательного обслуживания", isLeaf: false},
]},
{id: "O", code: "O", name: "УСЛУГИ В СФЕРЕ ГОСУДАРСТВЕННОГО УПРАВЛЕНИЯ И ОБЕСПЕЧЕНИЯ ВОЕННОЙ БЕЗОПАСНОСТИ; УСЛУГИ ПО ОБЯЗАТЕЛЬНОМУ СОЦИАЛЬНОМУ ОБЕСПЕЧЕНИЮ", items: [
	{id: "84", code: "84", name: "Услуги в области государственного управления и обеспечения военной безопасности, услуги в области обязательного социального обеспечения", isLeaf: false},
]},
{id: "P", code: "P", name: "УСЛУГИ В ОБЛАСТИ ОБРАЗОВАНИЯ", items: [
	{id: "85", code: "85", name: "Услуги в области образования", isLeaf: false},
]},
{id: "Q", code: "Q", name: "УСЛУГИ В ОБЛАСТИ ЗДРАВООХРАНЕНИЯ И СОЦИАЛЬНЫЕ УСЛУГИ", items: [
	{id: "86", code: "86", name: "Услуги в области здравоохранения", isLeaf: false},
	{id: "87", code: "87", name: "Услуги по предоставлению ухода с обеспечением проживания", isLeaf: false},
	{id: "88", code: "88", name: "Услуги социальные без обеспечения проживания", isLeaf: false},
]},
{id: "R", code: "R", name: "УСЛУГИ В ОБЛАСТИ ИСКУССТВА, РАЗВЛЕЧЕНИЙ, ОТДЫХА И СПОРТА", items: [
	{id: "90", code: "90", name: "Услуги в области творчества, искусства и развлечений", isLeaf: false},
	{id: "91", code: "91", name: "Услуги библиотек, архивов, музеев и прочие услуги в области культуры", isLeaf: false},
	{id: "92", code: "92", name: "Услуги по организации и проведению азартных игр и заключению пари, лотерей", isLeaf: false},
	{id: "93", code: "93", name: "Услуги, связанные со спортом, и услуги по организации развлечений и отдыха", isLeaf: false},
]},
{id: "S", code: "S", name: "УСЛУГИ ОБЩЕСТВЕННЫХ ОРГАНИЗАЦИЙ; ПРОЧИЕ УСЛУГИ ДЛЯ НАСЕЛЕНИЯ", items: [
	{id: "94", code: "94", name: "Услуги общественных организаций", isLeaf: false},
	{id: "95", code: "95", name: "Услуги по ремонту компьютеров, предметов личного потребления и бытовых товаров", isLeaf: false},
	{id: "96", code: "96", name: "Услуги персональные прочие", isLeaf: false},
]},
{id: "T", code: "T", name: "ТОВАРЫ И УСЛУГИ РАЗЛИЧНЫЕ, ПРОИЗВОДИМЫЕ ДОМАШНИМИ ХОЗЯЙСТВАМИ ДЛЯ СОБСТВЕННОГО ПОТРЕБЛЕНИЯ, ВКЛЮЧАЯ УСЛУГИ РАБОТОДАТЕЛЯ ДЛЯ ДОМАШНЕГО ПЕРСОНАЛА", items: [
	{id: "97", code: "97", name: "Услуги домашних хозяйств с наемными работниками", isLeaf: false},
	{id: "98", code: "98", name: "Продукция и различные услуги частных домашних хозяйств для собственных нужд", isLeaf: false},
]},
{id: "U", code: "U", name: "УСЛУГИ, ПРЕДОСТАВЛЯЕМЫЕ ЭКСТЕРРИТОРИАЛЬНЫМИ ОРГАНИЗАЦИЯМИ И ОРГАНАМИ", items: [
	{id: "99", code: "99", name: "Услуги, предоставляемые экстерриториальными организациями и органами", isLeaf: false},
]}
];
