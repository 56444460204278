import React from 'react';
import {Config} from './Config.js';
import {withStyles} from '@material-ui/core/styles';

import { CircularProgress, Link, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import Messages from './Messages.js';
import API from './API.js';
import LoadingCursor from './LoadingCursor.js';

const styles = theme => ({
    longBlock: {
        minHeight: '600px',
    },
    table: {
    },
});

  
class ActsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            warnings: [],
            notices: [],
            acts: null,
            //isLoading: true, // Фактически идет загрузка, когда acts=null и при этом нет ошибок. Если есть акты или ошибки, то не грузимся
            isSortAsc: false,
            orderBy: 'docDateYMD',
            page: 0,
        };
        this.handleRequestSort = this.handleRequestSort.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
    }

    componentDidMount() {
        this.props.appProps.setHead('Акты оказанных услуг', '');
        this.loadActs();
    }
    
    loadActs() {
        return API.fetch(this.props.appProps.setAuth,
            Config.billingApiUrl,
            '1.0',
            'getActs',
            {}
        )
        .then(responseData => {
            const {isSortAsc, orderBy} = this.state;
            let {errors, warnings, notices, content} = responseData;
            content = Array.isArray(content) ? content : [];
            content = content.sort(getComparator(isSortAsc, orderBy));
            if (!content.length && (!errors || !errors.length)) {
                notices = Array.isArray(notices) ? notices : [];
                notices.push('Акты услуг отсутствуют');
            }
            this.setState({errors, warnings, notices, acts: content instanceof Array ? content : []});
        })
        .catch(() => this.setState({errors: [Config.commonErrorText]}));
    }

    handleChangePage(event, newPage) {
        this.setState({page: newPage});
    }
    
    handleRequestSort() { // (event, property)
        this.setState(prevState => ({
            isSortAsc: !prevState.isSortAsc,
            acts: prevState.acts.slice().sort(getComparator(!prevState.isSortAsc, prevState.orderBy)),
            page: 0,
        }));
    }

    render() {
        const classes = this.props.classes;
        const {acts, isSortAsc, orderBy, page} = this.state;
        const {errors, warnings, notices} = this.state;
        const isLoading = !acts && (!errors || !errors.length);
        const pageData = acts ? (
            <EnhancedTable rows={acts} dense={true} isSortAsc={isSortAsc} orderBy={orderBy} page={page} onRequestSort={this.handleRequestSort} onChangePage={this.handleChangePage} classes={classes} />
        ) : (
            <div className={classes.longBlock}>
                {(!errors || !errors.length) ? <CircularProgress color="primary" /> : null}
            </div>
        );
        return (
            <React.Fragment>
                <LoadingCursor isLoading={isLoading} />
                <Typography variant='h4' gutterBottom >Акты услуг</Typography>
                <Messages errors={errors} warnings={warnings} notices={notices} />
                {pageData}
            </React.Fragment>
        );
    }
}

export default withStyles(styles, {withTheme: true})(ActsPage);

const headCells = [
    { id: 'docNo',			padding: 'default',		align: 'center',	dataAlign: 'right',		label: '№' },
    { id: 'docDate',		padding: 'default',		align: 'center',	dataAlign: 'right',		label: 'Дата' },
    { id: 'total',			padding: 'default',		align: 'right',		dataAlign: 'right',		label: 'Сумма, руб.' },
    { id: 'servicePeriod',	padding: 'default',		align: 'center',	dataAlign: 'center',	label: 'Период' },
    { id: 'organization',	padding: 'default',		align: 'center',	dataAlign: 'center',	label: 'Компания' },
    { id: 'url',			padding: 'checkbox',	align: 'center',	dataAlign: 'center',	label: 'Скачать' },
];

function comparator(a, b, orderBy) {
    if (a[orderBy] < b[orderBy]) return -1;
    if (a[orderBy] > b[orderBy]) return 1;
    //if (orderBy !== 'id') return comparator(a, b, 'id')
    if (orderBy !== 'docNo') return comparator(a, b, 'docNo')
    return 0;
}

function getComparator(isSortAsc, orderBy) {
    return isSortAsc
        ? (a, b) => comparator(a, b, orderBy)
        : (a, b) => -comparator(a, b, orderBy);
}

class EnhancedTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 25,
        };
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.emptyRows = this.emptyRows.bind(this);
    }

    handleChangeRowsPerPage(event) {
        const {onChangePage} = this.props;
        this.setState({
            pageSize: parseInt(event.target.value, 10)
        });
        onChangePage(null, 0);
    }

    emptyRows() {
        const {pageSize} = this.state;
        const {rows, page} = this.props;
        return pageSize - Math.min(pageSize, rows.length - page * pageSize);
    }

    render() {
        const classes = this.props.classes;
        const {pageSize} = this.state;
        //const {orderBy} = this.props;
        const {rows, dense, isSortAsc, page, onRequestSort, onChangePage} = this.props;
        return (
            <div>
                <TableContainer>
                    <Table
                        className={classes.table}
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            classes={classes}
                            isSortAsc={isSortAsc}
                            onRequestSort={onRequestSort}
                        />
                        <TableBody>
                            {rows
                            .slice(page * pageSize, page * pageSize + pageSize)
                            .map(row => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id}
                                    >
                                        <TableCell align={headCells[0].align} padding={headCells[0].padding}>{row.docNo}</TableCell>
                                        <TableCell align={headCells[1].align} padding={headCells[1].padding}>{row.docDate}</TableCell>
                                        <TableCell align={headCells[2].align} padding={headCells[2].padding}>{row.total}</TableCell>
                                        <TableCell align={headCells[3].align} padding={headCells[3].padding}>{row.serviceFrom + ' - ' + row.serviceTo}</TableCell>
                                        <TableCell align={headCells[4].align} padding={headCells[4].padding}>{row.invoice.contract.organization.shortName}</TableCell>
                                        <TableCell align={headCells[5].align} padding={headCells[5].padding}>
                                            <Link href={row.url} download=''>
                                                <DescriptionIcon color='primary' />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {/*this.emptyRows() > 0 && (
                                <TableRow style={{ height: (dense ? 42 : 62) * this.emptyRows() }}>
                                <TableCell colSpan={6} />
                                </TableRow>
                            )*/}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={pageSize}
                    page={page}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    labelRowsPerPage='Записей на странице'
                    backIconButtonText='Предыдущая'
                    nextIconButtonText='Следующая'
                />
            </div>
        );
    }
}

class EnhancedTableHead extends React.Component {
    constructor(props) {
        super(props);
        this.createSortHandler = this.createSortHandler.bind(this);
    }

    createSortHandler(property) {
        const {onRequestSort} = this.props;
        return (event) => {onRequestSort(event, property)};
    }

    render() {
        const {isSortAsc} = this.props;

        return (
            <TableHead>
                <TableRow>
                    {headCells.map(headCell => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={headCell.padding}
                            sortDirection={headCell.id === 'docDate' ? (isSortAsc ? 'asc' : 'desc') : false}
                        >
                            {headCell.id !== 'docDate' ? headCell.label :
                                <TableSortLabel
                                    active={headCell.id === 'docDate'}
                                    direction={isSortAsc ? 'asc' : 'desc'}
                                    hideSortIcon={headCell.id !== 'docDate'}
                                    onClick={headCell.id === 'docDate' ? this.createSortHandler('docDateYMD') : null}
                                >
                                    {headCell.label}
                                </TableSortLabel>
                            }
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
}
