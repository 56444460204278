import React from 'react';
import {Config} from './Config.js';
import {withStyles} from '@material-ui/core/styles';

import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Grid, Link, Radio, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';
import Messages from './Messages.js';
import API from './API.js';
import AddOrgForm from './AddOrgForm.js';
import OrgDetails from './OrgDetails.js';
import { withRouter } from 'react-router-dom';
import LoadingCursor from './LoadingCursor.js';

const styles = theme => ({
    icon: {
        marginRight: theme.spacing(1),
    },
    longBlock: {
        minHeight: '600px',
    },
    documentLink: {
        marginLeft: theme.spacing(2),
        alignItems: 'center',
    },
    pricesContainer: {
        padding: theme.spacing(0, 2, 0, 2), //   ^   >   \/   <
    },
    priceBox: {
        padding: theme.spacing(2),
        backgroundColor: '#F0F0F0',
    },
    priceBoxPrefered: {
        padding: theme.spacing(2),
        backgroundColor: '#F6F6F6',
    },
    priceAction: {
        marginLeft: 'auto',
        backgroundColor: theme.palette.primary.light,
    },
    priceActionPrefered: {
        marginLeft: 'auto',
    },
    selectOrgButton: {
        marginTop: theme.spacing(1),
    },
    header: {
        marginBottom: theme.spacing(4),
    },
});

  
class NewInvoicePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            warnings: [],
            notices: [],
            tariffs: null,
            organizations: null,
            isLoadingTariffs: true,
            isLoadingOrgs: true,
            tariffId: null,
            orgId: null,
            invoice: null,
            isLoadingInvoice: false,
            selectedOrgId: null,
            isNewOrgExpanded: false,
        };
        this.newOrgRef = React.createRef();
        this.addOrganization = this.addOrganization.bind(this);
        this.addAndSetOrganization = this.addAndSetOrganization.bind(this);
        this.handleExpandNewOrg = this.handleExpandNewOrg.bind(this);
        this.focusOnNewOrg = this.focusOnNewOrg.bind(this);
        this.setTariff = this.setTariff.bind(this);
        this.handleSelectOrg = this.handleSelectOrg.bind(this);
        this.makeInvoice = this.makeInvoice.bind(this);
    }

    handleExpandNewOrg() {
        this.setState(prevState => ({isNewOrgExpanded: !prevState.isNewOrgExpanded}));
    }

    focusOnNewOrg() {
        this.newOrgRef.current.focus();
    }

    sortOrganizations(organizations) {
        return organizations.sort((o1, o2) => (parseInt(o1.id, 10) - parseInt(o2.id, 10)));
    }
    
    addOrganization(organization) {
        this.setState(prevState => ({organizations: this.sortOrganizations(prevState.organizations.concat(organization)), isNewOrgExpanded: false, selectedOrgId: organization.id}));
    }

    addAndSetOrganization(organization) {
        this.setState(prevState => ({organizations: this.sortOrganizations(prevState.organizations.concat(organization)), isNewOrgExpanded: false, selectedOrgId: organization.id, orgId: organization.id}));
    }

    setTariff(tariffId) {
        this.setState({tariffId});
    }
    
    setOrg(orgId) {
        this.setState({orgId});
    }
    
    handleSelectOrg(event) {
        this.setState({selectedOrgId: event.target.value});
        event.stopPropagation();
    }

    componentDidMount() {
        this.props.appProps.setHead('Выставить счёт', '');
        this.loadTariffs();
        this.loadOrganizations();
    }

    componentDidUpdate() {
        const {tariffId, orgId, invoice, isLoadingInvoice, errors} = this.state;
        if (tariffId && orgId && !invoice && !isLoadingInvoice && (!errors || !errors.length))
            this.makeInvoice();
    }

    loadOrganizations() {
        return API.fetch(this.props.appProps.setAuth,
            Config.billingApiUrl,
            '1.0',
            'getOrganizations',
            {}
        )
        .then(responseData => {
            let {errors, warnings, notices, content} = responseData;
            content = Array.isArray(content) ? content : [];
            content = this.sortOrganizations(content);
            const orgIdFromUrl = this.orgIdParam;
            let orgId = null;
            if (orgIdFromUrl)
                for (let org of content)
                    if (orgIdFromUrl === org.id) {
                        orgId = orgIdFromUrl;
                        break;
                    }
            this.setState(prevState => ({
                errors: prevState.errors.concat(errors ? errors : []),
                warnings: prevState.warnings.concat(warnings ? warnings : []),
                notices: prevState.notices.concat(notices ? notices : []),
                organizations: content,
                isLoadingOrgs: false,
                orgId: orgId,
                selectedOrgId: orgId ? orgId : (content.length ? content[content.length-1].id : null),
            }));
        })
        .catch(() => this.setState(prevState => ({isLoadingOrgs: false, errors: prevState.errors.concat(Config.commonErrorText)})));
    }

    loadTariffs() {
        return API.fetch(this.props.appProps.setAuth,
            Config.billingApiUrl,
            '1.0',
            'getActualTariffs',
            {}
        )
        .then(responseData => {
            let {errors, warnings, notices, content} = responseData;
            content = Array.isArray(content) ? content : [];
            this.setState(prevState => ({
                errors: prevState.errors.concat(errors ? errors : []),
                warnings: prevState.warnings.concat(warnings ? warnings : []),
                notices: prevState.notices.concat(notices ? notices : []),
                tariffs: content,
                isLoadingTariffs: false,
            }));
        })
        .catch(() => this.setState(prevState => ({isLoadingTariffs: false, errors: prevState.errors.concat(Config.commonErrorText)})));
    }

    makeInvoice() {
        this.setState({isLoadingInvoice: true});
        const {orgId, tariffId} = this.state;
        const {invoice, isLoadingInvoice} = this.state;
        console.log('make invoice ' + orgId + '; ' + tariffId + '; ' + invoice + '; ' + isLoadingInvoice);

        return API.fetch(this.props.appProps.setAuth,
            Config.billingApiUrl,
            '1.0',
            'makeInvoice',
            {
                orgId: orgId,
                tariffId: tariffId,
            }
        )
        .then(responseData => {
            let {errors, warnings, notices, content} = responseData;
            content = (content && content.id) ? content : null;
            this.setState({errors, warnings, notices, invoice: content, isLoadingInvoice: false});
            window.scrollTo(0, 0);
        })
        .catch(() => this.setState(prevState => ({isLoadingInvoice: false, errors: prevState.errors.concat(Config.commonErrorText)})));
    }

    static get orgIdParamName()	{return 'o'}

    get orgIdParam() {
        return new URLSearchParams(this.props.location.search).get(NewInvoicePage.orgIdParamName);
    }

    render() {
        const classes = this.props.classes;
        const {appProps} = this.props;
        const {isLoadingOrgs, isLoadingTariffs, isLoadingInvoice, tariffs, organizations, tariffId, orgId, invoice, selectedOrgId, isNewOrgExpanded} = this.state;
        const {errors, warnings, notices} = this.state;
        const isLoading = isLoadingOrgs || isLoadingTariffs || isLoadingInvoice;
        let pageData = null;
        if (isLoading)
            pageData = <CircularProgress color="primary" />;
        else if (errors && errors.length)
            pageData = null; // Show messages, no data or actions
        else if (!tariffId)
            pageData = (
                <div>
                    <Typography variant='h6' gutterBottom className={classes.header}>Выберите тариф:</Typography>
                    <Grid container spacing={5} alignItems="flex-start" className={classes.pricesContainer}>
                        {tariffs.map(tariff => (
                            <Grid item key={tariff.id} xs={12} sm={6} md={6} lg={4}>
                                <Card className={tariff.isPrefered ? classes.priceBoxPrefered : classes.priceBox}>
                                    <CardHeader
                                        title={tariff.name}
                                        subheader={null}
                                        titleTypographyProps={{ align: 'center' }}
                                        subheaderTypographyProps={{ align: 'center' }}
                                        className={classes.cardHeader}
                                    />
                                    <CardContent>
                                        <Typography color="textPrimary" paragraph>Услуга автоматического поиска тендеров с отправкой результатов на электронную почту.</Typography>
                                        <Typography color="textPrimary" paragraph>{'Период оплаты: ' + tariff.paidPeriodRu}</Typography>
                                        {tariff.monthOldPrice ? (
                                                <React.Fragment>
                                                    <Typography color="textPrimary">{'Стоимость: '}<del>{tariff.monthOldPrice + ' руб./месяц'}</del></Typography>
                                                    <Typography color="textPrimary" paragraph>{tariff.monthPrice + ' руб./месяц'}</Typography>
                                                </React.Fragment>
                                            ) : (
                                                <Typography color="textPrimary" paragraph>{'Стоимость: ' + tariff.monthPrice + ' руб./месяц'}</Typography>
                                            )
                                        }
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            variant='contained' color="primary"
                                            className={tariff.isPrefered ? classes.priceActionPrefered : classes.priceAction}
                                            onClick={() => {this.setTariff(tariff.id)}}
                                        >
                                            Далее
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            );
        else if (!orgId) {
            if (!organizations || !organizations.length)
                pageData = (
                    <div>
                        <Typography variant='h6' gutterBottom className={classes.header}>Укажите реквизиты организации:</Typography>
                        <AddOrgForm
                            appProps={appProps}
                            onAddOrg={this.addAndSetOrganization}
                            firstInputRef={this.newOrgRef}
                            submitText='Сформировать счёт'
                            backText='Назад'
                            onBack={() => {this.setTariff(null)}}
                        />
                    </div>
                );
            else
                pageData = (
                    <div>
                        <Typography variant='h6' gutterBottom className={classes.header}>Выберите организацию:</Typography>
                        {organizations.map(organization => (
                            <Accordion key={organization.id}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                    <Radio
                                        checked={selectedOrgId === organization.id}
                                        onChange={this.handleSelectOrg}
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        value={organization.id}
                                        name="organization"
                                        color='primary'
                                        className={classes.icon}
                                    />
                                    <AccountBalanceIcon color='primary' fontSize='large' className={classes.icon} />
                                    <Typography variant='h6'>{organization.shortName}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <OrgDetails organization={organization} addNewInvoiceLink={false} />
                                </AccordionDetails>
                            </Accordion>
                        ))}
                        <Accordion expanded={isNewOrgExpanded} onChange={this.handleExpandNewOrg} TransitionProps={{onEntered: this.focusOnNewOrg}} >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                <AddCircleOutlineIcon color='secondary' fontSize='large' className={classes.icon} />
                                <Typography variant='h6'>Добавить организацию</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <AddOrgForm appProps={appProps} onAddOrg={this.addOrganization} firstInputRef={this.newOrgRef} submitText='Сохранить' />
                            </AccordionDetails>
                        </Accordion>
                        <CardActions>
                            <Button
                                variant='contained' color="primary"
                                onClick={() => {this.setTariff(null)}}
                                className={classes.selectOrgButton}
                                size="large"
                            >
                                Назад
                            </Button>
                            <Button
                                variant='contained' color="primary"
                                onClick={() => {this.setOrg(selectedOrgId)}}
                                disabled={!selectedOrgId || isNewOrgExpanded}
                                className={classes.selectOrgButton}
                                size="large"
                            >
                                Сформировать счёт
                            </Button>
                        </CardActions>
                    </div>
                );
        }
        else if (invoice && invoice.id)
            pageData = (
                <div>
                    <Typography variant='h6' gutterBottom className={classes.header}>{'Сформирован счёт на оплату № ' + invoice.docNo + ' от ' + invoice.docDate}</Typography>
                    <Typography>Копия счёта отправлена Вам на электронную почту.</Typography>
                    <Typography>{'Счёт действителен в течение ' + invoice.validText + '.'}</Typography>
                    <Typography>{'Счёт действует в рамках договора № ' + invoice.contract.docNo + ' от ' + invoice.contract.docDate + '.'}</Typography>
                    <Typography paragraph />
                    <div display='block'>
                        <Link href={invoice.url} download='' className={classes.documentLink} >
                            <DescriptionIcon color='primary' className={classes.icon} />
                            <Typography display='inline'>Скачать счёт</Typography>
                        </Link>
                    </div>
                    <div display='block'>
                        <Link href={invoice.contract.url} download='' className={classes.documentLink} >
                            <DescriptionIcon color='primary' className={classes.icon} />
                            <Typography display='inline'>Скачать договор</Typography>
                        </Link>
                    </div>
                    <Typography paragraph />
                    <embed src={invoice.url} width='100%' height='900px' />
                </div>
            );
        return (
            <div className={classes.longBlock}>
                <LoadingCursor isLoading={isLoading} />
                <Typography variant='h4' gutterBottom >Новый счёт</Typography>
                <Messages errors={errors} warnings={warnings} notices={notices} />
                {pageData}
            </div>
        );
    }
}

export default withRouter(withStyles(styles, {withTheme: true})(NewInvoicePage));
