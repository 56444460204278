import React from 'react';
import {Config, Routes} from './Config.js';
import {withStyles} from '@material-ui/core/styles';
import { CircularProgress, FormControl, NativeSelect, Typography } from '@material-ui/core';
import Messages from './Messages.js';
import API from './API.js';
import { withRouter } from 'react-router-dom';
import { TendersList, TendersPagination } from './TenderComponents.js';
import LoadingCursor from './LoadingCursor.js';
import StarIcon from '@material-ui/icons/Star';

const styles = theme => ({
    longBlock: {
        minHeight: '600px',
    },
    titleBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    sortBySelect: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
    },
});


class FavouritePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
        this.handleAddFavourite = this.handleAddFavourite.bind(this);
        this.handleRemoveFavourite = this.handleRemoveFavourite.bind(this);
        this.handleSortByChange = this.handleSortByChange.bind(this);
    }

    getInitialState() {
        return {
            errors: null,
            warnings: null,
            notices: null,
            isLoading: true,
            favouriteId: 0,
            page: 1, // For correct lots numbers only
            pageSize: 1,
            sortBy: '',
            tenders: [],
            favourites: [],
            totalCount: 0,
        };
    }

    componentDidUpdate(prevProps) {
        if (
            (this.pageParam !== this.getIntParam(prevProps.location.search, FavouritePage.pageParamName)) ||
            (this.favouriteIdParam !== this.getParam(prevProps.location.search, FavouritePage.favouriteIdParamName))
        )
            this.updatePage();
        else if (prevProps.appProps.menu !== this.props.appProps.menu)
            this.updateTitle();
    }
    
    componentDidMount() {
        this.updatePage();
    }

    updatePage() {
        this.updateTitle();
        this.setState({isLoading: true});
        this.loadTenders(this.favouriteIdParam, this.pageParam);
    }

    updateTitle() {
        const favourite = this.favourite;
        const title = favourite ? favourite.name : 'Избранное';
        this.props.appProps.setHead(title, '');
    }

    loadTenders(favouriteId, page) {
        if (!favouriteId)
            return this.setState({errors: ['Не указана папка избранного'], warnings: null, notices: null, isLoading: false});
        return API.fetch(this.props.appProps.setAuth,
            Config.favouriteApiUrl,
            '1.0',
            'getFavouriteTenders',
            {
                'id': favouriteId,
                'page': page,
            }
        )
        .then(responseData => {
            const {errors, warnings, notices, content} = responseData;
            if (!(content.tenders && content.tenders.length) && (content.page > 1) && (content.totalCount <= (content.page - 1)*content.pageSize)) {
                // If tenders were removed from favourite and pages count decreased
                const pagesCount = Math.floor((content.totalCount + content.pageSize - 1) / content.pageSize);
                if (pagesCount < page) {
                    const baseUrl = this.getBaseUrlNoPage(favouriteId);
                    this.props.history.replace((pagesCount > 1) ? Routes.addParamToUrl(baseUrl, FavouritePage.pageParamName, pagesCount) : baseUrl);
                    return;
                }
            }
            this.setState(Object.assign(this.getInitialState(), content, {errors, warnings, notices, isLoading: false}));
            window.scrollTo(0, 0);
        })
        .catch(() => {
            this.setState(Object.assign(this.getInitialState(), {errors: [Config.commonErrorText], isLoading: false}))
            window.scrollTo(0, 0);
        });
    }

    handleAddFavourite(favouriteId, registrationNumber) {
        return API.fetch(this.props.appProps.setAuth,
            Config.favouriteApiUrl,
            '1.0',
            'addToFavourite',
            {
                'id': favouriteId,
                'registrationNumber': registrationNumber,
            }
        )
        .then(responseData => {
            const {errors/*, warnings, notices, content*/} = responseData;
            if (!errors || !errors.length)
                this.setState(prevState => ({
                    favourites: Object.assign({}, prevState.favourites,
                        {[registrationNumber]: Array.isArray(prevState.favourites[registrationNumber]) ? Array.from(new Set(prevState.favourites[registrationNumber].concat(favouriteId))) : [favouriteId]}
                    )
                }));
        })
        .catch(() => {});
    }

    handleRemoveFavourite(favouriteId, registrationNumber) {
        return API.fetch(this.props.appProps.setAuth,
            Config.favouriteApiUrl,
            '1.0',
            'removeFromFavourite',
            {
                'id': favouriteId,
                'registrationNumber': registrationNumber,
            }
        )
        .then(responseData => {
            const {errors/*, warnings, notices, content*/} = responseData;
            if (!errors || !errors.length)
                this.setState(prevState => ({
                    favourites: Object.assign({}, prevState.favourites,
                        Array.isArray(prevState.favourites[registrationNumber]) ? {[registrationNumber]: prevState.favourites[registrationNumber].filter(id => id !== favouriteId)} : null
                    )
                }));
        })
        .catch(() => {});
    }

    handleSortByChange(event) {
        const favouriteId = this.favouriteIdParam;
        return API.fetch(this.props.appProps.setAuth,
            Config.favouriteApiUrl,
            '1.0',
            'sortFavouriteBy',
            {
                'id': favouriteId,
                'sortBy': event.target.value,
            }
        )
        .then(responseData => {
            const {errors/*, warnings, notices, content*/} = responseData;
            if (!errors || !errors.length) {
                if (this.state.page > 1) {
                    const baseUrl = this.getBaseUrlNoPage(favouriteId);
                    this.props.history.push(baseUrl);
                } else
                    this.updatePage();
            }
        })
        .catch(() => {});
    }

    getBaseUrlNoPage(favouriteIdParam) {
        let params = {[FavouritePage.favouriteIdParamName]: favouriteIdParam};
        //if (pageParam) Object.assign(params, {[FavouritePage.pageParamName]: pageParam});
        return Routes.addParamsToUrl(Routes.favourite, params);
    }

    // Params from url
    static get favouriteIdParamName()			{return 'id'}
    static get pageParamName()					{return 'p'}
    get favouriteIdParam() {
        return this.getParam(this.props.location.search, FavouritePage.favouriteIdParamName);
    }
    get pageParam() {
        return this.getIntParam(this.props.location.search, FavouritePage.pageParamName);
    }
    get favourite() {
        const favouritesMenu = !this.props.appProps.menu ? null : this.props.appProps.menu.favourites;
        if (!favouritesMenu)
            return null;
        const favouriteId = this.favouriteIdParam;
        let favouritesMenuItem = null;
        for (const item of favouritesMenu)
            if (item.id === favouriteId) {
                favouritesMenuItem = item;
                break;
            }
        return favouritesMenuItem;
    }
    getIntParam(search, paramName) {
        const id = new URLSearchParams(search).get(paramName);
        return /^[\d]+$/.test(id) ? parseInt(id, 10) : null;
    }
    getParam(search, paramName, regExp=null) {
        const value = new URLSearchParams(search).get(paramName);
        return regExp ? (regExp.test(value) ? value : null) : value;
    }

    render() {
        const classes = this.props.classes;
        const {auth} = this.props.appProps;
        const favouritesMenu = !this.props.appProps.menu ? null : this.props.appProps.menu.favourites;
        const {errors, warnings, notices} = this.state;
        const {isLoading, pageSize, tenders, favourites, totalCount, sortBy, favouriteId} = this.state;
        const {pageParam, favouriteIdParam} = this;
        const favourite = this.favourite;
        const pageTitle = favourite ? favourite.name : 'Избранное';
        const baseUrl = this.getBaseUrlNoPage(favouriteId);
        const firstLotIndex = (this.state.page - 1) * pageSize + 1;
        const isSameRun = favouriteId && (favouriteId === favouriteIdParam);
        const pageData = (isLoading && !isSameRun) ? (
            <div align='center'><CircularProgress color="primary" /></div>
        ) : (
            <React.Fragment>
                <Messages errors={errors} warnings={warnings} notices={notices} />
                {!((!errors || !errors.length) && (!tenders || !tenders.length)) ? null : (
                    <Messages key='NotFoundMessage' errors={null} warnings={null} notices={['Тендеры отсутствуют']} />
                )}
                {!(tenders && tenders.length) ? null : (
                    <FormControl className={classes.sortBySelect}>
                        {/*<InputLabel htmlFor="sort_by_select">Сортировать по</InputLabel>*/}
                        <NativeSelect
                            disabled={isLoading}
                            value={sortBy}
                            onChange={this.handleSortByChange}
                            inputProps={{
                                name: 'sort_by',
                                id: 'sort_by_select',
                            }}
                        >
                            <option value='FAVOURITE_DATE_DESC'>Дата добавления ↓</option>
                            <option value='FAVOURITE_DATE_ASC'>Дата добавления ↑</option>
                            <option value='SUBMISSION_DATE_DESC'>Окончание подачи заявок ↓</option>
                            <option value='SUBMISSION_DATE_ASC'>Окончание подачи заявок ↑</option>
                        </NativeSelect>
                    </FormControl>
                )}
                <TendersList isNumerateTenders={true} tenders={tenders} favourites={favourites} favouritesMenu={favouritesMenu} firstLotIndex={firstLotIndex} isNeedPayment={!auth.isActive} ankhorRef={this.tendersRef} onAddFavourite={this.handleAddFavourite} onRemoveFavourite={this.handleRemoveFavourite} />
                <TendersPagination isLoading={isLoading} baseUrl={baseUrl} tendersCount={totalCount} pageSize={pageSize} pageParam={pageParam} pageParamName={FavouritePage.pageParamName} />
            </React.Fragment>
        );
        return (
            <div className={classes.longBlock}>
                <LoadingCursor isLoading={isLoading} />
                <div className={classes.titleBlock}>
                    <StarIcon style={{color: favourite ? favourite.color : '#bababa', fontSize: 32, marginRight: '10px'}} />
                    <Typography variant='h5' display='inline' >{pageTitle}</Typography>
                </div>
                {pageData}
            </div>
        );
    }
}

export default withRouter(withStyles(styles, {withTheme: true})(FavouritePage));
