import React from 'react';
import {Config} from './Config.js';
import {withStyles} from '@material-ui/core/styles';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import API from './API.js';


const styles = theme => ({
    starLabel: {
        marginLeft: theme.spacing(1),
        //marginRight: theme.spacing(1),
    },
});



class DeleteFavouriteDialog extends React.PureComponent {
    render() {
        const {open, favouriteName, handleClose, handleDelete} = this.props;
        return (
            <Dialog open={open} onClose={handleClose} >
                <DialogTitle>Удалить папку избранного</DialogTitle>
                <DialogContent>
                    <DialogContentText>{'Вы действительно хотите удалить папку избранного "' + favouriteName + '"? Информация обо всех тендерах в данной папке будет потеряна.'}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>Отмена</Button>
                    <Button onClick={handleDelete} color="primary">Удалить</Button>
                </DialogActions>
            </Dialog>
        )
    }
}


class EditFavouriteDialog_ extends React.PureComponent {
    constructor(props) {
        super(props);
        const {favourite} = this.props;
        this.state = {
            name: favourite ? favourite.name : 'Избранное',
            color: favourite ? favourite.color : '#ffd750',
            icon: favourite ? favourite.icon : 'STAR',
            isDeleteFavouriteDialogOpened: false,
        };
        this.handleDelete = this.handleDelete.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleIconColorChange = this.handleIconColorChange.bind(this);
        this.handleDeleteFavouriteDialogSwitch = this.handleDeleteFavouriteDialogSwitch.bind(this);
    }

    handleDelete() {
        const {id, getMenu, onClose} = this.props;

        return API.fetch(null,
            Config.favouriteApiUrl,
            '1.0',
            'deleteFavouriteFolder',
            {
                'id': id,
            }
        )
        .then(() => {
            //const {errors, warnings, notices, content} = responseData;
            onClose();
            getMenu();
        })
        .catch(() => {});
    }

    handleSave() {
        const {id, getMenu, onClose} = this.props;
        const {name, color, icon} = this.state;
        const isNew = (id === EditFavouriteDialog.newFavouriteId);

        return API.fetch(null,
            Config.favouriteApiUrl,
            '1.0',
            isNew ? 'addFavouriteFolder' : 'editFavouriteFolder',
            {
                'id': id,
                'name': name,
                'color': color,
                'icon': icon,
            }
        )
        .then(() => {
            //const {errors, warnings, notices, content} = responseData;
            onClose();
            getMenu();
        })
        .catch(() => {});
    }

    handleDeleteFavouriteDialogSwitch() {
        this.setState(prevState => ({isDeleteFavouriteDialogOpened: !prevState.isDeleteFavouriteDialogOpened}));
    }

    handleSubmit(event) {
        this.handleSave();
        event.preventDefault();
    }

    handleNameChange(event) {
        const value = event.target.value;
        this.setState({name: value});
    }

    handleIconColorChange(event) {
        const value = event.target.value;
        this.setState({color: value});
    }

    static getFavourite(favourites, editFavouriteId) {
        let favouriteToEdit = null;
        if ((editFavouriteId !== 0) && (editFavouriteId !== EditFavouriteDialog.newFavouriteId) && favourites)
            for (let favourite of favourites)
                if (favourite.id === editFavouriteId) {
                    favouriteToEdit = favourite;
                    break;
                }
        return favouriteToEdit;
    }

    static get newFavouriteId()	{return 'new'}

    render() {
        const classes = this.props.classes;
        const {id, onClose} = this.props;
        const isNew = (id === EditFavouriteDialog.newFavouriteId);
        const {name, color, isDeleteFavouriteDialogOpened} = this.state;
        return (
            <React.Fragment>
                <Dialog key={id} open={id !== 0} fullWidth maxWidth='xs' onClose={onClose} disableBackdropClick >
                    <DialogTitle id="edit-favourite-dialog-title">{isNew ? 'Новая папка избранного' : 'Редактирование папки избранного'}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.handleSubmit} method='POST'>
                            <TextField variant="outlined" margin="normal" fullWidth name='name' type='text' autoComplete='off' autoFocus
                                label='Название'
                                value={name}
                                onChange={this.handleNameChange}
                                InputProps={{inputProps: {maxLength: 255}}}
                            />
                            <RadioGroup row name="icon_color" value={color} onChange={this.handleIconColorChange} style={{marginTop: '8px'}}>
                                <FormControlLabel value="#ff2020" control={<Radio color='primary' />} label={<StarIcon style={{color: "#ff4545"}} />} labelPlacement='bottom' className={classes.starLabel} />
                                <FormControlLabel value="#fc954f" control={<Radio color='primary' />} label={<StarIcon style={{color: "#fc954f"}} />} labelPlacement='bottom' className={classes.starLabel} />
                                <FormControlLabel value="#ffd750" control={<Radio color='primary' />} label={<StarIcon style={{color: "#ffd750"}} />} labelPlacement='bottom' className={classes.starLabel} />
                                <FormControlLabel value="#7ae044" control={<Radio color='primary' />} label={<StarIcon style={{color: "#7ae044"}} />} labelPlacement='bottom' className={classes.starLabel} />
                                <FormControlLabel value="#5fb0fd" control={<Radio color='primary' />} label={<StarIcon style={{color: "#5fb0fd"}} />} labelPlacement='bottom' className={classes.starLabel} />
                                <FormControlLabel value="#b866c6" control={<Radio color='primary' />} label={<StarIcon style={{color: "#b866c6"}} />} labelPlacement='bottom' className={classes.starLabel} />
                            </RadioGroup>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">Отмена</Button>
                        {isNew ? null : (<Button onClick={this.handleDeleteFavouriteDialogSwitch} color="primary">Удалить</Button>)}
                        <Button onClick={this.handleSave} color="primary">Сохранить</Button>
                    </DialogActions>
                </Dialog>
                <DeleteFavouriteDialog open={isDeleteFavouriteDialogOpened} favouriteName={name} handleClose={this.handleDeleteFavouriteDialogSwitch} handleDelete={this.handleDelete} />
            </React.Fragment>
        )
    }
}
const EditFavouriteDialog = withStyles(styles, {withTheme: true})(EditFavouriteDialog_);


export {EditFavouriteDialog};
