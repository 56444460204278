import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Config, Routes} from './Config.js';
import {withStyles} from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import LockIcon from '@material-ui/icons/Lock';
import { Badge, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, MenuItem, MenuList, Popover, SvgIcon, Tooltip } from '@material-ui/core';
import AccountCard from './AccountCard.js';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MenuIcon from '@material-ui/icons/Menu';
//import PublicIcon from '@material-ui/icons/Public';

//import LogoIcon from './svg/logo.svg';
import { ReactComponent as LogoIcon } from "./svg/logo.svg";

const appHeaderHeight = 78;

const styles = theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    appHeader: {
        height: appHeaderHeight,
        minHeight: appHeaderHeight,
    },
    title: {
        color: 'inherit',
        textDecoration: 'none',
        outline: 'none',
    },
    menuItem: {
        flexGrow: 1,
        textDecoration: 'none',
        color: theme.palette.text.primary,
        '&:hover, &:link, &:active, &:visited': {
            textDecoration: 'none',
            color: theme.palette.text.primary,
        }
    },
});


class AppHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            accountCardAnchorEl: null,
            supportAnchorEl: null,
            isSupportInfoOpen: false,
        }
        this.handleAccountClick = this.handleAccountClick.bind(this);
        this.handleAccountClose = this.handleAccountClose.bind(this);
        this.handleSupportClick = this.handleSupportClick.bind(this);
        this.handleSupportClose = this.handleSupportClose.bind(this);
        this.openSupportInfo = this.openSupportInfo.bind(this);
        this.closeSupportInfo = this.closeSupportInfo.bind(this);
    }

    static get height()	{return appHeaderHeight}

    handleAccountClick(event) {
        this.setState({accountCardAnchorEl: event.currentTarget});
    }

    handleAccountClose() {
        this.setState({accountCardAnchorEl: null});
    }

    handleSupportClick(event) {
        this.setState({supportAnchorEl: event.currentTarget});
    }

    handleSupportClose() {
        this.setState({supportAnchorEl: null});
    }

    openSupportInfo() {
        this.setState({isSupportInfoOpen: true, supportAnchorEl: null});
    }

    closeSupportInfo() {
        this.setState({isSupportInfoOpen: false});
    }

    render() {
        const classes = this.props.classes;
        const {auth, handleMobileMenuToggle} = this.props;
        const {accountCardAnchorEl, supportAnchorEl, isSupportInfoOpen} = this.state;

        const account = (auth && auth.login) ? (
                <React.Fragment>
                    <Tooltip
                        placement="bottom-end"
                        title={
                            <React.Fragment>
                                <Typography variant='body2' align='left'>{auth.name}</Typography>
                                <Typography variant='body2' align='left'>{auth.login}</Typography>
                            </React.Fragment>
                        }
                    >
                        <IconButton onClick={this.handleAccountClick} color='inherit'>
                            <Badge badgeContent={(auth.isActive ? 0 : 1) + (auth.isConfirmed ? 0 : 1)} color='error' overlap='circle'>
                                <AccountCircle fontSize="large" />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                    <Popover 
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        transformOrigin={{vertical: 'top', horizontal: 'right'}}
                        anchorEl={accountCardAnchorEl}
                        open={Boolean(accountCardAnchorEl)}
                        onClose={this.handleAccountClose}
                    >
                        <AccountCard auth={auth} onClose={this.handleAccountClose} />
                    </Popover>
                </React.Fragment>
            ) : (
                <Button component={RouterLink} to={Routes.login} color="inherit" startIcon={<LockIcon />}>Войти</Button>
            );
        const support = (
            <React.Fragment>
                <Tooltip
                    placement="bottom-end"
                    title={<Typography variant='body2' align='left'>Помощь</Typography>}
                >
                    <IconButton onClick={this.handleSupportClick} color='inherit'>
                        <HelpOutlineIcon fontSize={(auth && auth.login) ? 'large' : 'default'} />
                    </IconButton>
                </Tooltip>
                <Popover 
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    transformOrigin={{vertical: 'top', horizontal: 'right'}}
                    anchorEl={supportAnchorEl}
                    open={Boolean(supportAnchorEl)}
                    onClose={this.handleSupportClose}
                >
                    <MenuList autoFocusItem={Boolean(supportAnchorEl)}>
                        {!(auth && auth.login) ? null : <RouterLink to={Routes.help} className={classes.menuItem}><MenuItem onClick={this.handleSupportClose}>Справка</MenuItem></RouterLink>}
                        <MenuItem onClick={this.openSupportInfo} className={classes.menuItem}>Техподдержка</MenuItem>
                    </MenuList>
                </Popover>
            </React.Fragment>
        );
        const supportInfo = !isSupportInfoOpen ? null : (
            <Dialog open={true} >
                <DialogTitle id="support-contacts-dialog">Техническая поддержка</DialogTitle>
                <DialogContent>
                    <DialogContentText id="support-contacts-dialog-description">{'По всем вопросам, связанным с работой сервиса, настройкой поиска, оплатой, а так же с предложениями и пожеланиями обращайтесь в техническую поддержку на адрес '}{Config.supportLink}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.closeSupportInfo} color="primary">OK</Button>
                </DialogActions>
            </Dialog>
        );
        return (
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.appHeader}>

                    <Box display={{ xs: 'flex', [Config.mobileMenuBreakpoint]: 'none' }} style={{alignItems: 'center'}} component="div">
                        {(auth && auth.login) ? (
                            <IconButton onClick={handleMobileMenuToggle} color="inherit" edge="start">
                                <MenuIcon fontSize="large" />
                            </IconButton>
                        ) : (
                            <RouterLink to={Routes.lk} className={classes.title}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <SvgIcon component={LogoIcon} viewBox="0 0 24 24" fontSize="large" style={{marginRight: '16px'}} />
                                </div>
                            </RouterLink>
                        )}
                        <RouterLink to={Routes.lk} className={classes.title}>
                            <Typography display='inline' component="h1" variant="h6" noWrap>
                                Поиск Закупок
                            </Typography>
                        </RouterLink>
                    </Box>
                    <Box display={{ xs: 'none', [Config.mobileMenuBreakpoint]: 'flex' }} style={{alignItems: 'center'}} component="div">
                        <RouterLink to={Routes.lk} className={classes.title}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {/*<PublicIcon fontSize="large" style={{marginRight: '16px'}} />*/}
                                <SvgIcon component={LogoIcon} viewBox="0 0 24 24" fontSize="large" style={{marginRight: '16px'}} />
                                <Typography display='inline' component="h1" variant="h6" noWrap>
                                    Поиск Закупок
                                </Typography>
                            </div>
                        </RouterLink>
                    </Box>

                    <div style={{flexGrow: 1}} />
                    {supportInfo}
                    {support}
                    {account}
                </Toolbar>
            </AppBar>
        );
    }
}


export default withStyles(styles, {withTheme: true})(AppHeader);

