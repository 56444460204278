import React from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import {Config, Routes} from './Config.js';
import {withStyles} from '@material-ui/core/styles';

import { Button, Grid, TextField } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import TemplateFormPage from './TemplateFormPage.js';
import API from './API.js';
import LoadingCursor from './LoadingCursor.js';

const styles = theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

  
class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            warnings: [],
            notices: [],
            email: '',
            password: '',
            isLoading: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    componentDidMount() {
        const {appProps} = this.props;
        const {auth} = appProps;
        if (auth && auth.login) // Already logged in
            this.handleSuccessfullyLoggedIn();
        else
            this.props.appProps.setHead('Вход', '');
    }

    componentDidUpdate(prevProps) {
        const {appProps} = this.props;
        const {auth} = appProps;
        if (auth && auth.login && (auth !== prevProps.auth)) // Already logged in
            this.handleSuccessfullyLoggedIn();
    }

    handleChange(event) {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({[name]: value});
    }

    handleSubmit(event) {
        const {isLoading, email, password} = this.state;
        const isSubmitEnabled = !isLoading && Boolean(email.trim()) && Boolean(password.trim());
        if (isSubmitEnabled) {
            this.setState({isLoading: true}); //, errors: [], warnings: [], notices: []});
            this.login(email, password);
        }
        event.preventDefault();
    }

    handleSuccessfullyLoggedIn() {
        const fromState = this.props.location.state;
        const fromParam = this.fromParam;
        if (fromState && fromState.from)
            this.props.history.replace(fromState.from);
        else if (fromParam)
            Routes.externalRedirect(fromParam);
        else
            this.props.history.replace(Routes.lk);
    }

    login(email, password) {
        return API.fetch(this.props.appProps.setAuth,
            Config.authApiUrl,
            '1.0',
            'login',
            {
                login: email,
                password: password,
            }
        )
        .then(responseData => {
            const {auth, errors, warnings, notices} = responseData;
            if (!auth.login) // To prevent "Warning: Can't perform a React state update on an unmounted component.""
                this.setState({errors, warnings, notices, isLoading: false});
        })
        .catch(() => this.setState({isLoading: false, errors: [Config.commonErrorText]}));
    }

    static get fromParamName()	{return 'p'}
    get fromParam() {
        return this.getParam(this.props.location.search, LoginPage.fromParamName);
    }
    getParam(search, paramName) {
        return new URLSearchParams(search).get(paramName);
    }

    render() {
        const classes = this.props.classes;
        const {appProps} = this.props;
        const {auth} = appProps;
        const {isLoading, email, password} = this.state;
        const {errors, warnings, notices} = this.state;
//        if (auth && auth.login) // Already logged in, go to LK
//            return <Redirect to={{pathname: Routes.lk}} />;
        const registerLink = auth.prevLogin ? null : ( // Multiple register is not allowed
            <Grid item>
                <Link to={Routes.register}>Зарегистрироваться</Link>
            </Grid>
        );
        const content = (
            <form className={classes.form} onSubmit={this.handleSubmit} method='POST'>
                <TextField disabled={isLoading} value={email} variant="outlined" margin="normal" required fullWidth name="email" label="Email" type="email" id="email" autoComplete="email" autoFocus onChange={this.handleChange} InputProps={{inputProps: {maxLength: 320}}} />
                <TextField disabled={isLoading} value={password} variant="outlined" margin="normal" required fullWidth name="password" label="Пароль" type="password" id="password" autoComplete="current-password" onChange={this.handleChange} InputProps={{inputProps: {maxLength: 100}}} />
                <Button type="submit" disabled={isLoading} fullWidth variant="contained" color="primary" className={classes.submit} >
                    Войти
                </Button>
                <Grid container>
                    <Grid item xs>
                        <Link to={Routes.restore}>Забыли пароль?</Link>
                    </Grid>
                    {registerLink}
                </Grid>
            </form>
        );
        return (
            <React.Fragment>
                <LoadingCursor isLoading={isLoading} />
                <TemplateFormPage
                    title='Войти'
                    icon={<LockOutlinedIcon />}
                    isDivider={false}
                    content={content}
                    errors={errors} warnings={warnings} notices={notices}
                />
            </React.Fragment>
        );
    }
}

export default withRouter(withStyles(styles, {withTheme: true})(LoginPage));
