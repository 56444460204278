import React from 'react';
import {Config} from './Config.js';
import {withStyles} from '@material-ui/core/styles';
import './css/style_index.css';

import { CircularProgress } from '@material-ui/core';
import Messages from './Messages.js';
import API from './API.js';
import { withRouter } from 'react-router-dom';
import LoadingCursor from './LoadingCursor.js';

const styles = theme => ({
    longBlock: {
        minHeight: '4000px',
    },
});

  
class HelpPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            warnings: [],
            notices: [],
            pageHtml: null,
        };
    }

    componentDidMount() {
        this.props.appProps.setHead('Справка', 'Инструкция по настройке поиска тендеров');
        //this.setState({isLoading: true}); //, errors: [], warnings: [], notices: []});
        this.loadPageHtml();
    }
    
    loadPageHtml() {
        return API.fetch(this.props.appProps.setAuth,
            Config.dataApiUrl,
            '1.0',
            'getHelpPage',
            {}
        )
        .then(responseData => {
            const {errors, warnings, notices, content} = responseData;
            const html = (content && content.html && content.html.startsWith('<!--HelpPage-->')) ? content.html : null;
            this.setState({errors, warnings, notices, pageHtml: html});
            // Scroll to anchor after loading page
            let anchor = this.anchor;
            let anchorElement = anchor ? document.getElementById(anchor) : null;
            if (anchorElement)
                anchorElement.scrollIntoView(); // {behavior: "smooth"}
        })
        .catch(() => this.setState({errors: [Config.commonErrorText]}));
    }
    
    get anchor() {
        let anchor = this.props.location.hash;
        return (anchor && anchor.startsWith('#')) ? anchor.substring(1) : null;
    }

    render() {
        const classes = this.props.classes;
        const {pageHtml} = this.state;
        const {errors, warnings, notices} = this.state;
        const isLoading = !pageHtml && (!errors || !errors.length);
        const pageData = pageHtml ? (
            <div dangerouslySetInnerHTML={{__html: pageHtml}} style={{textAlign: 'left'}} />
        ) : (
            <div className={classes.longBlock}>
                {(!errors || !errors.length) ? <CircularProgress color="primary" /> : null}
            </div>
        );
        return (
            <React.Fragment>
                <LoadingCursor isLoading={isLoading} />
                <Messages errors={errors} warnings={warnings} notices={notices} />
                {pageData}
            </React.Fragment>
        );
    }
}

export default withRouter(withStyles(styles, {withTheme: true})(HelpPage));
