import React from 'react';
import {withRouter} from 'react-router';
import {Config, Routes} from './Config.js';
import {withStyles} from '@material-ui/core/styles';

import { Button } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TemplateFormPage from './TemplateFormPage.js';
import API from './API.js';
import LoadingCursor from './LoadingCursor.js';

const styles = theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

  
class LogoutPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            warnings: [],
            notices: [],
            isLoading: true,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        const {isLoading} = this.state;
        const isSubmitEnabled = !isLoading;
        if (isSubmitEnabled) {
            this.setState({isLoading: true}); //, errors: [], warnings: [], notices: []});
            this.logout();
        }
        event.preventDefault();
    }

    componentDidMount() {
        this.props.appProps.setHead('Выйти', '');
        this.logout();
    }
    
    logout() {
        return API.fetch(this.props.appProps.setAuth,
            Config.authApiUrl,
            '1.0',
            'logout',
            {}
        )
        .then(responseData => {
            const {errors, warnings, notices} = responseData;
            this.setState({errors, warnings, notices, isLoading: false});
            if (!errors || !errors.length)
                this.props.history.replace(Routes.login);
        })
        .catch(() => this.setState({isLoading: false, errors: [Config.commonErrorText]}));
    }

    render() {
        const classes = this.props.classes;
        const {isLoading} = this.state;
        const {errors, warnings, notices} = this.state;
        const content = (
            <form className={classes.form} onSubmit={this.handleSubmit} method='POST'>
                <Button type="submit" disabled={isLoading} fullWidth variant="contained" color="primary" className={classes.submit} >
                    Повторить
                </Button>
            </form>
        );
        return (
            <React.Fragment>
                <LoadingCursor isLoading={isLoading} />
                <TemplateFormPage
                    title='Выйти'
                    icon={<ExitToAppIcon />}
                    isDivider={false}
                    content={content}
                    errors={errors} warnings={warnings} notices={notices}
                />
            </React.Fragment>
        );
    }
}

export default withRouter(withStyles(styles, {withTheme: true})(LogoutPage));
