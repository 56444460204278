import React from 'react';
import {withRouter} from 'react-router';
import {Config} from './Config.js';
import {withStyles} from '@material-ui/core/styles';

import { Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import UnsubscribeIcon from '@material-ui/icons/Unsubscribe';
import TemplateFormPage from './TemplateFormPage.js';
import API from './API.js';
import LoadingCursor from './LoadingCursor.js';

const styles = theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});


class UnsubscribePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            warnings: [],
            notices: [],
            reason: '',
            isLoading: true,
            isCodeValid: false,
            isUnsubscribed: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleChange(event) {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({[name]: value});
    }

    handleSubmit(event) {
        const {isLoading, isCodeValid, reason} = this.state;
        const isSubmitEnabled = !isLoading && isCodeValid;
        if (isSubmitEnabled) {
            this.setState({isLoading: true});
            this.unsubscribe(reason);
        }
        event.preventDefault();
    }

    componentDidMount() {
        this.props.appProps.setHead('Отписаться от рассылки', '');
        this.checkUnsubscribeCode();
    }

    unsubscribe(reason) {
        const code = this.code;
        if (!code)
            return this.setState({isLoading: false, errors: ['Не указаны параметры отключения рассылки']});
        return API.fetch(this.props.appProps.setAuth,
            Config.authApiUrl,
            '1.0',
            'unsubscribe',
            {
                reason: reason,
                code: code,
            }
        )
        .then(responseData => {
            const {errors, warnings, notices} = responseData;
            const isUnsubscribed = !errors || !errors.length;
            this.setState({errors, warnings, notices, isLoading: false, isUnsubscribed: isUnsubscribed});
            if (isUnsubscribed)
                this.props.appProps.getMenu(false);
        })
        .catch(() => this.setState({isLoading: false, errors: [Config.commonErrorText]}));
    }

    checkUnsubscribeCode() {
        const code = this.code;
        if (!code)
            return this.setState({isLoading: false, errors: ['Не указаны параметры отключения рассылки']});
        return API.fetch(this.props.appProps.setAuth,
            Config.authApiUrl,
            '1.0',
            'checkUnsubscribeCode',
            {
                code: code,
            }
        )
        .then(responseData => {
            const {errors, warnings, notices} = responseData;
            const isCodeValid = !errors || !errors.length;
            this.setState({errors, warnings, notices, isLoading: false, isCodeValid: isCodeValid});
        })
        .catch(() => this.setState({isLoading: false, errors: [Config.commonErrorText]}));
    }

    get code() {
        return new URLSearchParams(this.props.location.search).get(Config.codeParamName);
    }
    
    render() {
        const classes = this.props.classes;
        //const {appProps} = this.props;
        //const {auth} = appProps;
        const {isLoading, isCodeValid, isUnsubscribed, reason, errors, warnings, notices} = this.state;
        let content = null;
        if (isUnsubscribed)
            content = <Typography align='justify'>Поиск успешно отключен. Вы больше не будете получать письма с найденными тендерами. Снова включить поиск вы можете в личном кабинете.</Typography>;
        else if (!isCodeValid)
            content = !isLoading ? null : <CircularProgress color="primary" />;
        else
            content = (
                <form className={classes.form} onSubmit={this.handleSubmit} method='POST'>
                    <Typography align='justify'>Вы собираетесь отключить поиск тендеров. После отключения сервис больше не будет искать тендеры по вашим критериям и присылать вам файлы результатов.</Typography>
                    <TextField disabled={isLoading || !isCodeValid} value={reason} variant="outlined" margin="normal" fullWidth name="reason" label="Причина отключения" type="text" id="reason" autoComplete="off" onChange={this.handleChange} InputProps={{inputProps: {maxLength: 250}}} />
                    <Button type="submit" disabled={isLoading || !isCodeValid} fullWidth variant="contained" color="primary" className={classes.submit}>
                        Отключить сервис
                    </Button>
                </form>
            );
        return (
            <React.Fragment>
                <LoadingCursor isLoading={isLoading} />
                <TemplateFormPage
                    title='Отписаться'
                    icon={<UnsubscribeIcon />}
                    isDivider={isUnsubscribed || !isCodeValid}
                    content={content}
                    errors={errors} warnings={warnings} notices={notices}
                />
            </React.Fragment>
        );
    }
}

export default withRouter(withStyles(styles, {withTheme: true})(UnsubscribePage));
