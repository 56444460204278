import React from 'react';
import {Redirect, withRouter} from 'react-router';
import { Link } from 'react-router-dom';
import {Config, Routes} from './Config.js';
import {withStyles} from '@material-ui/core/styles';

import { Button, Checkbox, FormControlLabel, Link as LinkHref, TextField, Typography } from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TemplateFormPage from './TemplateFormPage.js';
import API from './API.js';
import LoadingCursor from './LoadingCursor.js';

const styles = theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    registerNote: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
});

  
class RegisterPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            warnings: [],
            notices: [],
            name: '',
            tel: '',
            email: '',
            password: '',
            promoCode: '',
            isAgreed: true,
            isLoading: false,
            isRegistered: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.appProps.setHead('Регистрация', 'Регистрация в сервисе ' + Config.serviceName);
        const {auth} = this.props.appProps;
        if (!auth || (!auth.login && !auth.prevLogin))
            if (window.ym)
                window.ym(Config.yaMetricId, 'reachGoal', 'RegisterForm');
    }

    handleChange(event) {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({[name]: value});
    }

    handleSubmit(event) {
        const {isLoading, name, tel, email, password, promoCode, isAgreed} = this.state;
        const isSubmitEnabled = !isLoading && isAgreed && Boolean(email.trim()) && Boolean(password.trim()) && Boolean(name.trim()) && Boolean(tel.trim());
        if (isSubmitEnabled) {
            this.setState({isLoading: true}); //, errors: [], warnings: [], notices: []});
            this.register(name, tel, email, password, promoCode);
        }
        event.preventDefault();
    }

    register(name, tel, email, password, promoCode) {
        // Если сразу обновлять auth в API, то isRegistered не успевает обновиться и идет редирект на личный кабинет без показа успешной регистрации
        //return API.fetch(this.props.appProps.setAuth,
        return API.fetch(null,
            Config.authApiUrl,
            '1.0',
            'register',
            {
                login: email,
                password: password,
                contactPerson: name,
                phone: tel,
                promo_code: promoCode,
            }
        )
        .then(responseData => {
            const {errors, warnings, notices} = responseData;
            const isRegistered = !errors || !errors.length;
            this.setState({errors, warnings, notices, isLoading: false, isRegistered: isRegistered});
            if (this.props.appProps.setAuth)
                this.props.appProps.setAuth(responseData.auth)
            if (isRegistered)
            {
                this.props.history.replace(Routes.register_ok);
                if (window.ym)
                    window.ym(Config.yaMetricId, 'reachGoal', 'RegisterOk');
            }
        })
        .catch(() => this.setState({isLoading: false, errors: [Config.commonErrorText]}));
    }

    render() {
        const classes = this.props.classes;
        const {appProps} = this.props;
        const {auth} = appProps;
        const {isLoading, isRegistered, name, tel, email, password, promoCode, isAgreed} = this.state;
        const {errors, warnings, notices} = this.state;
        let content = null;
        if (isRegistered)
            content = (
                <React.Fragment>
                    <Typography align='justify'>Благодарим вас за регистрацию. Вам отправлено письмо со ссылкой для подтверждения электронной почты. Если в течение часа письмо не придет, напишите в техническую поддержку {Config.supportLink}</Typography>
                    <Typography paragraph />
                    <Button component={Link} to={Routes.newTemplate} color="primary">
                        Личный кабинет
                    </Button>
                </React.Fragment>
            );
        else if (auth && auth.login) // Already logged in, go to LK
            return <Redirect to={Routes.lk} />;
        else if (auth && auth.prevLogin) // Multiple register is not allowed
            return <Redirect to={Routes.login} />;
        else
            content = (
                <form className={classes.form} onSubmit={this.handleSubmit} method='POST'>
                    <Typography variant='body2' align='justify' className={classes.registerNote}>
                        Для получения рассылки новых тендеров и работы с сервисом, пожалуйста, зарегистрируйтесь. Если вы уже зарегистрированы, нажмите <Link to={Routes.login}>Войти</Link>.
                    </Typography>
                    <TextField disabled={isLoading} value={name} variant="outlined" margin="normal" required fullWidth name="name" label="Контактное лицо" type="text" id="name" autoComplete="name" autoFocus onChange={this.handleChange} InputProps={{inputProps: {maxLength: 250}}} />
                    <TextField disabled={isLoading} value={tel} variant="outlined" margin="normal" required fullWidth name="tel" label="Телефон" type="tel" id="tel" autoComplete="tel" onChange={this.handleChange} InputProps={{inputProps: {maxLength: 50}}} />
                    <TextField disabled={isLoading} value={email} variant="outlined" margin="normal" required fullWidth name="email" label="Email" type="email" id="email" autoComplete="email" onChange={this.handleChange} InputProps={{inputProps: {maxLength: 320}}} />
                    <TextField disabled={isLoading} value={password} variant="outlined" margin="normal" required fullWidth name="password" label="Пароль" type="password" id="password" autoComplete="new-password" onChange={this.handleChange} InputProps={{inputProps: {maxLength: 100}}} />
                    {/*<TextField disabled={isLoading} value={promoCode} variant="outlined" margin="normal" fullWidth name="promoCode" label="Специальный код при наличии" type="text" id="promoCode" autoComplete="off" onChange={this.handleChange} InputProps={{inputProps: {maxLength: 50}}} />*/}
                    <Typography paragraph />
                    <FormControlLabel
                        control={
                            <Checkbox disabled={isLoading} checked={isAgreed} name="isAgreed" color="primary" required onChange={this.handleChange} />
                        }
                        label={
                            <Typography variant='body2' align='justify'>
                                Подтверждаю согласие с <LinkHref href={Routes.confidential} target="_blank">политикой конфиденциальности</LinkHref>.
                            </Typography>
                        }
                    />
                    <Button type="submit" disabled={isLoading || !isAgreed} fullWidth variant="contained" color="primary" className={classes.submit} >
                        Зарегистрироваться
                    </Button>
                </form>
            );
        return (
            <React.Fragment>
                <LoadingCursor isLoading={isLoading} />
                <TemplateFormPage
                    title='Регистрация'
                    icon={isRegistered ? <CheckCircleIcon /> : <PersonAddIcon />}
                    isDivider={isRegistered}
                    content={content}
                    errors={errors} warnings={warnings} notices={notices}
                />
            </React.Fragment>
        );
    }
}

export default withRouter(withStyles(styles, {withTheme: true})(RegisterPage));
