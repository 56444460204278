import React from 'react';
import {withRouter} from 'react-router';
import TreeOkpd2 from './TreeOkpd2.js';
import Okpd2SearchBar from './Okpd2SearchBar.js';
import Okpd2SearchPage from './Okpd2SearchPage.js';


class Okpd2Page extends React.Component {
    componentDidMount() {
        this.props.appProps.setHead('Справочник ОКПД2', 'Справочник ОКПД2. Общероссийский классификатор продукции по видам экономической деятельности');
    }

    render() {
        const search = new URLSearchParams(this.props.location.search).get(Okpd2SearchBar.searchParamName);
        const {appProps} = this.props;
        if (search)
            return <Okpd2SearchPage appProps={appProps} />;
        return (
            <React.Fragment>
                <h1>Справочник ОКПД2 (общероссийский классификатор продукции по видам экономической деятельности)</h1>
                <Okpd2SearchBar />
                <br />
                <TreeOkpd2 id='okpd2_tree' />
            </React.Fragment>
        );
    }
}


export default withRouter(Okpd2Page);
