import React from 'react';
import { Link } from "@material-ui/core";
import TemplatePage from './TemplatePage';

const base = '';
//const base = '/site2';

const apiUrl = base + '/api';

const lk = '/lk';

class Config {
    static get titlePrefix()		{return 'Поиск Закупок'}
    static get supportEmail()		{return 'support@zakupki-poisk.ru'}
    static get emailParamName()		{return 'email'}
    static get codeParamName()		{return 'code'}

    static get okpd2ApiUrl()		{return apiUrl+'/okpd2.html'}
    static get authApiUrl()			{return apiUrl+'/auth.html'}
    static get dataApiUrl()			{return apiUrl+'/data.html'}
    static get dataPublicApiUrl()	{return apiUrl+'/data_public.html'}
    static get billingApiUrl()		{return apiUrl+'/billing.html'}
    static get templateApiUrl()		{return apiUrl+'/template.html'}
    static get searchApiUrl()		{return apiUrl+'/search.html'}
    static get favouriteApiUrl()	{return apiUrl+'/favourite.html'}

    static get commonErrorText()	{return 'Произошла ошибка. Обновите страницу и попробуйте еще раз. Если ошибка повторится, обратитесь в техническую поддержку.'}
    static get supportLink()		{return <Link href={'mailto:' + Config.supportEmail}>{Config.supportEmail}</Link>}
    static get serviceName()		{return 'Поиск Закупок'}

    static get mobileMenuBreakpoint(){return 'md'}

    static get yaMetricId()			{return 38423360}
}

class Routes {
    static get base()				{return base}

    static get lk()					{return base+lk}
    static get okpd2()				{return base+lk+'/okpd2'}
    static get okpd2Search()		{return base+lk+'/okpd2'}
    static get template()			{return base+lk+'/template'}
    static get newTemplate()		{return Routes.addParamToUrl(Routes.template, TemplatePage.templateIdParamName, TemplatePage.newTemplateId)}
    static get search()				{return base+lk+'/search'}
    static get favourite()			{return base+lk+'/favourite'}
    static get account()			{return base+lk+'/account'}
    static get billing()			{return base+lk+'/billing'}
    static get acceptance()			{return base+lk+'/acceptance'}
    static get newInvoice()			{return base+lk+'/newinvoice'}
    static get help()				{return base+lk+'/help'}

    static get login()				{return base+'/login'}
    static get logout()				{return base+'/logout'}
    static get register()			{return base+'/register'}
    static get register_ok()		{return Routes.register+'/ok'}
    static get confirm()			{return base+'/confirm'}
    static get restore()			{return base+'/restore'}
    static get newPassword()		{return base+'/password'}
    static get unsubscribe()		{return base+'/unsubscribe'}
    static get confidential()		{return base+'/confidential'}

    static get home()				{return base+'/'}
    static get homeTest()			{return base+'/index'}

    static addParamToUrl(path, param, value) {
        return Routes.addParamsToUrl(path, {[param]: value})
    }
    
    static addParamsToUrl(path, params) {
        const fakeBase = 'http://a';
        let url;
        let useFakeBase = false;
        try {
            url = new URL(path);
        } catch (err) {
            url = new URL(path, fakeBase);
            useFakeBase = true;
        }
        for (let name in params)
            url.searchParams.set(name, params[name]);
        return !useFakeBase ? url : url.href.replace(fakeBase, '');
    }

    static getUrlParamString(param, value) {
        return Routes.getUrlParamsString({[param]: value})
    }

    static getUrlParamsString(params) {
        let urlParams = new URLSearchParams();
        for (let name in params)
            urlParams.set(name, params[name]);
        return urlParams.toString();
    }

    static externalRedirect(to) {
        const fakeBase = 'http://a';
        let url;
        try {
            url = new URL(to);
        } catch (err) {
            url = new URL(to, fakeBase);
        }

        let form = document.createElement('form');
        form.action = to;
        form.method = 'GET';
        for (let p of url.searchParams) {
            let input = document.createElement('input');
            input.type='hidden';
            input.name=p[0];
            input.value=p[1];
            form.appendChild(input);
        }
        document.body.append(form); // Перед отправкой формы, её нужно вставить в документ
        form.submit();
    }
}


export {Config, Routes};
