import React from 'react';
import {Link} from 'react-router-dom';
import {Config, Routes} from './Config.js';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import PaymentIcon from '@material-ui/icons/Payment';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import DescriptionIcon from '@material-ui/icons/Description';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import EmailIcon from '@material-ui/icons/Email';
import { IconButton, ListItemSecondaryAction, Tooltip, Typography } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import TemplatePage from './TemplatePage.js';
import SearchPage from './SearchPage.js';
import {EditFavouriteDialog} from './FavouriteComponents.js';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';


const styles = theme => ({
    nestedMenuItem: {
        paddingLeft: theme.spacing(5),
    },
        nestedMenuItemText: {
    },
    listItemIcon: {
        minWidth: '42px',
    },
    secondaryActionHidden: {
    },
    menuItem: {
        [theme.breakpoints.up(Config.mobileMenuBreakpoint)]: { /* Hide secondary actions for desktop menu, allways show it for mobile menu */
            "& ~ $secondaryActionHidden": {
                display: 'none',
            },
            '&:hover': {
                "& ~ $secondaryActionHidden": {
                    display: 'block',
                },
            },
            "& ~ $secondaryActionHidden:hover": {
                display: 'block',
            },
        },
    },
    lightTooltip: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.primary,
        boxShadow: theme.shadows[1],
    },
});


function MenuItem_(props) {
    const {text, icon, link, onClick, className, textClassName, classes, settingsLink, onSettingsClick} = props;
    return (
        <ListItem button component={onClick ? 'div' : Link} to={link} onClick={onClick} className={clsx(classes.menuItem, className)}>
            <ListItemIcon className={classes ? classes.listItemIcon : null}>
                {icon}
            </ListItemIcon>
            <ListItemText primary={text} classes={{primary: (textClassName ? textClassName : null),}} />
            {!(settingsLink || onSettingsClick)  ? null : (
                <ListItemSecondaryAction className={classes.secondaryActionHidden}>
                    <Tooltip classes={{tooltip: classes.lightTooltip}} title={
                        <Typography variant='body2' align='left'>Параметры</Typography>}
                    >
                        <IconButton edge='end' size='small' component={onSettingsClick ? 'div' : Link} to={settingsLink} onClick={onSettingsClick}>
                            <SettingsIcon fontSize='small' />
                        </IconButton>
                    </Tooltip>
                </ListItemSecondaryAction>
            )}
        </ListItem>
    );
}
const MenuItem = withStyles(styles, {withTheme: true})(MenuItem_);

function MenuItemExpandable_(props) {
    const {text, icon, isExpanded, switchState, className, textClassName, classes} = props;
    return (
        <ListItem button onClick={switchState} className={className ? className : null}>
            <ListItemIcon className={classes ? classes.listItemIcon : null}>
                {icon}
            </ListItemIcon>
            <ListItemText primary={text} classes={{primary: (textClassName ? textClassName : null),}} />
            {isExpanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
        </ListItem>
    );
}
const MenuItemExpandable = withStyles(styles, {withTheme: true})(MenuItemExpandable_);


class AppMenu extends React.Component {
    constructor(props) {
        super(props);
        const {isSearchOpen, isFavouriteOpen, isPaymentOpen} = this;
        this.state = {
            [isSearchOpen]: true,
            [isFavouriteOpen]: true,
            [isPaymentOpen]: false,
            editFavouriteId: 0,
        };
        this.switchState = this.switchState.bind(this);
        this.handleFavouriteSettings = this.handleFavouriteSettings.bind(this);
        this.handleFavouriteDialogClose = this.handleFavouriteDialogClose.bind(this);
    }

    get isSearchOpen()		{return 'isSearchOpen'}
    get isFavouriteOpen()	{return 'isFavouriteOpen'}
    get isPaymentOpen()		{return 'isPaymentOpen'}

    switchState(stateName) {
        this.setState(prevState => ({[stateName]: !prevState[stateName]}));
    }

    handleFavouriteDialogClose() {
        this.setState({editFavouriteId: 0});
    }

    handleFavouriteSettings(id) {
        this.setState({editFavouriteId: id});
    }

    render() {
        const classes = this.props.classes;
        const {menu} =  this.props;
        const {favourites, canAddNewFavourite} = menu ? menu : {favourites: [], canAddNewFavourite: 1};
        const {templates, canAddNewSearch} = menu ? menu : {templates: [], canAddNewSearch: 1};
        const {isSearchOpen, isFavouriteOpen, isPaymentOpen, state} = this;

        const {getMenu} =  this.props;
        const {editFavouriteId} = this.state;
        const favouriteToEdit = EditFavouriteDialog.getFavourite(favourites, editFavouriteId);

        return (
            <React.Fragment>
                <List component="nav">

                    <MenuItemExpandable text="Поиск" icon={<SearchIcon />} isExpanded={state[isSearchOpen]} switchState={this.switchState.bind(this, isSearchOpen)} />
                    <Collapse in={state[isSearchOpen]} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {!Array.isArray(templates) ? null : templates.map(template => 
                                <MenuItem key={template.id} text={template.name} icon={template.isSubscribed ? <EmailIcon /> : <SearchIcon />} className={classes.nestedMenuItem} textClassName={classes.nestedMenuItemText}
                                    link={{pathname: Routes.search, search: Routes.getUrlParamString(SearchPage.templateIdParamName, template.id), state: {templateName: template.name}}}
                                    settingsLink={{pathname: Routes.template, search: Routes.getUrlParamString(TemplatePage.templateIdParamName, template.id), state: {templateName: template.name}}}
                                    onDelete={null}
                                />
                            )}
                            {!canAddNewSearch ? null : (
                                <MenuItem key={TemplatePage.newTemplateId} text="Новый поиск" icon={<AddIcon />} link={Routes.newTemplate} className={classes.nestedMenuItem} textClassName={classes.nestedMenuItemText} />
                            )}
                        </List>
                    </Collapse>
                    <Divider />

                    <MenuItemExpandable text="Избранное" icon={<StarBorderIcon />} isExpanded={state[isFavouriteOpen]} switchState={this.switchState.bind(this, isFavouriteOpen)} />
                    <Collapse in={state[isFavouriteOpen]} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {!Array.isArray(favourites) ? null : favourites.map(favourite => 
                                <MenuItem key={favourite.id} text={favourite.name} icon={<StarIcon style={{ color: favourite.color }} />} className={classes.nestedMenuItem} textClassName={classes.nestedMenuItemText} link={Routes.addParamToUrl(Routes.favourite, 'id', favourite.id)} onSettingsClick={this.handleFavouriteSettings.bind(this, favourite.id)} />
                            )}
                            {!canAddNewFavourite ? null : (
                                <MenuItem key={EditFavouriteDialog.newFavouriteId} text="Новая папка" icon={<AddIcon />} className={classes.nestedMenuItem} textClassName={classes.nestedMenuItemText} onClick={this.handleFavouriteSettings.bind(this, EditFavouriteDialog.newFavouriteId)} />
                            )}
                        </List>
                    </Collapse>
                    <Divider />

                    <MenuItemExpandable text="Оплата" icon={<PaymentIcon />} isExpanded={state[isPaymentOpen]} switchState={this.switchState.bind(this, isPaymentOpen)} />
                    <Collapse in={state[isPaymentOpen]} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <MenuItem text="Мои организации" icon={<AccountBoxIcon />} link={Routes.account} className={classes.nestedMenuItem} textClassName={classes.nestedMenuItemText} />
                            <MenuItem text="Счета и оплаты" icon={<AccountBalanceWalletIcon />} link={Routes.billing} className={classes.nestedMenuItem} textClassName={classes.nestedMenuItemText} />
                            <MenuItem text="Акты услуг" icon={<DescriptionIcon />} link={Routes.acceptance} className={classes.nestedMenuItem} textClassName={classes.nestedMenuItemText} />
                        </List>
                    </Collapse>
                    <Divider />

                    <MenuItem text="Справка" icon={<HelpOutlineIcon />} link={Routes.help} />
                    <MenuItem text="Справочник ОКПД2" icon={<FormatListNumberedIcon />} link={Routes.okpd2} />
                    <MenuItem text="Выход" icon={<ExitToAppIcon />} link={Routes.logout} />
                </List>
                <EditFavouriteDialog key={'Edit_' + editFavouriteId} id={editFavouriteId} favourite={favouriteToEdit} getMenu={getMenu} onClose={this.handleFavouriteDialogClose} />
            </React.Fragment>
        );
    }
}


export default withStyles(styles, {withTheme: true})(AppMenu);

