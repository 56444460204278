import React from 'react';
import {Routes} from './Config.js';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router';

import AppNavigationLK from './AppNavigationLK.js';
import AppHeader from './AppHeader.js';
import AppDrawer from './AppDrawer.js';

import {Redirect} from "react-router-dom";
import { Paper } from '@material-ui/core';


const styles = theme => ({
    appHeader: {
        height: AppHeader.height,
        minHeight: AppHeader.height,
    },
    paper: {
        flexGrow: 1,
        padding: theme.spacing(4),
        margin: theme.spacing(1),
    },
});


class AppLK extends React.Component {
    render() {
        const classes = this.props.classes;
        const {location} = this.props;
        const {appProps} = this.props;
        const {auth, menu, getMenu, isMobileMenuOpen, handleMobileMenuToggle} = appProps;

        return (auth && auth.login) ? (
            <React.Fragment>
                <AppDrawer menu={menu} getMenu={getMenu} isMobileMenuOpen={isMobileMenuOpen} handleMobileMenuToggle={handleMobileMenuToggle} />
                <Paper component="main" className={classes.paper}>
                    <AppNavigationLK appProps={appProps} />
                </Paper>
            </React.Fragment>
        ) : (
            <Redirect
                to={{
                    pathname: Routes.login,
                    state: {from: location}
                }}
            />
        );
    }
}


export default withRouter(withStyles(styles, {withTheme: true})(AppLK));
