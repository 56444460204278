import React from 'react';
import {withRouter} from 'react-router';
import {Config, Routes} from './Config.js';
import {withStyles} from '@material-ui/core/styles';

import { Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { Link } from 'react-router-dom';
import TemplateFormPage from './TemplateFormPage.js';
import API from './API.js';
import LoadingCursor from './LoadingCursor.js';

const styles = theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});


class NewPasswordPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            warnings: [],
            notices: [],
            password: '',
            isLoading: true,
            isCodeValid: false,
            isPasswordSaved: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleChange(event) {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({[name]: value});
    }

    handleSubmit(event) {
        const {isLoading, isCodeValid, password} = this.state;
        const isSubmitEnabled = !isLoading && isCodeValid && Boolean(password.trim());
        if (isSubmitEnabled) {
            this.setState({isLoading: true});
            this.restorePassword(password);
        }
        event.preventDefault();
    }

    componentDidMount() {
        this.props.appProps.setHead('Восстановление пароля', '');
        this.checkRestorePasswordCode();
    }

    restorePassword(password) {
        const code = this.code;
        if (!code)
            return this.setState({isLoading: false, errors: ['Не указаны параметры восстановления пароля']});
        return API.fetch(this.props.appProps.setAuth,
            Config.authApiUrl,
            '1.0',
            'restorePassword',
            {
                password: password,
                code: code,
            }
        )
        .then(responseData => {
            const {errors, warnings, notices} = responseData;
            const isPasswordSaved = !errors || !errors.length;
            this.setState({errors, warnings, notices, isLoading: false, isPasswordSaved: isPasswordSaved});
        })
        .catch(() => this.setState({isLoading: false, errors: [Config.commonErrorText]}));
    }

    checkRestorePasswordCode() {
        const code = this.code;
        if (!code)
            return this.setState({isLoading: false, errors: ['Не указаны параметры восстановления пароля']});
        return API.fetch(this.props.appProps.setAuth,
            Config.authApiUrl,
            '1.0',
            'checkRestorePasswordCode',
            {
                code: code,
            }
        )
        .then(responseData => {
            const {errors, warnings, notices} = responseData;
            const isCodeValid = !errors || !errors.length;
            this.setState({errors, warnings, notices, isLoading: false, isCodeValid: isCodeValid});
        })
        .catch(() => this.setState({isLoading: false, errors: [Config.commonErrorText]}));
    }

    get code() {
        return new URLSearchParams(this.props.location.search).get(Config.codeParamName);
    }
    
    render() {
        const classes = this.props.classes;
        //const {appProps} = this.props;
        //const {auth} = appProps;
        const {isLoading, isCodeValid, isPasswordSaved, password, errors, warnings, notices} = this.state;
        let content = null;
        if (isPasswordSaved)
            content = (
                <React.Fragment>
                    <Typography>Пароль успешно изменен!</Typography>
                    <Typography paragraph />
                    <Button component={Link} to={Routes.lk} color="primary">Личный кабинет</Button>
                </React.Fragment>
            );
        else if (!isCodeValid)
            content = !isLoading ? null : <CircularProgress color="primary" />;
        else
            content = (
                <form className={classes.form} onSubmit={this.handleSubmit} method='POST'>
                    <TextField disabled={isLoading || !isCodeValid} value={password} variant="outlined" margin="normal" required fullWidth name="password" label="Новый пароль" type="password" id="password" autoComplete="new-password" onChange={this.handleChange} InputProps={{inputProps: {maxLength: 100}}} />
                    <Button type="submit" disabled={isLoading || !isCodeValid} fullWidth variant="contained" color="primary" className={classes.submit} >
                        Сохранить
                    </Button>
                </form>
            );
        return (
            <React.Fragment>
                <LoadingCursor isLoading={isLoading} />
                <TemplateFormPage
                    title='Восстановить пароль'
                    icon={<VpnKeyIcon />}
                    isDivider={isPasswordSaved || (isLoading && !isCodeValid)}
                    content={content}
                    errors={errors} warnings={warnings} notices={notices}
                />
            </React.Fragment>
        );
    }
}

export default withRouter(withStyles(styles, {withTheme: true})(NewPasswordPage));
