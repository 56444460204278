import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import { Avatar, Container, Typography } from '@material-ui/core';
import Messages from './Messages.js';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    divider: {
        marginTop: theme.spacing(3),
    },
});


class TemplateFormPage extends React.PureComponent {

    render() {
        const classes = this.props.classes;
        const {title, icon, isDivider, content, errors, warnings, notices} = this.props;
        return (
            <Container component="main" maxWidth="xs">
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        {icon}
                    </Avatar>
                    <Typography component="h1" variant="h5">{title}</Typography>
                    <Messages errors={errors} warnings={warnings} notices={notices} />
                    {isDivider ? <div className={classes.divider} /> : null}
                    {content}
                </div>
            </Container>
        );
    }
}

export default withStyles(styles, {withTheme: true})(TemplateFormPage);
