import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import AppHeader from './AppHeader.js';
import AppMenu from './AppMenu.js';
import {Config} from './Config.js';

import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';


const drawerWidth = 260;

const styles = theme => ({
    appHeader: {
        height: AppHeader.height,
        minHeight: AppHeader.height,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        background: theme.palette.background.default,
    },
    menuContainer: {
        overflow: 'auto',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(4),
    },
});


class AppDrawer extends React.Component {

    static get width()	{return drawerWidth}

    render() {
        const classes = this.props.classes;
        const {menu, getMenu, isMobileMenuOpen, handleMobileMenuToggle, width} = this.props;
        return (
            <React.Fragment>
                {/*
                <Box display={{ xs: 'block', [Config.mobileMenuBreakpoint]: 'none' }}>
                    <Drawer variant="temporary" open={isMobileMenuOpen} onClose={handleMobileMenuToggle} className={classes.drawer} classes={{paper: classes.drawerPaper}} ModalProps={{keepMounted: true}}>
                        <div className={classes.menuContainer}>
                            <AppMenu menu={menu} getMenu={getMenu} />
                        </div>
                    </Drawer>
                </Box>
                <Box display={{ 'xs': 'none', [Config.mobileMenuBreakpoint]: 'block' }}>
                    <Drawer variant={isWidthUp(Config.mobileMenuBreakpoint, width) ? "permanent" : "temporary"} open={isMobileMenuOpen} onClose={handleMobileMenuToggle} className={classes.drawer} classes={{paper: classes.drawerPaper}} ModalProps={{keepMounted: true}}>
                        {isWidthUp(Config.mobileMenuBreakpoint, width) ? <Toolbar className={classes.appHeader} /> : null}
                        <div className={classes.menuContainer}>
                            <AppMenu menu={menu} getMenu={getMenu} />
                        </div>
                    </Drawer>
                </Box>
                */}
                <Drawer variant={isWidthUp(Config.mobileMenuBreakpoint, width) ? "permanent" : "temporary"} open={isMobileMenuOpen} onClose={handleMobileMenuToggle} className={classes.drawer} classes={{paper: classes.drawerPaper}} ModalProps={{keepMounted: true}}>
                    {isWidthUp(Config.mobileMenuBreakpoint, width) ? <Toolbar className={classes.appHeader} /> : null}
                    <div className={classes.menuContainer}>
                        <AppMenu menu={menu} getMenu={getMenu} />
                    </div>
                </Drawer>
            </React.Fragment>
        );
    }
}


export default withWidth()(withStyles(styles, {withTheme: true})(AppDrawer));

