import React from 'react';
import {withRouter} from 'react-router';
import {withStyles} from '@material-ui/core/styles';
import {Routes} from '../Config.js';
import { IconButton, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';


const styles = theme => ({
    searchInput: {
        width: '100%',
        maxWidth: '300px',
    },
    box: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    }
});


class Okpd2SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            [Okpd2SearchBar.searchParamName]: props.search ? props.search : ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    static get searchParamName()	{return 'search'}

    handleChange(event) {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({[name]: value});
    }

    handleSubmit(event) {
        const isSubmitEnabled = Boolean(this.state[Okpd2SearchBar.searchParamName].trim());
        if (isSubmitEnabled)
            this.props.history.push(Routes.addParamsToUrl(Routes.okpd2Search, this.state));
        event.preventDefault();
    }

    render() {
        const classes = this.props.classes;
        const isSubmitEnabled = Boolean(this.state[Okpd2SearchBar.searchParamName].trim());
        const search = this.state[Okpd2SearchBar.searchParamName];
        return (
            <div style={{display: 'block'}} width='100%'>
                <form action={Routes.okpd2Search} onSubmit={this.handleSubmit} method='GET'>
                    <div className={classes.box}>
                        <TextField
                            type='search'
                            name={Okpd2SearchBar.searchParamName}
                            value={search}
                            onChange={this.handleChange}
                            variant='outlined'
                            label='Поиск кодов'
                            margin='normal'
                            className={classes.searchInput}
                            InputProps={{
                                maxLength: 250,
                            }}
                        />
                        <IconButton type='submit' disabled={!isSubmitEnabled}>
                            <SearchIcon fontSize='large' />
                        </IconButton>
                    </div>
                </form>
            </div>
        );
    }
}


export default withRouter(withStyles(styles, {withTheme: true})(Okpd2SearchBar));
