import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import { Button, Link, Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { Routes } from './Config';
import { Link as RouterLink } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import NewInvoicePage from './NewInvoicePage';


const styles = theme => ({
    icon: {
        marginRight: theme.spacing(1),
    },
    documentLink: {
        marginLeft: theme.spacing(2),
        alignItems: 'center',
    },
    newButton: {
        margin: theme.spacing(2, 1, 1, 2),
    }
});


class OrgDetails extends React.PureComponent {

    render() {
        const classes = this.props.classes;
        const {organization, addNewInvoiceLink} = this.props;
        return (
            <div>
                <Typography variant='subtitle1'>Полное наименование:</Typography>
                <Typography paragraph>{organization.fullName}</Typography>
                <Typography variant='subtitle1'>Кратное наименование:</Typography>
                <Typography paragraph>{organization.shortName}</Typography>
                <Typography paragraph>ИНН: {organization.inn}, КПП: {organization.kpp}</Typography>
                <Typography variant='subtitle1'>Юридический адрес:</Typography>
                <Typography paragraph>{organization.officialAddress}</Typography>
                <Typography variant='subtitle1'>Почтовый адрес:</Typography>
                <Typography paragraph>{organization.postalAddress}</Typography>
                <Typography variant='subtitle1'>Расчетный счет:</Typography>
                <Typography paragraph>{organization.bankAccount}</Typography>
                <Typography variant='subtitle1'>Корреспондентский счет:</Typography>
                <Typography paragraph>{organization.bankCorrAccount}</Typography>
                <Typography variant='subtitle1'>БИК:</Typography>
                <Typography paragraph>{organization.bankBic}</Typography>
                <Typography variant='subtitle1'>Банк:</Typography>
                <Typography paragraph>{organization.bankName}</Typography>
                <div display='block'>
                    <Link href={organization.contractUrl} download='' className={classes.documentLink} >
                        <DescriptionIcon color='primary' className={classes.icon} />
                        <Typography display='inline'>{'Договор № ' + organization.contractNo + ' от ' + organization.contractDateTxt}</Typography>
                    </Link>
                </div>
                {!addNewInvoiceLink ? null : (
                    <Button size='small' variant='contained' color='primary' component={RouterLink} to={Routes.addParamToUrl(Routes.newInvoice, NewInvoicePage.orgIdParamName, organization.id)} startIcon={<AddIcon />} className={classes.newButton}>
                        Счёт на оплату
                    </Button>
                )}
            </div>
        );
    }
}


export default withStyles(styles, {withTheme: true})(OrgDetails);

