import React from 'react';
import {Config} from './Config.js';
import {withStyles} from '@material-ui/core/styles';
import './css/style_index.css';

import { CircularProgress, Paper } from '@material-ui/core';
import Messages from './Messages.js';
import API from './API.js';
import LoadingCursor from './LoadingCursor.js';

const styles = theme => ({
    paper: {
        flexGrow: 1,
        padding: theme.spacing(3),
        margin: theme.spacing(1),
    },
    longBlock: {
        minHeight: '4000px',
    },
});

  
class ConfidentialPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            warnings: [],
            notices: [],
            pageHtml: null,
        };
    }

    componentDidMount() {
        this.props.appProps.setHead('Политика конфиденциальности', 'Политика конфиденциальности сервиса ' + Config.serviceName);
        //this.setState({isLoading: true}); //, errors: [], warnings: [], notices: []});
        this.loadPageHtml();
    }
    
    loadPageHtml() {
        return API.fetch(this.props.appProps.setAuth,
            Config.dataPublicApiUrl,
            '1.0',
            'getConfidentialPage',
            {}
        )
        .then(responseData => {
            const {errors, warnings, notices, content} = responseData;
            const html = (content && content.html && content.html.startsWith('<!--ConfidentialPage-->')) ? content.html : null;
            this.setState({errors, warnings, notices, pageHtml: html});
        })
        .catch(() => this.setState({errors: [Config.commonErrorText]}));
    }
    
    render() {
        const classes = this.props.classes;
        const {pageHtml} = this.state;
        const {errors, warnings, notices} = this.state;
        const isLoading = !pageHtml && (!errors || !errors.length);
        const pageData = pageHtml ? (
            <div dangerouslySetInnerHTML={{__html: pageHtml}} style={{textAlign: 'left'}} />
        ) : (
            <div className={classes.longBlock}>
                {(!errors || !errors.length) ? <CircularProgress color="primary" /> : null}
            </div>
        );
        return (
            <Paper component="main" className={classes.paper}>
                <LoadingCursor isLoading={isLoading} />
                <Messages errors={errors} warnings={warnings} notices={notices} />
                {pageData}
            </Paper>
        );
    }
}

export default withStyles(styles, {withTheme: true})(ConfidentialPage);
