import React from 'react';
import {Config, Routes} from './Config.js';
import {withStyles} from '@material-ui/core/styles';

import { Link, Box, Button, CircularProgress, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AddIcon from '@material-ui/icons/Add';
import Messages from './Messages.js';
import API from './API.js';
import { Alert } from '@material-ui/lab';
import { Link as RouterLink } from 'react-router-dom';
import LoadingCursor from './LoadingCursor.js';

const styles = theme => ({
    longBlock: {
        minHeight: '600px',
    },
    table: {
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    newInvoiceButton: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
    },
    newButton: {
        margin: theme.spacing(1),
    }
});

  
class InvoicesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            warnings: [],
            notices: [],
            invoices: null,
            payments: {},
            //isLoading: true, // Фактически идет загрузка, когда invoices=null и при этом нет ошибок. Если есть счета или ошибки, то не грузимся
            isSortAsc: false,
            orderBy: 'docDateYMD',
            page: 0,
        };
        this.handleRequestSort = this.handleRequestSort.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleLoadPayments = this.handleLoadPayments.bind(this);
    }

    componentDidMount() {
        this.props.appProps.setHead('Счета и оплаты', '');
        this.loadInvoices();
    }
    
    loadInvoices() {
        return API.fetch(this.props.appProps.setAuth,
            Config.billingApiUrl,
            '1.0',
            'getInvoices',
            {}
        )
        .then(responseData => {
            const {isSortAsc, orderBy} = this.state;
            let {errors, warnings, notices, content} = responseData;
            content = Array.isArray(content) ? content : [];
            content = content.sort(getComparator(isSortAsc, orderBy));
            if (!content.length && (!errors || !errors.length)) {
                notices = Array.isArray(notices) ? notices : [];
                notices.push('Счета отсутствуют');
            }
            this.setState({errors, warnings, notices, invoices: content});
        })
        .catch(() => this.setState({errors: [Config.commonErrorText]}));
    }

    handleChangePage(event, newPage) {
        this.setState({page: newPage});
    }
    
    handleRequestSort() { // (event, property)
        this.setState(prevState => ({
            isSortAsc: !prevState.isSortAsc,
            invoices: prevState.invoices.slice().sort(getComparator(!prevState.isSortAsc, prevState.orderBy)),
            page: 0,
        }));
    }

    handleLoadPayments(invoiceId, invoicePayments) {
        this.setState(prevState => ({payments: Object.assign(prevState.payments, {[invoiceId]: invoicePayments})}));
    }

    render() {
        const classes = this.props.classes;
        const {appProps} = this.props;
        const {invoices, payments, isSortAsc, orderBy, page} = this.state;
        const {errors, warnings, notices} = this.state;
        const isLoading = !invoices && (!errors || !errors.length);
        const pageData = invoices ? (
            <EnhancedTable appProps={appProps} rows={invoices} payments={payments} dense={true} isSortAsc={isSortAsc} orderBy={orderBy} page={page} onRequestSort={this.handleRequestSort} onChangePage={this.handleChangePage} onLoadPayments={this.handleLoadPayments} classes={classes} />
        ) : (
            <div className={classes.longBlock}>
                {(!errors || !errors.length) ? <CircularProgress color="primary" /> : null}
            </div>
        );
        return (
            <React.Fragment>
                <LoadingCursor isLoading={isLoading} />
                <Typography variant='h4' gutterBottom >Счета и оплаты</Typography>
                <Messages errors={errors} warnings={warnings} notices={notices} />
                <Button variant='contained' color='primary' component={RouterLink} to={Routes.newInvoice} startIcon={<AddIcon />} className={classes.newButton}>
                    Новый счёт
                </Button>
                {pageData}
            </React.Fragment>
        );
    }
}

export default withStyles(styles, {withTheme: true})(InvoicesPage);

const headCells = [
    { id: 'payments',		padding: 'checkbox',align: 'center',	dataAlign: 'center',	label: null },
    { id: 'docNo',			padding: 'default',	align: 'center',	dataAlign: 'right',		label: '№' },
    { id: 'docDate',		padding: 'default',	align: 'center',	dataAlign: 'right',		label: 'Дата' },
    { id: 'total',			padding: 'default',	align: 'right',		dataAlign: 'right',		label: 'Сумма, руб.' },
    { id: 'servicePeriod',	padding: 'default',	align: 'center',	dataAlign: 'center',	label: 'Период' },
    { id: 'organization',	padding: 'default',	align: 'center',	dataAlign: 'center',	label: 'Компания' },
    { id: 'status',			padding: 'default',	align: 'left',		dataAlign: 'center',	label: 'Статус' },
    { id: 'url',			padding: 'none',	align: 'center',	dataAlign: 'center',	label: 'Скачать' },
];

const paymentsHeadCells = [
    { id: 'docNo',			padding: 'default',	align: 'center',	dataAlign: 'right',		label: '№' },
    { id: 'docDate',		padding: 'default',	align: 'center',	dataAlign: 'right',		label: 'Дата документа' },
    { id: 'valueDate',		padding: 'default',	align: 'center',	dataAlign: 'right',		label: 'Дата зачисления' },
    { id: 'total',			padding: 'default',	align: 'right',		dataAlign: 'right',		label: 'Сумма, руб.' },
    { id: 'organization',	padding: 'default',	align: 'center',	dataAlign: 'center',	label: 'Плательщик' },
];

function comparator(a, b, orderBy) {
    if (a[orderBy] < b[orderBy]) return -1;
    if (a[orderBy] > b[orderBy]) return 1;
    //if (orderBy !== 'id') return comparator(a, b, 'id')
    if (orderBy !== 'docNo') return comparator(a, b, 'docNo')
    return 0;
}

function getComparator(isSortAsc, orderBy) {
    return isSortAsc
        ? (a, b) => comparator(a, b, orderBy)
        : (a, b) => -comparator(a, b, orderBy);
}

class EnhancedTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 25,
        };
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.emptyRows = this.emptyRows.bind(this);
    }

    handleChangeRowsPerPage(event) {
        const {onChangePage} = this.props;
        this.setState({
            pageSize: parseInt(event.target.value, 10)
        });
        onChangePage(null, 0);
    }

    emptyRows() {
        const {pageSize} = this.state;
        const {rows, page} = this.props;
        return pageSize - Math.min(pageSize, rows.length - page * pageSize);
    }

    render() {
        const classes = this.props.classes;
        const {appProps} = this.props;
        const {pageSize} = this.state;
        //const {orderBy} = this.props;
        const {rows, payments, dense, isSortAsc, page, onRequestSort, onChangePage, onLoadPayments} = this.props;
        return (
            <div>
                <TableContainer>
                    <Table
                        className={classes.table}
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            classes={classes}
                            headCells={headCells}
                            isSortAsc={isSortAsc}
                            onRequestSort={onRequestSort}
                        />
                        <TableBody>
                            {rows
                            .slice(page * pageSize, page * pageSize + pageSize)
                            .map(row => (
                                <EnhancedTableRow key={row.id} appProps={appProps} row={row} payments={payments[row.id]} onLoadPayments={onLoadPayments} classes={classes} />
                            ))}
                            {/*this.emptyRows() > 0 && (
                                <TableRow style={{ height: (dense ? 42 : 62) * this.emptyRows() }}>
                                <TableCell colSpan={8} />
                                </TableRow>
                            )*/}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={pageSize}
                    page={page}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    labelRowsPerPage='Записей на странице'
                    backIconButtonText='Предыдущая'
                    nextIconButtonText='Следующая'
                />
            </div>
        );
    }
}

class EnhancedTableRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isPaymentsOpen: false,
            errors: [],
            warnings: [],
            notices: [],
        };
        this.handleOpenPayments = this.handleOpenPayments.bind(this);
    }

    handleOpenPayments() {
        const {payments} = this.props;
        this.setState(prevState => ({isPaymentsOpen: !prevState.isPaymentsOpen}));
        if (!payments)
            this.loadPayments();
    }

    loadPayments() {
        const {row, onLoadPayments} = this.props;
        return API.fetch(this.props.appProps.setAuth,
            Config.billingApiUrl,
            '1.0',
            'getPaymentsInvoices',
            {
                invoiceId: row.id,
            }
        )
        .then(responseData => {
            let {errors, warnings, notices, content} = responseData;
            content = Array.isArray(content) ? content : [];
            this.setState({errors, warnings, notices});
            onLoadPayments(row.id, content);
        })
        .catch(() => this.setState({errors: [Config.commonErrorText]}));
    }

    render() {
        const classes = this.props.classes;
        const {row, payments} = this.props;
        const {isPaymentsOpen} = this.state;
        const {errors, warnings, notices} = this.state;
        let severity = row.isPaid ? 'success' : (row.isValid ? 'info' : 'error');
        let status =   row.isPaid ? 'Оплачен' : (row.isValid ? 'Ожидает оплату' : 'Просрочен');
        const paymentsRow = !row.isPaid ? null : (
            <PaymentsRow payments={payments} isVisible={isPaymentsOpen} errors={errors} warnings={warnings} notices={notices} classes={classes} />
        );
        return (
            <React.Fragment>
                <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                >
                    <TableCell align={headCells[0].align} padding={headCells[0].padding}>
                        {!row.isPaid ? null :
                            <IconButton size="small" onClick={this.handleOpenPayments}>
                                {isPaymentsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        }
                    </TableCell>
                    <TableCell align={headCells[1].align} padding={headCells[1].padding}>{row.docNo}</TableCell>
                    <TableCell align={headCells[2].align} padding={headCells[2].padding}>{row.docDate}</TableCell>
                    <TableCell align={headCells[3].align} padding={headCells[3].padding}>{row.total}</TableCell>
                    <TableCell align={headCells[4].align} padding={headCells[4].padding}>{row.tariff.paidPeriodRu}</TableCell>
                    <TableCell align={headCells[5].align} padding={headCells[5].padding}>{row.contract.organization.shortName}</TableCell>
                    <TableCell align={headCells[6].align} padding={headCells[6].padding}>
                        <Alert severity={severity} icon={false} >{status}</Alert>
                    </TableCell>
                    <TableCell align={headCells[7].align} padding={headCells[7].padding}>
                        <Link href={row.url} download=''>
                            <DescriptionIcon color='primary' />
                        </Link>
                    </TableCell>
                </TableRow>
                {paymentsRow}
            </React.Fragment>
        );
    }
}

class EnhancedTableHead extends React.Component {
    constructor(props) {
        super(props);
        this.createSortHandler = this.createSortHandler.bind(this);
    }

    createSortHandler(property) {
        const {onRequestSort} = this.props;
        if (!onRequestSort) return () => {}; // Empty func if onRequestSort is null
        return (event) => {onRequestSort(event, property)};
    }

    render() {
        const {headCells, isSortAsc, noSort} = this.props;

        return (
            <TableHead>
                <TableRow>
                    {headCells.map(headCell => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={headCell.padding}
                            sortDirection={headCell.id === 'docDate' ? (isSortAsc ? 'asc' : 'desc') : false}
                        >
                            {((headCell.id !== 'docDate') || noSort) ? headCell.label :
                                <TableSortLabel
                                    active={headCell.id === 'docDate'}
                                    direction={isSortAsc ? 'asc' : 'desc'}
                                    hideSortIcon={headCell.id !== 'docDate'}
                                    onClick={headCell.id === 'docDate' ? this.createSortHandler('docDateYMD') : null}
                                >
                                    {headCell.label}
                                </TableSortLabel>
                            }
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
}

class PaymentsRow extends React.Component {
    render() {
        const classes = this.props.classes;
        const {payments, isVisible} = this.props;
        const {errors, warnings, notices} = this.props;
        const pageData = payments ? (
            <Table size="small">
                <EnhancedTableHead
                    classes={classes}
                    headCells={paymentsHeadCells}
                    noSort={true}
                    isSortAsc={true}
                    onRequestSort={null}
                />
                <TableBody>
                    {payments.map(payment => (
                        <TableRow key={payment.id}>
                            <TableCell align={headCells[0].align} padding={headCells[1].padding}>{payment.docNo}</TableCell>
                            <TableCell align={headCells[1].align} padding={headCells[2].padding}>{payment.docDate}</TableCell>
                            <TableCell align={headCells[2].align} padding={headCells[3].padding}>{payment.valueDate}</TableCell>
                            <TableCell align={headCells[3].align} padding={headCells[4].padding}>{payment.total}</TableCell>
                            <TableCell align={headCells[4].align} padding={headCells[5].padding}>{payment.payerName}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        ) : (
            <div>
                {(!errors || !errors.length) ? <CircularProgress color="primary" /> : null}
            </div>
        );
        return (
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} />
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={isVisible} timeout="auto" unmountOnExit>
                        <Box boxShadow={2} padding={2} marginLeft={1} marginRight={1} marginTop={1} marginBottom={2}>
                            <Typography variant="h6" gutterBottom component="div">Платежные поручения</Typography>
                            <Messages errors={errors} warnings={warnings} notices={notices} />
                            {pageData}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        );
    }
}
