import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import { Button, Grid, TextField, Typography } from '@material-ui/core';
import Messages from './Messages.js';
import API from './API.js';
import { Config } from './Config.js';
import LoadingCursor from './LoadingCursor.js';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    divider: {
        marginTop: theme.spacing(3),
    },
    submit: {
        marginRight: theme.spacing(1),
    },
});


class AddOrgForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            warnings: [],
            notices: [],
            isLoading: false,
            name: '',
            tel: '',
            fullName: '',
            shortName: '',
            inn: '',
            kpp: '',
            officialAddress: '',
            postalAddress: '',
            bankAccount: '',
            bankBic: '',
            bankCorrAccount: '',
            bankName: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({[name]: value});
    }

    handleSubmit(event) {
        const {isLoading, name, tel, fullName, shortName, inn, kpp, officialAddress, postalAddress, bankAccount, bankBic, bankCorrAccount, bankName} = this.state;
        const isSubmitEnabled = !isLoading && Boolean(name.trim()) && Boolean(tel.trim()) && Boolean(fullName.trim()) && Boolean(shortName.trim()) && Boolean(inn.trim()) && Boolean(officialAddress.trim()) && Boolean(bankAccount.trim()) && Boolean(bankBic.trim()) && Boolean(bankName.trim());
        if (isSubmitEnabled) {
            this.setState({isLoading: true}); //, errors: [], warnings: [], notices: []});
            this.addOrg(name, tel, fullName, shortName, inn, kpp, officialAddress, postalAddress, bankAccount, bankBic, bankCorrAccount, bankName);
        }
        event.preventDefault();
    }

    addOrg(name, tel, fullName, shortName, inn, kpp, officialAddress, postalAddress, bankAccount, bankBic, bankCorrAccount, bankName) {
        return API.fetch(this.props.appProps.setAuth,
            Config.billingApiUrl,
            '1.0',
            'addOrganization',
            {name, tel, fullName, shortName, inn, kpp, officialAddress, postalAddress, bankAccount, bankBic, bankCorrAccount, bankName}
        )
        .then(responseData => {
            const {onAddOrg} = this.props;
            const {errors, warnings, notices, content} = responseData;
            if (!errors || !errors.length) {
                this.setState({errors, warnings, notices, isLoading: false,
                    name: '', tel: '', fullName: '', shortName: '', inn: '', kpp: '', officialAddress: '', postalAddress: '', bankAccount: '', bankBic: '', bankCorrAccount: '', bankName: '',});
                onAddOrg(content);
            } else
                this.setState({errors, warnings, notices, isLoading: false});
        })
        .catch(() => this.setState({isLoading: false, errors: [Config.commonErrorText]}));
    }

    render() {
        const classes = this.props.classes;
        const {firstInputRef, submitText, backText, onBack} = this.props;
        const {errors, warnings, notices} = this.state;
        const {isLoading, name, tel, fullName, shortName, inn, kpp, officialAddress, postalAddress, bankAccount, bankBic, bankCorrAccount, bankName} = this.state;
        return (
            <div>
                <LoadingCursor isLoading={isLoading} />
                <Messages errors={errors} warnings={warnings} notices={notices} />
                <form className={classes.form} onSubmit={this.handleSubmit} method='POST'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={8}><TextField inputRef={firstInputRef} disabled={isLoading} value={name} variant="outlined" margin="normal" required fullWidth name="name" label="Контактное лицо" type="text" id="name" autoComplete="name" autoFocus onChange={this.handleChange} InputProps={{inputProps: {maxLength: 250}}} /></Grid>
                        <Grid item xs={12} md={4}><TextField disabled={isLoading} value={tel} variant="outlined" margin="normal" required fullWidth name="tel" label="Телефон" type="tel" id="tel" autoComplete="tel" onChange={this.handleChange} InputProps={{inputProps: {maxLength: 50, inputMode: 'tel'}}} /></Grid>
                        <Grid item xs={12}><TextField disabled={isLoading} value={fullName} variant="outlined" margin="normal" required fullWidth name="fullName" label="Полное наименование" type="text" id="fullName" autoComplete="organization" onChange={this.handleChange} InputProps={{inputProps: {maxLength: 500}}} /></Grid>
                        <Grid item xs={12} md={6}><TextField disabled={isLoading} value={shortName} variant="outlined" margin="normal" required fullWidth name="shortName" label="Краткое наименование" type="text" id="shortName" autoComplete="organization" onChange={this.handleChange} InputProps={{inputProps: {maxLength: 500}}} /></Grid>
                        <Grid item xs={12} sm={6} md={3}><TextField disabled={isLoading} value={inn} variant="outlined" margin="normal" required fullWidth name="inn" label="ИНН" type="text" id="inn" autoComplete="on" onChange={this.handleChange} InputProps={{inputProps: {maxLength: 12, minLength: 10, inputMode: 'numeric', pattern: '\\d{10}|\\d{12}'}}} /></Grid>
                        <Grid item xs={12} sm={6} md={3}><TextField disabled={isLoading} value={kpp} variant="outlined" margin="normal" fullWidth name="kpp" label="КПП" type="text" id="kpp" autoComplete="on" onChange={this.handleChange} InputProps={{inputProps: {maxLength: 9, minLength: 9, inputMode: 'numeric', pattern: '\\d{9}'}}} /></Grid>
                        <Grid item xs={12}><TextField disabled={isLoading} value={officialAddress} variant="outlined" margin="normal" required fullWidth name="officialAddress" label="Юридический адрес" type="text" id="officialAddress" autoComplete="street-address" onChange={this.handleChange} InputProps={{inputProps: {maxLength: 500}}} /></Grid>
                        <Grid item xs={12}><TextField disabled={isLoading} value={postalAddress} variant="outlined" margin="normal" fullWidth name="postalAddress" label="Почтовый адрес" type="text" id="postalAddress" autoComplete="street-address" onChange={this.handleChange} InputProps={{inputProps: {maxLength: 500}}} /></Grid>
                        <Grid item xs={12} md={4}><TextField disabled={isLoading} value={bankAccount} variant="outlined" margin="normal" required fullWidth name="bankAccount" label="Расчетный счет" type="text" id="bankAccount" autoComplete="on" onChange={this.handleChange} InputProps={{inputProps: {maxLength: 20, minLength: 20, inputMode: 'numeric', pattern: '\\d{20}'}}} /></Grid>
                        <Grid item xs={12} md={4}><TextField disabled={isLoading} value={bankCorrAccount} variant="outlined" margin="normal" fullWidth name="bankCorrAccount" label="Корреспондентский счет" type="text" id="bankCorrAccount" autoComplete="on" onChange={this.handleChange} InputProps={{inputProps: {maxLength: 20, minLength: 20, inputMode: 'numeric', pattern: '\\d{20}'}}} /></Grid>
                        <Grid item xs={12} md={4}><TextField disabled={isLoading} value={bankBic} variant="outlined" margin="normal" required fullWidth name="bankBic" label="БИК" type="text" id="bankBic" autoComplete="on" onChange={this.handleChange} InputProps={{inputProps: {maxLength: 9, minLength: 9, inputMode: 'numeric', pattern: '\\d{9}'}}} /></Grid>
                        <Grid item xs={12}><TextField disabled={isLoading} value={bankName} variant="outlined" margin="normal" required fullWidth name="bankName" label="Название банка" type="text" id="bankName" autoComplete="on" onChange={this.handleChange} InputProps={{inputProps: {maxLength: 250}}} /></Grid>
                    </Grid>
                    <Typography paragraph />
                    {(!backText || !onBack) ? null :
                        <Button onClick={onBack} disabled={isLoading} variant="contained" color="primary" className={classes.submit} >
                            {backText}
                        </Button>
                    }
                    <Button type="submit" disabled={isLoading} variant="contained" color="primary" className={classes.submit} >
                        {submitText}
                    </Button>
                </form>
            </div>
        );
    }
}

export default withStyles(styles, {withTheme: true})(AddOrgForm);
