import React from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import {Config, Routes} from './Config.js';

import NotFoundPage from './NotFoundPage.js';
import SearchPage from './SearchPage.js';
import TemplatePage from './TemplatePage.js';
import Okpd2SearchBar from './okpd2/Okpd2SearchBar.js';


class AppNavigationOld extends React.Component {
    newURLSearchParams(obj) {
        let p = new URLSearchParams();
        for (let name in obj)
            p.set(name, obj[name]);
        return p;
    }

    addURLSearchParams(p, obj) {
        for (let name in obj)
            p.set(name, obj[name]);
        return p;
    }

    render() {
        const {appProps, location} = this.props;
        const {pathname, search, hash} = location;
        const params = search ? (new URLSearchParams(search)) : (new URLSearchParams());

        if (pathname === Routes.base + '/filter.html') {
            const templateId = params && params.get('s');
            if (params)
                params.delete('s');
            if (templateId)
                return <Redirect to={{pathname: Routes.template, search: this.addURLSearchParams(params, {[TemplatePage.templateIdParamName]: templateId}).toString(), hash}} />;
            return <Redirect to={{pathname: Routes.lk, search, hash}} />;
        }
        if (pathname === Routes.base + '/search_result.html') {
            const templateId = params && params.get('s');
            const date = params && params.get('d');
            if (params) {
                params.delete('s');
                params.delete('d');
            }
            if (templateId && date)
                return <Redirect to={{pathname: Routes.search, search: this.addURLSearchParams(params, {[SearchPage.templateIdParamName]: templateId, [SearchPage.dateParamName]: date}).toString(), hash}} />;
            return <Redirect to={{pathname: Routes.search, search, hash}} />;
        }
        if (pathname === Routes.base + '/okpd2_search.html') {
            const request = params && params.get('search');
            if (params)
                params.delete('search');
            if (request)
                return <Redirect to={{pathname: Routes.okpd2Search, search: this.addURLSearchParams(params, {[Okpd2SearchBar.searchParamName]: request}).toString(), hash}} />;
            return <Redirect to={{pathname: Routes.okpd2, search, hash}} />;
        }
        if (pathname === Routes.base + '/confirm_email.html') {
            const email = params && params.get('email');
            const code = params && params.get('code');
            if (params) {
                params.delete('email');
                params.delete('code');
            }
            if (email && code)
                return <Redirect to={{pathname: Routes.confirm, search: this.addURLSearchParams(params, {[Config.emailParamName]: email, [Config.codeParamName]: code}).toString(), hash}} />;
            return <Redirect to={{pathname: Routes.confirm, search, hash}} />;
        }
        if (pathname === Routes.base + '/unsubscribe.html') {
            const code = params && params.get('code');
            if (params)
                params.delete('code');
            if (code)
                return <Redirect to={{pathname: Routes.unsubscribe, search: this.addURLSearchParams(params, {[Config.codeParamName]: code}).toString(), hash}} />;
            return <Redirect to={{pathname: Routes.unsubscribe, search, hash}} />;
        }
        if (pathname === Routes.base + '/restore_new_password.html') {
            const code = params && params.get('code');
            if (params)
                params.delete('code');
            if (code)
                return <Redirect to={{pathname: Routes.newPassword, search: this.addURLSearchParams(params, {[Config.codeParamName]: code}).toString(), hash}} />;
            return <Redirect to={{pathname: Routes.newPassword, search, hash}} />;
        }

        if (pathname === Routes.base + '/search_test.html')
            return <Redirect to={{pathname: Routes.lk, search, hash}} />;
        if (pathname === Routes.base + '/tariff.html')
            return <Redirect to={{pathname: Routes.newInvoice, search, hash}} />;
        if (pathname === Routes.base + '/help.html')
            return <Redirect to={{pathname: Routes.help, search, hash}} />;
        if (pathname === Routes.base + '/documents_signed.html')
            return <Redirect to={{pathname: Routes.acceptance, search, hash}} />;
        if (pathname === Routes.base + '/okpd2.html')
            return <Redirect to={{pathname: Routes.okpd2, search, hash}} />;

        if (pathname === Routes.base + '/login.html')
            return <Redirect to={{pathname: Routes.login, search, hash}} />;
        if (pathname === Routes.base + '/logout.html')
            return <Redirect to={{pathname: Routes.logout, search, hash}} />;
        if (pathname === Routes.base + '/register.html')
            return <Redirect to={{pathname: Routes.register, search, hash}} />;
        if (pathname === Routes.base + '/restore.html')
            return <Redirect to={{pathname: Routes.restore, search, hash}} />;
        if (pathname === Routes.base + '/register_ended.html')
            return <Redirect to={{pathname: Routes.login, search, hash}} />;
        if (pathname === Routes.base + '/register_ok.html')
            return <Redirect to={{pathname: Routes.login, search, hash}} />;
        if (pathname === Routes.base + '/confidential.html')
            return <Redirect to={{pathname: Routes.confidential, search, hash}} />;

        return <NotFoundPage appProps={appProps} />;
    }
}

export default withRouter(AppNavigationOld);
