import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { Button, Card, CardContent, Divider, Grid, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EmailIcon from '@material-ui/icons/Email';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { Routes } from './Config';
import TemplatePage from './TemplatePage';
//import SettingsIcon from '@material-ui/icons/Settings';
import SearchPage from './SearchPage';
import AddIcon from '@material-ui/icons/Add';
import StarIcon from '@material-ui/icons/Star';
import {EditFavouriteDialog} from './FavouriteComponents.js';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';


const styles = theme => ({
    longBlock: {
        minHeight: '600px',
    },
    centerBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    pageTitle: {
        marginBottom: theme.spacing(4),
    },
    subscription: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'top',
        marginBottom: theme.spacing(2),
    },
    button: {
        marginBottom: theme.spacing(1),
    }
});

  
class LKPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editFavouriteId: 0,
        };
        this.handleFavouriteSettings = this.handleFavouriteSettings.bind(this);
        this.handleFavouriteDialogClose = this.handleFavouriteDialogClose.bind(this);
    }

    componentDidMount() {
        this.props.appProps.setHead('Настройки поиска', '');
    }

    handleFavouriteDialogClose() {
        this.setState({editFavouriteId: 0});
    }

    handleFavouriteSettings(id) {
        this.setState({editFavouriteId: id});
    }

    render() {
        const classes = this.props.classes;
        const {appProps} = this.props;
        const templates = appProps.menu ? appProps.menu.templates : null;
        const canAddNewSearch = appProps.menu ? appProps.menu.canAddNewSearch : null;
        const favourites = appProps.menu ? appProps.menu.favourites : null;
        const canAddNewFavourite = appProps.menu ? appProps.menu.canAddNewFavourite : null;

        const {getMenu} = appProps;
        const {editFavouriteId} = this.state;
        const favouriteToEdit = EditFavouriteDialog.getFavourite(favourites, editFavouriteId);

        return (
            <div className={classes.longBlock}>
                <Typography align='center' variant='h5' className={classes.pageTitle} >Настройки поиска</Typography>
                <Grid container spacing={5}>
                    {!Array.isArray(templates) ? null : templates.map(template => 
                        <Grid item xs={12} sm={6} md={4} lg={3} key={template.id}>
                            <Template template={template} />
                        </Grid>
                    )}
                    {!canAddNewSearch ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Card raised>
                                <CardContent>
                                    <div className={classes.centerBox}>
                                        <AddCircleIcon color='secondary' style={{fontSize: 40}} />
                                        <Typography align='center' variant='h6' paragraph >Новый поиск</Typography>
                                        <Button variant='text' color='primary' size="small" className={classes.button} fullWidth component={Link} to={Routes.newTemplate} startIcon={<AddIcon />} >Создать</Button>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                </Grid>
                <Divider variant="fullWidth" component='div' style={{marginTop: '32px', marginBottom: '32px'}} />
                <Typography align='center' variant='h5' className={classes.pageTitle} >Избранное</Typography>
                <Grid container spacing={5}>
                    {!Array.isArray(favourites) ? null : favourites.map(favourite => 
                        <Grid item xs={12} sm={6} md={4} lg={3} key={favourite.id}>
                            <Favourite favourite={favourite} onSettings={this.handleFavouriteSettings.bind(this, favourite.id)} />
                        </Grid>
                    )}
                    {!canAddNewFavourite ? null : (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Card raised>
                                <CardContent>
                                    <div className={classes.centerBox}>
                                        <AddCircleIcon color='secondary' style={{fontSize: 40}} />
                                        <Typography align='center' variant='h6' paragraph >Новая папка</Typography>
                                        <Button variant='text' color='primary' size="small" className={classes.button} fullWidth onClick={this.handleFavouriteSettings.bind(this, EditFavouriteDialog.newFavouriteId)} startIcon={<AddIcon />} >Создать</Button>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                </Grid>
                <EditFavouriteDialog key={'Edit_' + editFavouriteId} id={editFavouriteId} favourite={favouriteToEdit} getMenu={getMenu} onClose={this.handleFavouriteDialogClose} />
            </div>
        );
    }
}

export default withStyles(styles, {withTheme: true})(LKPage);


class Template_ extends React.PureComponent {
    render() {
        const classes = this.props.classes;
        const {template} = this.props;
        return (
            <Card raised>
                <CardContent>
                    <div className={classes.centerBox}>
                        {template.isSubscribed ? <EmailIcon color='primary' style={{fontSize: 40}} /> : <SearchIcon color='primary' style={{fontSize: 40}} />}
                        <Typography align='center' variant='h6' paragraph >{template.name}</Typography>
                        <div display='inline' className={classes.subscription}>
                            {template.isSubscribed ? <CheckIcon fontSize='small' color='secondary' className={classes.icon} /> : <ClearIcon fontSize='small' color='error' className={classes.icon} />}
                            <Typography align='left' display='inline' >{template.isSubscribed ? 'Рассылка включена' : 'Рассылка отключена'}</Typography>
                        </div>
                        <Button variant='text' color='primary' size="small" className={classes.button} fullWidth component={Link} to={{pathname: Routes.template, search: Routes.getUrlParamString(TemplatePage.templateIdParamName, template.id), state: {templateName: template.name}}} >Редактировать</Button>
                        <Button variant='text' color='primary' size="small" className={classes.button} fullWidth component={Link} to={{pathname: Routes.search, search: Routes.getUrlParamString(SearchPage.templateIdParamName, template.id), state: {templateName: template.name}}} startIcon={<SearchIcon />} >Найти</Button>
                    </div>
                </CardContent>
            </Card>
        );
    }
}
const Template = withStyles(styles, {withTheme: true})(Template_);


class Favourite_ extends React.PureComponent {
    render() {
        const classes = this.props.classes;
        const {favourite, onSettings} = this.props;
        return (
            <Card raised>
                <CardContent>
                    <div className={classes.centerBox}>
                        <StarIcon style={{color: favourite.color, fontSize: 40}} />
                        <Typography align='center' variant='h6' paragraph >{favourite.name}</Typography>
                        <Button variant='text' color='primary' size="small" className={classes.button} fullWidth onClick={onSettings} >Редактировать</Button>
                        <Button variant='text' color='primary' size="small" className={classes.button} fullWidth component={Link} to={Routes.addParamToUrl(Routes.favourite, 'id', favourite.id)} startIcon={<FolderOpenIcon />} >Открыть</Button>
                    </div>
                </CardContent>
            </Card>
        );
    }
}
const Favourite = withStyles(styles, {withTheme: true})(Favourite_);
