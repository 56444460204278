import React from 'react';
import {Switch, Route} from 'react-router-dom';
import ActsPage from './ActsPage.js';
import {Routes} from './Config.js';
import FavouritePage from './FavouritePage.js';
import HelpPage from './HelpPage.js';
import InvoicesPage from './InvoicesPage.js';
import LKPage from './LKPage.js';
import NewInvoicePage from './NewInvoicePage.js';
import NotFoundPage from './NotFoundPage.js';
import Okpd2Page from './okpd2/Okpd2Page.js';
import OrganizationsPage from './OrganizationsPage.js';
import SearchPage from './SearchPage.js';
import TemplatePage from './TemplatePage.js';


class AppNavigationLK extends React.Component {
    render() {
        const {appProps} = this.props;
        return (
            <Switch>
                <Route path={Routes.okpd2}>				<Okpd2Page appProps={appProps} />		</Route>
                <Route path={Routes.help}>				<HelpPage appProps={appProps} />		</Route>
                <Route path={Routes.account}>			<OrganizationsPage appProps={appProps} />		</Route>
                <Route path={Routes.billing}>			<InvoicesPage appProps={appProps} />		</Route>
                <Route path={Routes.acceptance}>		<ActsPage appProps={appProps} />		</Route>
                <Route path={Routes.newInvoice}>		<NewInvoicePage appProps={appProps} />		</Route>
                <Route path={Routes.template}>			<TemplatePage appProps={appProps} />		</Route>
                <Route path={Routes.search}>			<SearchPage appProps={appProps} />		</Route>
                <Route path={Routes.favourite}>			<FavouritePage appProps={appProps} />		</Route>
                
                <Route path={Routes.lk} exact>			<LKPage appProps={appProps} />		</Route>
                <Route path='/'>						<NotFoundPage appProps={appProps} />		</Route>
            </Switch>
        );
    }
}


export default AppNavigationLK;
