import React from 'react';


export function comparatorIgnoreCase(fieldName, a, b) {
    a = (fieldName && a) ? a[fieldName] : a;
    b = (fieldName && b) ? b[fieldName] : b;
    a = a ? a.toLowerCase() : '';
    b = b ? b.toLowerCase() : '';
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
}

export function includesIgnoreCase(arr, str, fieldName=null) {
    if (str)
        str = str.trim();
    if (!Array.isArray(arr) || !str)
        return false;
    for (let value of arr)
        if ((fieldName ? value[fieldName] : value).toLowerCase() === str.toLowerCase())
            return value;
    return false;
}

export function isOKPD2(s, minLength=2) {
	if (!s)
		return false;
	s = s.trim();
	if (s.length < minLength)
		return false;
	var okpd2_reg = '/^\\d\\d\\.\\d\\d\\.\\d\\d\\.\\d\\d\\d';
	var reg = '/^' + s.replace(/\\/g, '\\\\').replace(/\./g, '\\.').replace(/\d/g, '\\d');
	return (okpd2_reg.indexOf(reg) === 0);
}

export function trimOKPD2(s) {
    return s ? s.replace(/[.\\s]+$/g, '') : s;
}

export function formatMarkedText(s) {
    if (!s)
        return s;
    const start = '_ZA2G5BM_';
    const end = '_MB5G2AZ_';
    const parts = s.split(start);
    if (parts.length === 1)
        return s;
    return (
        <React.Fragment>
            {parts.map((part, i) => {
                if (!i)
                    return <React.Fragment key={i}>{part}</React.Fragment>;
                const parts2 = part.split(end);
                return (parts2.length === 1) ? (
                    <b key={i}>{parts2[0]}</b>
                ) : (
                    <React.Fragment key={i}><b>{parts2[0]}</b>{parts2[1]}</React.Fragment>
                );
            })}
        </React.Fragment>
    );
}
